/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : Login PopUp 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import LoginBanner from '../../Assets/LoginBG.webp'
import ArrowBack from '../../Assets/Arrow - Left.webp'
import DangerCircle from '../../Assets/Danger Circle.webp'
import '../CS/ForgotPassword.css'
import SignUpPop from './SignUpPop'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { OtpEmail } from '../../../../../Model/UM-UserManagement/UserManagement';


function ForgotPassword({ closePopup }) {
    const navigate = useNavigate();
    const location = useLocation();  // To get the email passed from login
    const [emailVal, setEmailVal] = useState(location.state?.email || '');  // Prefill email if passed
    const popupRef = useRef(null);
    const [showSignup, setShowSignup] = useState(false); // State to control signup overlay visibility
    const [passwordVal, setPasswordVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLoginSubmit = async (e) => {
        const url = OtpEmail;
        const data = {
            email: emailVal,
            password: passwordVal,
            fcmToken: 'zzzzzd',
            loginType: 'normal',
            deviceType: 'react'
        };
        const response = await axios.post(
            url,
            data
        );
        if (response.data.status === 201) {
            const UserID = response.data.data.UserID; // Extract UserID from response data
            navigate('/', { state: { authToken: response.data.token, UserID: UserID, from: 'login' } });
        }
        else {
            alert(response.data.message);
        }
    }
    const [alertMessage, setAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSendOTP = async () => {
        try {
            setLoading(true); // Set loading to true before making the API call

            const url = OtpEmail;
            const response = await axios.post(url, { email: emailVal });


            if (response.data.status === 200) {
                const UserID = response.data.data.UserID;
                navigate('/verification', { state: { authToken: response.data.token, UserID: UserID, from: 'login' } });
            } else {
                setAlertMessage(response.data.message);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            // Handle the error as needed
        } finally {
            setLoading(false); // Set loading to false after the API call is complete (success or failure)
        }
    };

    const handleCloseAlert = () => {
        setAlertMessage(null);
    };

    const handlepopup = (event) => {
        const targetId = event.target.id;

        // Check the id and navigate accordingly
        if (targetId === 'pop-up-overlay') {
            navigate('/');
        } else if (targetId === 'um-forgot') {
        }
    };
    return (

        <div className="pop-up-overlay"
            id='pop-up-overlay'
            onClick={handlepopup}
        >
            <div className='um-forgot'
            >
                <div className='um-login-banner'>
                    <div className='um-login-img'>
                        <img src={LoginBanner} alt='LoginBanner' />
                    </div>
                </div>
                <div className='um-forgot-detail'>
                    <div>
                        <div className='um-forgot-account'>
                            Forgot your password?
                        </div>
                        <div >
                            <div className='um-login-forgot'>
                                Enter your email address. We will send OTP
                            </div>

                            <div className='um-login-forgot-2'>
                                on your registered email address
                            </div>
                        </div>
                        <div className='um-forgot-email-text'>Email</div>
                        <div className='um-login-emailbox'>

                            <div className='um-email-msg-textbox'>

                                <input
                                    className='um-email-inputbox'
                                    value={emailVal}
                                    onChange={(e) => setEmailVal(e.target.value)} 
                                    type='email'
                                    placeholder="Email Address"
                                />
                            </div>
                        </div>
                        {alertMessage && (
                            <div className="alert-overlay" onClick={handleCloseAlert}>
                                <div className='alert-box-img' onClick={handleCloseAlert}><img src={DangerCircle} alt='alert-danger' /></div>
                                <div className="um-alert-box">
                                    {alertMessage}

                                </div>
                            </div>
                        )}
                        <div className='um-sendotp-btn'
                            onClick={handleSendOTP}
                        >
                            Send OTP
                        </div>
                        {/* Display loading overlay or spinner based on the loading state */}
                        {loading && (
                            <div className="loading-overlay">
                                {/* Add your loading spinner or message here */}
                                Loading...
                            </div>
                        )}
                        <div className='um-arrow-back-btn'
                            onClick={() => navigate('/loginpop')}
                        >
                            <div className='um-back-arrow-img'> <img src={ArrowBack} alt='arrowback' /></div>
                            <div className='um-back-arrow-text'>
                                Back to Log In
                            </div>
                        </div>


                    </div>


                </div>


            </div>
            {/* Display signup overlay if showSignup state is true */}
            {showSignup && (
                <div className="signup-overlay">

                    <div className="signup-form">
                        <SignUpPop />

                    </div>
                </div>
            )}
        </div>

    )
}

export default ForgotPassword
