/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Explore Audio Stories Page 
Brief :
AudioStories, renders a page displaying audio content. It includes a header, an audio content component (AudioContent), and a footer. The component manages state variables such as data, page, loading, allStoriesLoaded, totalStories, and totalStoriesObject.

The useEffect hook is employed to fetch audio data, particularly sub-playlist data, based on the current page and the predefined page limit. 
It updates the state variables accordingly after fetching the data. 
The AudioContent component utilizes these state variables to display the audio stories, handle pagination, and manage the loading state.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AdsDetailView from '../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView';
import ArrowLeft from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import PauseButtonEx from '../../Assets/Images/Pause buttonEx.webp'
import PlayButtonEx from '../../Assets/Images/Play buttonEX.webp'
import TimeRewindEX from '../../Assets/Images/Time rewindEX.webp'
import TimeForwardEX from '../../Assets/Images/Time forwardEX.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import ShareButton from '../../../WS-WrittenStories/src/JS/StoryDetailView/ShareButton';
import LoadingGif from '../../../WS-WrittenStories/Assets/Images/Loading.gif'
import PlayButtonbtn from '../../../AS-AudioStories/Assets/Images/Play buttonbtn.png'
import axios from 'axios';
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, audio_rating, update_story_rating } from '../../../../../Model/WS-WrittenStories/WrittenStories';
import StarFill from '../../../WS-WrittenStories/Assets/Images/StarFill.webp'
import Star from '../../../WS-WrittenStories/Assets/Images/Star.webp'
import PauseButtonbtnList from '../../Assets/Images/headphone-sound.webp'


function AudioDetail({ data, loading, setPage, page,
    allStoriesLoaded, totalStories,
    totalStoriesObject, setSortBy, setData,
    // sequence,
    //expand
    expandedClassName, audioList, audioPagination, onExpandClick, audioPlayerBox, audioPlayerBoxExpand, onExpandClose, seekingInputMob,
    audio, stories
}) {
    const { sequence } = useParams();
    // const authToken = localStorage.getItem('token') || null;
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const initialUserId = localStorage.getItem('UserID') || 0;
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [showPopup, setShowPopup] = useState(false);
    const storyID = data.storyID;
    console.log(storyID);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);

        // Fetch current rating
        if (authToken && storyID && userId) {
            const config = {
                method: 'get',
                url: `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/get_rating?story_id=${storyID}&user_id=${userId}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken}`
                }
            };

            axios.request(config)
                .then(response => {
                    const { rated, rating } = response.data.data;
                    if (rated) {
                        const updatedFilledStars = Array(5).fill(false);
                        for (let i = 0; i < rating; i++) {
                            updatedFilledStars[i] = true;
                        }
                        setFilledStars(updatedFilledStars);
                    }
                })
                .catch(error => {
                    console.error(error); // Handle errors here
                });
        }
    }, [authToken, storyID, userId]);

    const handleShowPopup = () => {
        if (authToken == null || authToken === "no token") {
            if (isPlaying) {
                audio.pause();
                setIsPlaying(false);
            }
            navigate('/loginpop');
        }
    };
    const handleSeek = (e) => {
        const { value } = e.target;
        audio.currentTime = parseFloat(value); // Convert value to a float
        setCurrentTime(parseFloat(value)); // Update the state with the float value
    };
    const formatTime = (time, from = "duration") => {
        const tt = time;
        const minutes = Math.floor(tt / 60);
        const seconds = Math.floor(tt % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    const [currentTime, setCurrentTime] = useState(0.0);
    const [duration, setDuration] = useState(0);
    const [durationLabel, setDurationLabel] = useState('00:00');
    const [isPlaying, setIsPlaying] = useState(false);
    const handleTimeRewind = () => {
        if (!audio) return;
        audio.currentTime -= 10; // Rewind 10 seconds
    };
    const handleTimeForward = () => {
        if (!audio) return;
        audio.currentTime += 10; // Forward 10 seconds
    };
    window.onpopstate = e => {
        if (audio) audio.pause();
    }

    // add code
    useEffect(() => {
        if (audio) {
            const handleTimeUpdate = () => {
                setCurrentTime(audio.currentTime);
            };
            audio.addEventListener('timeupdate', handleTimeUpdate);

            return () => {
                audio.removeEventListener('timeupdate', handleTimeUpdate);
                audio.pause();
            };
        }
    }, [audio]);
    // end code
    useEffect(() => {
        if (audio) {
            if (!isPlaying) {
                setIsPlaying(true);
                audio.play().catch(error => {
                    console.error('Play error:', error);
                });
            }
            audio.addEventListener('timeupdate', () => {
                let value = audio.currentTime;
                setCurrentTime(value);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            setDurationLabel(formatTime(audio.duration));
            setDuration(audio.duration);
        }
    }, [currentTime]);
    const handlePlayPause = (index) => {
        if (isPlaying) {
            setIsPlaying(false);
            audio.pause();
            return;
        }
        audio.play().catch(error => {
            console.error('Play error:', error);
        });
        setIsPlaying(true);
    };
    const [showSignup, setShowSignup] = useState(false);
    let navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
        // navigate("/kids-bedtime-stories-audio");
        audio.pause();
    };

    const [heartedStories, setHeartedStories] = useState(false);

    // Set the initial state of heartedStories based on data.favouriteStory
    useEffect(() => {
        setHeartedStories(data.favouriteStory);
    }, [data.favouriteStory]);

    const handleShowHeart = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // Toggle the hearted state for the story
            setHeartedStories(!heartedStories);

            // Perform favorite/unfavorite action based on the state
            if (!heartedStories) {
                favouriteStory(data.storyID);
            } else {
                unfavouriteStory(data.storyID);
            }
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const [bookmarkedStories, setBookmarkedStories] = useState(false);

    // Set the initial state of heartedStories based on data.is_saved
    useEffect(() => {
        setBookmarkedStories(data.is_saved);
    }, [data.is_saved]);
    const handleShowBookmark = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // Toggle the hearted state for the story
            setBookmarkedStories(!bookmarkedStories);

            // Perform favorite/unfavorite action based on the state
            if (!bookmarkedStories) {
                savedStory(data.storyID);
            } else {
                unsavedStory(data.storyID);
            }
        }
    };

    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [allowClick, setAllowClick] = useState(true);

    const handleShowSignup = () => {
        setShowSignup(true);
    };
    const toggleStar = (index) => {
        if (authToken) {
            const newRating = index + 1;
            const updatedFilledStars = Array(5).fill(false);
            for (let i = 0; i < newRating; i++) {
                updatedFilledStars[i] = true;
            }
            setFilledStars(updatedFilledStars); // Update stars before API call

            const requestBody = {
                story_id: String(storyID),
                rating: newRating.toString()
            };
            const requestHeaders = {
                Authorization: `${authToken}`,
            };

            axios
                .patch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/rating`, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Verify response status
                    if (response.data.status === 200) {
                        console.log('Rating updated successfully');
                    }
                })
                .catch((error) => {
                    console.error('Error updating rating:', error);
                    // Revert stars if there is an error
                    setFilledStars(filledStars);
                });
        }
    };
    const handleCloseSignup = () => {
        navigate('/loginpop');
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                // console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    // listview
    const handleStoryClick = (storyID) => {
        // updateStory(storyID);
        // ai(storyID)
    }
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const handleDetailView = (storyID, title) => {
        navigate(`/videostories/${storyID}`,
        );
        window.scrollTo(0, 0);

    }
    const navigateToStory = (storyID, title) => {
        navigate(`/videostories/${storyID}`,
        );
        window.scrollTo(0, 0);
    };
    const handlePlayAudio = (storyID, title, story) => {
        const formattedTitle = title.replace(/\s+/g, '-');
        const serializableStory = {
            storyID: storyID,
            title: title,
            // creationDate: creationDate,
            // Add other serializable properties of the story object here
        };
        // console.log(serializableStory)
        navigate(`/singleaudio/${storyID}/${formattedTitle}`, { state: { story: serializableStory } });
        window.scrollTo(0, 0);
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    function formatDateMob(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    return (
        <div>
            <div >
                <div className='as-audio-detail-view'>
                    <div className='as-player-box-expand'>

                        <div className='as-audio-detail-view'>
                            <div className='as-audio-backbtn'
                            >
                                <img src={ArrowLeft} alt='backbtn' onClick={goBack} />
                                <div className='as-audio-now-playing'>
                                    Now Playing
                                </div>
                            </div>
                            <div className='as-audio-detailview'>
                                <div className='as-detailview-thumbnailimg'>
                                    <img src={data.

                                        storyThumbnailImage
                                    } alt='Story' />

                                </div>
                                <div className='as-detailview-storyTitle'>
                                    <div className='as-detailview-heart' >

                                        <div className='as-detailview-heart-icon' onClick={handleShowHeart}>
                                            <img
                                                src={heartedStories ? HeartFilled : Heart}
                                                alt='heart'
                                            />
                                        </div>


                                    </div>
                                    <div className='as-audio-TitleAuthorNarrator'>
                                        <div className='as-audio-storyTitle'>{data.storyTitle}</div>
                                        <div className='as-detailview-authorNarrator'>
                                            <div>{data.authorName},&nbsp;&nbsp;

                                                {data.narratorName}</div>

                                        </div>
                                    </div>
                                    <div className='as-detailview-bookmark-share'>

                                        <div className='ws-bookmark' onClick={handleShowBookmark}>
                                            <img
                                                src={bookmarkedStories ? BookmarkFilled : Bookmark}
                                                alt='heart'
                                            />
                                        </div>


                                        <div className='ws-shareEX'>
                                            <div className='web'>
                                                <ShareButton />

                                            </div>
                                            <div className='mobile'>
                                                <div className='ws-detail-share'
                                                    onClick={handleShareClick}
                                                >
                                                    <img src={Share} alt='share' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='as-detailview-playrate'>
                                    <div className='as-detail-play'><span className='as-detail-playno'>{data.listening_count}&nbsp;</span> play</div>
                                    <div className='as-detail-rate'> <span className='as-detail-rateno'>
                                        {Number(data.rating).toFixed(2)}
                                        &nbsp; </span>rating</div>
                                </div>


                                <div className='as-audio-playpausebtn'>
                                    <div className='as-player-timerewindEX'
                                        onClick={handleTimeRewind}
                                    >
                                        <img src={TimeRewindEX} alt='TimeRewind' />
                                    </div>
                                    <div className='as-player-playpause'
                                        onClick={handlePlayPause}
                                    >
                                        {(isPlaying) ? <img src={PauseButtonEx} alt='PauseButton' /> : <img src={PlayButtonEx} alt='PlayButton' />}
                                    </div>

                                    <div className='as-player-timeforwardEX'
                                        onClick={handleTimeForward}
                                    >
                                        <img src={TimeForwardEX} alt='Timeforward' />
                                    </div>
                                </div>
                                <div className='seekbar-expand'>

                                    <div className='seekinginput-expand'>
                                        <input id='myinput'
                                            type="range"
                                            min={0}
                                            max={duration}
                                            value={currentTime}
                                            onChange={handleSeek}
                                            className='slider-expand'
                                        />
                                    </div>
                                </div>
                                <div className='as-audio-timeShow'>
                                    <div className='as-player-currenttimeEX'>{formatTime(currentTime, "currentTime")}</div>
                                    <div className='as-player-maxtimeEX'>{durationLabel}</div>
                                </div>
                                <div className='as-audio-rating'>
                                    <div className='as-detail-rating'>
                                        Rate Story
                                    </div>
                                    {(authToken == null || authToken === "no token") ?
                                        <div className='as-audio-detail-ratestar'>
                                            {filledStars.map((isFilled, index) => (
                                                <img
                                                    key={index}
                                                    src={isFilled ? StarFill : Star}
                                                    alt={`Star ${index + 1}`}
                                                    onClick={handleShowSignup}
                                                />
                                            ))}
                                        </div>
                                        :
                                        <div className='as-audio-detail-ratestar'>
                                            {filledStars.map((isFilled, index) => (
                                                <img
                                                    key={index}
                                                    src={isFilled ? StarFill : Star}
                                                    alt={`Star ${index + 1}`}
                                                    onClick={() => toggleStar(index)}
                                                />
                                            ))}
                                        </div>
                                    }


                                </div>

                                {showSignup && (
                                    <div className="speech-bubble-container">


                                        <div className="speech-bubble" onClick={handleCloseSignup}>
                                            <div className="box-content">
                                                <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='as-detailview-ads'>
                            <AdsDetailView />
                        </div>
                    </div>
                </div>
                {/* ListView */}
                <div className='vs-detailview'>
                    <div className='ws-stories-box-align-bottom' id="divTwo" >
                        <div className='as-audio-stories-storiescount'>
                            More audio stories like this
                            {/* Stories {firstAndLastStoriesNumber} out of {totalStories} */}
                        </div>


                        {loading ? ( // Conditionally render the loading screen or data

                            <div className='loading'>
                                <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                            </div>
                        ) : (
                            stories.map((story, i) => {
                                return (
                                    <div key={i}>
                                        <div className='web'>

                                            <div className='vs-stories-box' key={i} onClick={() => handleStoryClick(story.storyID)}>
                                                <div className='as-audio-btn' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                >
                                                    <div className='as-audio-img' >
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            <div>
                                                                <div className='as-audio-play'>
                                                                    <img src={PauseButtonbtnList} alt='Play' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ws-stories-text'>
                                                    <div className='ws-stories-title-img' >
                                                        <div className='ws-stories-box-title'
                                                            onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                        >

                                                            {story.title}
                                                        </div>
                                                        <div className='ws-stories-img-hsb' >

                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }


                                                            </div>
                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }
                                                            </div>



                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date'
                                                    >
                                                        <div className='ws-stories-box-author'
                                                            onClick={() => handlePlayAudio(story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                        <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                    </div>
                                                    <div
                                                        className="ws-stories-box-wrapper"
                                                        onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                        style={{ padding: '45px 0', cursor: 'pointer' }}
                                                    >
                                                        <div className="ws-stories-box-summary">
                                                            {story.summary}
                                                        </div>
                                                    </div>


                                                    <div className='ws-story-box-viewrate'
                                                        onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                    >
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.views}</div>
                                                            <div className='ws-story-viewtext'>views</div>
                                                        </div>
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                            <div className='ws-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        <div className='mobile'>
                                            <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                <div className='as-audio-btn' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                >
                                                    <div className='as-audio-img' >
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />

                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            <div><div className='as-audio-play'>
                                                                <img src={PauseButtonbtnList} alt='Play' />
                                                            </div></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='ws-stories-text' >
                                                    <div className='ws-stories-title-img'>
                                                        <div className='ws-stories-box-title' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                        >
                                                            {story.title}
                                                        </div>
                                                        <div className='ws-stories-img-hsb'>

                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }


                                                            </div>

                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }
                                                            </div>

                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                    >
                                                        <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                        <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                    </div>
                                                    <div className='ws-stories-box-summary' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                    >
                                                        {story.summary}
                                                    </div>
                                                    <div className='ws-stories-box-view-rate'
                                                    >
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.views}</div>
                                                            <div className='ws-story-viewtext'>views</div>
                                                        </div>
                                                        <div className='ws-stories-box-ratebox'>
                                                            <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                            <div className='ws-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        )}
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AudioDetail
