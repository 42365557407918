/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : Login PopUp 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import LoginBanner from '../../Assets/LoginBG.webp'
import '../CS/Verification.css'
import SignUpPop from './SignUpPop'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { verifyotp } from '../../../../../Model/UM-UserManagement/UserManagement';


function Verification({ closePopup }) {
    const location = useLocation();
    const authToken = location.state && location.state.authToken;
    const navigate = useNavigate();
    const popupRef = useRef(null);
    const [showSignup, setShowSignup] = useState(false); // State to control signup overlay visibility
    const [passwordVal, setPasswordVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };



    const [otpValues, setOtpValues] = useState(['', '', '', '']);
    const [isContinueEnabled, setContinueEnabled] = useState(false);
    const inputRefs = useRef(otpValues.map(() => React.createRef()));

    const handleOtpChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);
    
        // Check if all fields are filled
        const allFieldsFilled = newOtpValues.every(val => val !== '');
        setContinueEnabled(allFieldsFilled);
    };
    
    const focusNextInput = (index) => {
        const nextIndex = index + 1;
        if (nextIndex < otpValues.length) {
            inputRefs.current[nextIndex].current.focus();
        } else {
            console.log(' ');
        }
    };
    
    const focusPreviousInput = (index) => {
        const prevIndex = index - 1;
        if (prevIndex >= 0) {
            inputRefs.current[prevIndex].current.focus();
        }
    };
    
    // Handle OTP paste event
    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text').slice(0, otpValues.length);
        const newOtpValues = [...otpValues];
    
        // Split pasted data into input fields
        for (let i = 0; i < pasteData.length; i++) {
            newOtpValues[i] = pasteData[i];
        }
    
        setOtpValues(newOtpValues);
    
        // Focus the last filled input box after pasting
        inputRefs.current[pasteData.length - 1].current.focus();
    
        // Check if all fields are filled after pasting
        const allFieldsFilled = newOtpValues.every(val => val !== '');
        setContinueEnabled(allFieldsFilled);  // Enable continue button if all fields are filled
    };
    
    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && otpValues[index] === '') {
            focusPreviousInput(index);
        }
    };

    const handleSendOTP = () => {
        try {
            setLoading(true);

            const url = verifyotp;
            const otp = otpValues.join('');
            const requestHeaders = {
                Authorization: `${authToken}`,
            };

            // Make an API call to send OTP with authToken
            axios.post(url, { otp }, { headers: requestHeaders })
                .then(response => {
                    console.log(response.data);

                    if (response.data.status === 200) {
                        navigate('/createpassword', { state: { authToken, from: 'login' } });
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    console.error('Error sending OTP:', error);
                    // Handle the error as needed
                })
                .finally(() => {
                    setLoading(false); // Set loading to false after the API call is complete (success or failure)
                });
        } catch (error) {
            // Handle errors as needed
            setLoading(false);
        }
    };

    // const inputRefs = useRef(otpValues.map(() => React.createRef()));

    // const focusNextInput = index => {
    //     const nextIndex = index + 1;
    //     if (nextIndex < otpValues.length) {
    //         inputRefs.current[nextIndex].current.focus();
    //     } else {
    //         console.log(' ')
    //     }
    // }
    const handlepopup = (event) => {
        const targetId = event.target.id;

        // Check the id and navigate accordingly
        if (targetId === 'pop-up-overlay') {
            navigate('/');
        } else if (targetId === 'um-forgot') {
            // Handle um-login click (e.g., show a popup or perform other actions)
        }
    };
    return (

        <div className="pop-up-overlay"
            id='pop-up-overlay'
            onClick={handlepopup}
        >
            <div className='um-forgot'
            >
                <div className='um-login-banner'>
                    <div className='um-login-img'>
                        <img src={LoginBanner} alt='LoginBanner' />
                    </div>
                </div>
                <div className='um-forgot-detail'>
                    <div>
                        <div className='um-forgot-account'>
                            Verification required
                        </div>
                        <div >
                            <div className='um-login-forgot'>
                                To continue, complete this verification process

                            </div>

                            <div className='um-login-forgot-2'>
                                by entering 4 digit OTP sent on your email
                            </div>
                        </div>
                        <div className='um-forgot-detail'>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <div key={index} className={`um-otp-emailbox${index + 1}`}>
                                    <div className='um-otp-msg-textbox'>
                                        <input
                                            ref={inputRefs.current[index]}
                                            className='um-otp-inputbox'
                                            value={otpValues[index]}
                                            onChange={(e) => {
                                                handleOtpChange(index, e.target.value);
                                                if (e.target.value !== '') {
                                                    focusNextInput(index);
                                                }
                                            }}
                                            onKeyDown={(e) => handleKeyDown(e, index)}
                                            onPaste={handlePaste}
                                            type='text'
                                            maxLength={1} // Ensuring only one digit per box
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div
                            className={` ${isContinueEnabled ? 'um-sendotp-enabled' : 'um-sendotp-disable'}`}
                            onClick={isContinueEnabled ? handleSendOTP : null}
                        >
                            Continue
                        </div>

                        {/* Display loading overlay or spinner based on the loading state */}
                        {loading && (
                            <div className="loading-overlay">
                                {/* Add your loading spinner or message here */}
                                Loading...
                            </div>
                        )}
                    </div>


                </div>


            </div>
            {/* Display signup overlay if showSignup state is true */}
            {showSignup && (
                <div className="signup-overlay">
                    {/* Add your signup form or content here */}
                    {/* For example: */}
                    <div className="signup-form">
                        <SignUpPop />
                        {/* Signup form fields */}
                        {/* ... */}
                    </div>
                </div>
            )}
            {/* <p>Login first</p>
                <button onClick={handleClosePopup}>Close</button> */}
            {/* </div> */}
        </div>

    )
}

export default Verification
