/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Cookie DisClaimer Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/Cookie.css'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'

function CookieDisclaimer() {
    return (
        <div>
            <Header />
            <div className='am-cookie-page'>
                <div className='am-cookie'>
                    Cookie disclaimer
                </div>
                {/* <p className='am-cookie-para'>
                    This website uses cookies.
                </p>
                <p className='am-cookie-para'>
                    We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services.
                </p> */}
                <div className='am-term-heading'>What Are Cookies? </div>
                <p className='am-cookie-para'>
                    Our website uses cookies to enhance your experience. Cookies are small data files stored on your device that help us personalize content and ads, offer social media features, and analyze our site traffic. By using cookies, we can improve your experience and understand how you interact with our website.
                </p>
                <div className='am-term-heading'>How We Use Cookies  </div>
                <p className='am-cookie-para'>We use cookies to: </p>
                <ul>
                    <li className='am-term-para'>Personalize content and ads  </li>
                    <li className='am-term-para'>Provide social media features </li>
                    <li className='am-term-para'>Analyze our traffic </li>
                </ul>
                <div className='am-term-heading'>Third-Party Sharing </div>
                <p className='am-cookie-para'>We share information about your use of our site with social media, advertising, and analytics partners. These partners may combine this information with other data you've provided or that they've collected from your use of their services.  </p>
                <div className='am-term-heading'>Contact Us  </div>
                <p className='am-cookie-para'>If you have any questions about our Cookies Policy, feel free to reach out to us  </p>
                <ul>
                    <li className='am-term-para'>By visiting this page on our website:
                        <a
                            href="https://kushmanda.tech/"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >https://kushmanda.tech</a>   </li>
                    <li className='am-term-para'>By sending us an email: legal@kushmanda.tech  </li>
                </ul>
                <div className='am-term-heading'>Additional Information </div>
                <ul>
                    <li className='am-term-para'>
                        <a
                            href="/privacy"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >Read our Privacy Policy  </a> </li>
                    <li className='am-term-para'>
                        <a
                            href="/term"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >
                            Read our Terms for Users and Contributors
                        </a>
                    </li>
                </ul>
            </div>
            <Footer />
        </div>
    )
}

export default CookieDisclaimer
