/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Header of Home Screen
Brief :Header Component: This component represents the header section of the website, handling navigation and display functionalities.
Navigation: It contains navigation elements like the website logo, tabs for different sections (e.g., Home, Audio Stories), a 'Write' button, and a mobile menu for responsive design.
Search Functionality: It includes a search bar that allows users to input text and search for stories, audios, and authors. Pressing 'Enter' triggers a search with the entered text.
State Management: It manages states for menu visibility, search input visibility, search text, and active tabs.
Routing: Utilizes the react-router-dom library for navigation using the useNavigate hook and tracks the current location with the useLocation hook.
Event Handling: Handles click events for navigation tabs, the website logo, and the 'Write' button. It also manages the mobile menu toggle and search icon toggle events.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import '../../CS/Header.css'
import WBSLogo from '../../../Assets/Images/WBSLogo.png'
import Profile from '../../../Assets/Images/profile.webp'
import { useNavigate, useLocation } from 'react-router-dom';
import '../../CS/Slider.css'
import HamburgerMenu from './HamburgerMenu';
import NewSearch from '../StoriesList/NewSearch';
import Navigation from './Navigation';

function Header(props) {

    const totalStories = props.totalStories;
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [UserID, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem('IsAdmin') || null);
    const [isImage, setIsImage] = useState(localStorage.getItem('isImage') || null);
    const [Image, setImage] = useState(localStorage.getItem('ImageName') || null);
    const [name, setName] = useState(localStorage.getItem(' name') || null);

    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
        setIsAdmin(localStorage.getItem('IsAdmin') === 'true');
        setIsImage(localStorage.getItem('isImage') ==='true');
        setImage(localStorage.getItem('ImageName') || null);
        setName(localStorage.getItem('name') || null);

    }, []);

    const getInitials = (name) => {
        if (!name) return '';
        const nameArray = name.split(' ');
        const initials = nameArray.map(n => n[0]).join('');
        return initials.toUpperCase();
    };
    const [menuOpen, setMenuOpen] = useState(false);
    const [searchInputVisible, setSearchInputVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('home'); // Initial active tab
    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            navigate(`/search-results?searchText=${searchText}&totalStories=${totalStories}`);
        }
    };
    const handleChange = (e) => {
        setSearchText(e.target.value);
    };
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const yellowColor = {
        color: ' #403085',
    };
    const handleSearchIconClick = () => {
        setSearchInputVisible(!searchInputVisible); // Toggle input visibility
    };
    useEffect(() => {
        // Reset the active tab when the route changes
        const pathname = location.pathname;
        switch (pathname) {
            case '/kids-bedtime-stories-audio':
                setActiveTab('kids-bedtime-stories-audio');
                break;
            case '/': // Add other route cases if needed
                setActiveTab('home');
                break;
            case '/authorlist': // Add other route cases if needed
                setActiveTab('authorlist');
                break;
            case '/videostories':
                setActiveTab('videostories');
                break;
            // Add additional cases for other routes if needed
            default:
                setActiveTab(''); // Reset to default active tab when route doesn't match
                break;
        }
    }, [location.pathname]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName === 'home' ? '' : tabName}`); // Navigate based on tab clicked
    };

    const handleWriteSection = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            navigate('/writingSection'
            );
        }
    }

    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }
    const handleUserProfile = () => {
        navigate('/userprofile'
        );
    }
    const handleLogout = () => {
        // Handle logout logic here
        // Clear values from localStorage and update component state
        localStorage.removeItem('token');
        localStorage.removeItem('UserID');
        localStorage.removeItem('IsAdmin');
        localStorage.removeItem('ImageName');
        localStorage.removeItem('ImageExist');
        localStorage.removeItem('isImage');
        localStorage.removeItem('name');
        setAuthToken(null);
        setUserID(null);
        setIsAdmin(null);
        setImage(null);
        setName(null);
        setImage(null);
        navigate('/'
        );

        window.location.reload();
        navigate('/'
        );


    };



    return (
        <div className='ws-new-header'>
            <div className='ws-header'>
                <div className='header'>
                    <div className='ws-header-logo' onClick={() => {
                        navigate('/'
                        )
                    }}
                    >
                        <img src={WBSLogo} alt='App-logo'></img>
                    </div>
                </div>
                <div className='header2'>
                    <div
                        className={`home ${activeTab === 'home' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('home'); navigate('/'
                            );
                        }}
                    >Home</div>
                    <div
                        className={`audio ${activeTab === 'kids-bedtime-stories-audio' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('kids-bedtime-stories-audio'); navigate('/kids-bedtime-stories-audio'
                            );
                        }}
                    >Audio Stories</div>
                    <div
                        className={`audio ${activeTab === 'videostories' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('videostories'); navigate('/videostories'
                            );
                        }}
                    >Video Stories</div>
                    <div
                        className={`author ${activeTab === 'authorlist' ? 'active' : ''}`}
                        onClick={() => {
                            handleTabClick('authorlist'); navigate('/authorlist'
                            );
                        }}
                    >Author</div>
                </div>
                <NewSearch totalStories={totalStories}
                />
                <div className='header3'>
                    {/* {(authToken == null || authToken === "no token") ?
                        <div onClick={handleLoginSignUp} className='divlog'>
                            <img src={Profile} alt='profile' />
                        </div>
                        :

                        <div className='divlog' onClick={handleUserProfile}>
                            <img src={Image} alt='profile' />
                        </div>
                    } */}
                    {(authToken == null || authToken === "no token") ? (
                        <div onClick={handleLoginSignUp} className='divlog'>
                            <img src={Profile} alt='profile' />
                        </div>
                    ) : (
                        <div className='divlog' onClick={handleUserProfile}>
                            {isImage ? (
                                <>
                                    <img src={Image} alt='profile' />
                                    <div>{isImage}</div> {/* Display true/false */}
                                </>
                            ) : (
                                <>
                                    <div className='name-initials'>
                                        {getInitials(name)}
                                    </div>
                                    <div>{isImage}</div> {/* Display true/false */}
                                </>
                            )}
                        </div>

                    )}
                 


                    <div className='divwrite'>
                        <button className='writebtn' onClick={handleWriteSection}>Write</button>
                    </div>
                    {(authToken == null || authToken === "no token") ?
                        <div className='divwrite'></div> :

                        <div className='divwrite'>
                            <button className='logoutbtn'
                                onClick={handleLogout}
                            >Logout
                            </button>
                        </div>
                    }



                </div>
            </div>
            <div className='mobile'>
                <HamburgerMenu />
                {/* <Navigation /> */}
            </div>
            <div className='web'>
                <div className='new'>
                </div>
            </div>
        </div>
    )
}

export default Header
