/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Home Screen Stories
Brief : 
Purpose: Represents the Home Screen Stories section of the "wikibedtimestories.com" website.
Functionality:
Rendering Stories: Displays stories along with their images, titles, authors, creation dates, views, ratings, and summaries.
Pagination: Implements navigation buttons to move between pages of stories.
Category Handling: Allows users to filter stories by categories and subcategories.
Sharing and Navigation: Includes functionality for sharing stories and navigating to specific story details.
Component Structure: Contains various components for handling story content, categories, and pagination.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react'
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../CS/Stories.css'
import DownArrow from '../../../Assets/Images/Arrow - Down.webp'
import StaticCat from '../../../Assets/Images/Category image.webp'
import SortByCat from '../../../Assets/Images/SortByCat.webp'
import Heart from '../../../Assets/Images/Heart.webp'
import HeartFilled from '../../../Assets/Images/HeartFilled.webp'
import Share from '../../../Assets/Images/Share.webp'
import Bookmark from '../../../Assets/Images/Bookmark.webp'
import BookmarkFilled from '../../../Assets/Images/BookmarkFilled.webp'
import RightArrow from '../../../Assets/Images/ArrowRight.webp'
import NonSelect from '../../../Assets/Images/NonSelect.webp'
import Select from '../../../Assets/Images/Select.webp'
import ArrowDown1 from '../../../Assets/Images/Arrow - Down.webp'
// import ArrowDown1 from '../../../Assets/Images/Arrow - Down 2.webp'
import ArrowDown from '../../../Assets/Images/Arrow - Down 2.webp'
import ArrowUp1 from '../../../Assets/Images/Arrow - Up.webp'
// import ArrowUp1 from '../../../Assets/Images/Arrow - Up 2.WebP'
import ArrowUp from '../../../Assets/Images/Arrow - Up 2.WebP'
import LoadingImage from '../../../Assets/Images/LoadingImage.webp'
import loadinggif from '../../../Assets/Images/Loading.gif'
import AdsListView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsListView';
import CatNumb from '../../../Assets/Images/CatNumb.webp'
import NewTag from '../../../Assets/Images/NewTag.webp'
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, airecommended, audio_rating, update_story_views } from '../../../../../../Model/WS-WrittenStories/WrittenStories';
import Navigation from '../Header/Navigation';
import '../../CS/categorybtn.css'
import AgeCategory from './AgeCategory';
import ArrowLeft from '../../../Assets/Images/ArrowLeftPagination.webp'
import ArrowRight from '../../../Assets/Images/ArrowRightPagination.webp'
import PlayButtonbtn from '../../../../AS-AudioStories/Assets/Images/Play buttonbtn.png'
import AudioButton from '../../../../AS-AudioStories/Assets/Images/AudioButton.webp'

// audioPlayer
import TimeRewind from '../../../../AS-AudioStories/Assets/Images/Time rewind.webp'
import TimeForward from '../../../../AS-AudioStories/Assets/Images/Time forward.webp'
import PauseButtonbtn from '../../../../AS-AudioStories/Assets/Images/Pause buttonbtn.png'
import Cross from '../../../../AS-AudioStories/Assets/Images/cross_icon.webp'
import Expand from '../../../../AS-AudioStories/Assets/Images/expand.webp'
import PauseButtonEx from '../../../../AS-AudioStories/Assets/Images/Pause buttonEx.webp'
import PlayButtonEx from '../../../../AS-AudioStories/Assets/Images/Play buttonEX.webp'
import TimeRewindEX from '../../../../AS-AudioStories/Assets/Images/Time rewindEX.webp'
import TimeForwardEX from '../../../../AS-AudioStories/Assets/Images/Time forwardEX.webp'
import StarFill from '../../../Assets/Images/StarFill.webp'
import Star from '../../../Assets/Images/Star.webp'
import ArrowDownDetail from '../../../../AS-AudioStories/Assets/Images/Arrow - Down.webp'
import AdsDetailView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView';
import PlayButtonbtnList from '../../../../AS-AudioStories/Assets/Images/headphone-circle.webp'
import PauseButtonbtnList from '../../../../AS-AudioStories/Assets/Images/headphone-sound.webp'
function Stories({ data, allStoriesLoaded,
    loading,
    setSortBy, setPage, page, setData, setAllStoriesLoaded, totalStories,
    totalStoriesObject,
    firstAndLastStoriesNumber,
    apiCallInProgress, totalPages, apiCallCompleted, setApiCallCompleted, selectedAge
    , setSelectedAge, setFilterBy,
    //expand
    expandedClassName, audioList, audioPagination, onExpandClick, audioPlayerBox, audioPlayerBoxExpand, onExpandClose, seekingInputMob

}) {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [UserID, setUserID] = useState(localStorage.getItem('UserID') || null);
    const CurrentDate = new Date(new Date().toUTCString());
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const goToNextPage = () => {
        if (!allStoriesLoaded) {
            setPage(page + 1);
            setData([]);
        }
    };


    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPages = 8; // Maximum number of pages visible at a time
        let startPage = 1;
        let endPage = Math.min(totalPages, maxPages);

        // Adjust the page range when the current page reaches 8 or higher
        if (page >= maxPages) {
            startPage = page - maxPages + 2; // Start from page 2 when reaching page 8
            endPage = Math.min(page + 1, totalPages); // Show 8 pages from the current range
        }

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-number ${i === page ? 'active' : ''}`}
                    onClick={() => {
                        if (i === page) return; // Prevent API call and navigation for the active page
                        setPage(i);
                        setData([]); // Clear previous data
                        window.scrollTo(0, 0);
                        navigate(`/?page=${i}`);
                    }}
                >
                    {i}
                </button>
            );
        }

        return pageNumbers;
    };




    const goToFirstPage = () => {
        if (page > 1) {
            setPage(1);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=1`);
        }
    };

    const goToLastPage = () => {
        if (page < totalPages) {
            setPage(totalPages);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=${totalPages}`);
        }
    };

    const goToPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setData([]);
            window.scrollTo(0, 0);
            navigate(`/?page=${page - 1}`);
        }
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // console.log('logged in')
        }
    };

    const [heartedStories, setHeartedStories] = useState(new Array(data.length).fill(false));

    // Function to handle heart click for a specific story
    const handleShowHeart = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the heartedStories array
            const updatedHeartedStories = [...heartedStories];

            // Toggle the hearted state for the specific story
            updatedHeartedStories[index] = !updatedHeartedStories[index];

            // Update the hearted state for all stories
            setHeartedStories(updatedHeartedStories);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(data.length).fill(false));

    // Function to handle bookmark click for a specific story
    const handleShowBookmark = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedBookmarkedStories = [...bookmarkedStories];

            // Toggle the bookmarked state for the specific story
            updatedBookmarkedStories[index] = !updatedBookmarkedStories[index];

            // Update the bookmarked state for all stories
            setBookmarkedStories(updatedBookmarkedStories);

        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const ai = async (storyID) => {
        const url = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/ai/story_status`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': authToken,

        };
        storyID = String(storyID);
        const body = JSON.stringify({
            "story_id": storyID,
            "story_type": "read",
            "time": "10"
        });

        try {
            const response = await fetch(airecommended, {
                method: 'PUT',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    const recommendStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
            story_type: "read",
            time: "10",
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(airecommended, requestBody, { headers: requestHeaders })
            .then((response) => { })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleStoryClick = (storyID) => {
        // updateStory(storyID);
        // ai(storyID)
    }
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                navigateToStory();
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    const navigateToStory = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        // const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${title}`, {
            state: { story_id: storyID, StoryTitle: title },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const handlePlayAudio = (storyID, title, story) => {
        const formattedTitle = title.replace(/\s+/g, '-');
        const serializableStory = {
            storyID: storyID,
            title: title,
            // creationDate: creationDate,
            // Add other serializable properties of the story object here
        };
        // console.log(serializableStory)
        navigate(`/singleaudio/${storyID}/${formattedTitle}`, { state: { story: serializableStory } });
        window.scrollTo(0, 0);
    };
    const handlePlayVideo = (storyID, title) => {
        navigate(`/videostories/${storyID}`,
        );
        window.scrollTo(0, 0);

    }
    const handleDetailView = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${title}`, {
            state: { story_id: storyID, StoryTitle: formattedTitle },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const [isOpen, setIsOpen] = useState(false);
    const [arrowImage, setArrowImage] = useState(ArrowDown);
    const [arrowImageCat, setArrowImageCat] = useState(DownArrow);
    const toggleCategoryList = () => {
        setSortByListVisible(false);
        setSubcategories([]);
        setIsCategoryListVisible(!isCategoryListVisible);
        setIsOpen(!isOpen);
        setArrowImage(isOpen ? ArrowDown : ArrowUp);
        setArrowImageCat(isOpen ? DownArrow : ArrowUp1);
    };

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);

    const mainCategories = [
        { id: 3, name: 'Epic' },
        { id: 2, name: 'Folktales' },
        { id: 1, name: 'Mythology' },
        { id: 99, name: 'Region' },
        // Add more main categories as needed
    ];
    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/sub_categories?pageNumber=1&sortMode=ASC&sortBy=id&pageNumber=1&pageSize=20&primaryCategoryId=${categoryId}`);
            const data = await response.json();
            if (data && data.data) {
                setSubcategories(data.data);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };
    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);
    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        fetchSubcategories(categoryId);
        setIsOpen(true);
    };
    const handleSortByClick = () => {
        setIsOpen(true);

    };
    const [isSortByListVisible, setSortByListVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('All Stories');
    const [containerClassName, setContainerClassName] = useState('ws-stories-catsortby-text');
    const [arrowImage1, setArrowImage1] = useState(ArrowDown1);
    const toggleSortByList = () => {
        // setIsOpen(false);
        // setSortByListVisible(!isSortByListVisible);
        // setContainerClassName(isSortByListVisible ? 'selected-sortby' : 'other-sortby-name');
        // setArrowImage1(isSortByListVisible ? ArrowDown1 : ArrowUp1);
        // Only toggle if the list is not open, or handle closing explicitly when open
        if (!isSortByListVisible) {
            setSortByListVisible(true);
            setContainerClassName('other-sortby-name');
            setArrowImage1(ArrowUp1);
        } else {
            setSortByListVisible(false);
            setContainerClassName('selected-sortby');
            setArrowImage1(ArrowDown1);
        }
    };
    const [divIdToRender, setDivIdToRender] = useState('divTwo'); // Initialize with an empty string or your default value

    const handleOptionClick = (option) => {
        // Prevent selecting the same option again
        if (option === selectedOption) {
            return; // If the option is already selected, do nothing
        }
        setSelectedOption(option);
        setSortByListVisible(false);
        setContainerClassName('selected-sortby');
        setArrowImage1(ArrowDown1)
        setData([]);
        setPage(1);
        // Determine the sorting parameter based on the selected option
        let divIdToRender;
        let sortingParameter;
        let filterParameter;
        switch (option) {
            case 'Recommended':
                sortingParameter = 'recommended';
                // Call handleCross to close the audio player and reset the play symbol
                handleCross();
                setDivIdToRender('divOne');
                break;
            case 'Audio/Video':
                sortingParameter = 'rating';
                filterParameter = 'audioVideo';
                setDivIdToRender('divTwo');
                break;
            case 'Recently Released':
                sortingParameter = 'last_updated_date';

                setDivIdToRender('divTwo');
                break;
            case 'All Stories':
                sortingParameter = 'last_approved_date';
                filterParameter = 'mixed_stories';
                setDivIdToRender('divTwo');
                break;
            default:
                sortingParameter = 'default_parameter';
                setDivIdToRender('divTwo');
        }
        setSortBy(sortingParameter);
        setFilterBy(filterParameter);
        setTimeout(() => {

        }, 1000); // Adjust the timeout value if needed
    };
    const getImgSrc = (option) => {
        return selectedOption === option ? Select : NonSelect;

    };


    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }
    const authorview = (authorId) => {
        navigate(`/authorprofile/${authorId}`);
        window.scrollTo(0, 0);
    }
    const categoryBoxRef = useRef(null);

    const sortByBoxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (categoryBoxRef.current && !categoryBoxRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCategoryListVisible(false);
            setArrowImage(ArrowDown);
            setArrowImageCat(DownArrow);
        }
        if (sortByBoxRef.current && !sortByBoxRef.current.contains(event.target)) {
            setSortByListVisible(false);
            setContainerClassName('selected-sortby');
            setArrowImage1(ArrowDown1);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // audioplayerStart
    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [allowClick, setAllowClick] = useState(true);
    const [storyDetails, setStoryDetails] = useState({
        storyImage: '',
        authorName: '',
        narratorName: '',
        title: ''
    });

    const handlePlayPause = (index) => {
        const clickedStory = data[index]; // Get the clicked story from the data array

        if (!clickedStory) {
            console.error('Invalid index or story data');
            return;
        }
        const { audioLocation, storyThumbnailImage, authorName, narratorName, title, storyRatings, storyViews, storyID, favouriteStory, is_saved, sequence, ListeningCount, views, rating } = clickedStory;

        if (index === currentPlayingIndex) {
            // Clicked on the currently playing story, so pause it
            audio.pause();
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
            setIsInputVisible(true);
            // Add logic to pause the audio for the current story
            // Set the story details when pausing
            setStoryDetails({
                storyImage: '',
                authorName: '',
                narratorName: '',
                title: '',
                storyRatings: '',
                storyViews: '',
                storyID: '',
                favouriteStory: '',
                is_saved: '',
                sequence: '',
                ListeningCount: '',
                views: '',
                rating: ''
            });
        } else {
            // Clicked on a new story, stop the previously playing story
            if (currentPlayingIndex !== null) {
                audio.pause();
                console.log('Paused previous audio');
                setIsInputVisible(false);
                // Pause the previous story if there was one
                // Add logic to pause the audio for the previously playing story
            }

            // Start playing the new story

            audio.src = audioLocation; // Set the new audio source
            audio.play().catch(error => {
                console.error('Play error:', error);
            });

            setIsPlaying(true);
            setCurrentPlayingIndex(index);
            setIsInputVisible(true);
            // Add logic to play the audio for the new story
            // Set the story details when playing
            setStoryDetails({
                storyImage: storyThumbnailImage,
                authorName,
                narratorName,
                title,
                storyRatings,
                storyViews,
                storyID,
                favouriteStory,
                is_saved,
                sequence,
                ListeningCount,
                views,
                rating
            });
        }
    };
    useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);
        return () => {
            newAudio.pause();
            setAudio(null);
        };
    }, []);
    const [currentTime, setCurrentTime] = useState('00:00');
    const [duration, setDuration] = useState(0);
    const handleSeek = (e) => {
        const { value } = e.target;
        audio.currentTime = parseFloat(value); // Convert value to a float
        setCurrentTime(parseFloat(value)); // Update the state with the float value
    };
    const handleTimeRewind = () => {
        if (!audio) return;
        audio.currentTime -= 10; // Rewind 10 seconds
    };

    const handleTimeForward = () => {
        if (!audio) return;
        audio.currentTime += 10; // Forward 10 seconds
    };
    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', () => {
                setCurrentTime(audio.currentTime);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            audio.addEventListener('loadedmetadata', () => {
                setDuration(audio.duration);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', () => {
                setCurrentTime(audio.currentTime);
            });
        }
    }, [audio]);
    const handleBoxPlayPause = () => {
        if (!audio) return;

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play().catch(error => {
                console.error('Play error:', error);
            });
        }
        setIsPlaying(!isPlaying);
    };
    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    const handleCross = () => {
        // Pause the current audio if playing
        if (currentPlayingIndex !== null) {
            audio.pause(); // Stop the audio
            setIsPlaying(false); // Update the play/pause state to show play symbol
            setCurrentPlayingIndex(null); // Reset the current playing index
        }

        // Reset the story details to hide the player and associated details
        setStoryDetails({
            storyImage: '',
            authorName: '',
            narratorName: '',
            title: '',
            storyRatings: '',
            storyViews: '',
            storyID: '',
            favouriteStory: '',
            is_saved: '',
            sequence: '',
            ListeningCount: '',
            views: '',
            rating: ''
        });

        // Hide the input section or other UI elements as necessary
        setIsInputVisible(false);
    };
    const [showSignup, setShowSignup] = useState(false);

    const handleShowSignup = () => {
        setShowSignup(true);
    };
    const handleCloseSignup = () => {
        navigate('/loginpop');
    };
    const toggleStar = (storyID, sequence, index) => {
        const clickedStory = data[index];

        console.log(clickedStory)
        if (!clickedStory) {
            console.error('Invalid index or story data');
            return;
        }

        const story_ID = String(storyID);
        let lastValueOfI; // Declare i outside the loop
        if (allowClick) {
            const updatedFilledStars = [...filledStars];
            for (let i = 0; i <= index; i++) {

                updatedFilledStars[i] = true;
                lastValueOfI = i + 1; // Store the value of i in lastValueOfI
            }
            const requestBody = {
                story_id: story_ID,
                sequence: String(sequence),
                rating: lastValueOfI.toString()
            };
            const requestHeaders = {
                Authorization: `Bearer ${authToken}`,
            };
            axios
                .patch(audio_rating, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Handle the API response here
                })
                .catch((error) => {
                    console.error(error); // Handle errors here
                });
            setFilledStars(updatedFilledStars);
            setAllowClick(false); // Prevent further clicks
        }
    };

    // audioPlayerEnd
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    function formatDateMob(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    return (
        <div>
            <div className={audioList} >
                <div className='ws-stories-basket'>
                    <div>
                        <div className='ws-stories-btn' >
                            <div className='ws-stories-corner-left' ref={categoryBoxRef}>
                                <div className='ws-stories-category-cat'
                                    onClick={toggleCategoryList}
                                >
                                    <img src={StaticCat} alt='category' />
                                </div>
                                <div
                                    className={isCategoryListVisible ? 'other-class-name' : 'ws-stories-category-text'}

                                    onClick={toggleCategoryList}

                                >
                                    Categories
                                    <img src={arrowImage} alt='ArrowDown' className="show-above-950px" />
                                    <img src={arrowImageCat} alt='down' className="show-below-950px" />
                                </div>
                                {isOpen && (
                                    <div className='category-list'

                                    >
                                        {/* Display static main categories */}
                                        {mainCategories.map((category) => (
                                            <div className='category-item' key={category.id} onClick={() => handleCategoryClick(category.id)}
                                                onMouseEnter={() => handleCategoryClick(category.id)}
                                            >
                                                {category.name}
                                            </div>
                                        ))}
                                        {/* Conditionally display fetched subcategories */}
                                        {selectedCategory !== null && subcategories.length > 0 && (
                                            <div className='subcategory-list'>
                                                {subcategories.map((subcategory) => (
                                                    <div className='subcategory-item' key={subcategory.subCategoryID}
                                                        onClick={() => {
                                                            navigate(`/category/${selectedCategory}/subcategory/${subcategory.subCategoryID}/${subcategory.primaryCategoryName}/${subcategory.subCategoryName}`, {
                                                            });
                                                        }} >
                                                        {subcategory.subCategoryName}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {/* Show nothing (empty div) when no subcategories available */}
                                        {selectedCategory !== null && subcategories.length === 0 && <div></div>}
                                    </div>
                                )}
                            </div>


                            <div className='ws-stories-corner-right' ref={sortByBoxRef}>
                                <div className='ws-stories-catsortby' onClick={toggleSortByList}
                                >
                                    <img src={SortByCat} alt='sortby' />
                                </div>
                                <div
                                    className={containerClassName}
                                    onClick={toggleSortByList}

                                >
                                    {/* Sort By */}
                                    {selectedOption}
                                    <div className='sortbyarrow'>
                                        <img src={arrowImage1} alt='ArrowDown' />
                                    </div>

                                </div>
                                {isSortByListVisible && (
                                    <div className='dropdown-options'

                                    >
                                        {(authToken == null || authToken === "no token") ?
                                            <div onClick={handleLoginSignUp}
                                            >

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                            :
                                            <div onClick={() => handleOptionClick('Recommended')}>

                                                <img src={getImgSrc('Recommended')} alt='select' />

                                                Recommended
                                            </div>
                                        }
                                        <div onClick={() => handleOptionClick('Recently Released')}>
                                            <img src={getImgSrc('Recently Released')} alt='select' />

                                            Recently released
                                        </div>
                                        <div onClick={() => handleOptionClick('Audio/Video')}>
                                            <img src={getImgSrc('Audio/Video')} alt='select' />
                                            Audio / Video
                                        </div>
                                        <div onClick={() => handleOptionClick('All Stories')}>
                                            <img src={getImgSrc('All Stories')} alt='select' />
                                            All Stories
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mobile'>
                            <Navigation />
                        </div>
                        <AgeCategory selectedAge={selectedAge}
                            setSelectedAge={setSelectedAge} />
                    </div>
                    {/* other sortby designed */}
                    {divIdToRender === 'divTwo' &&
                        <div className='ws-stories-box-align-bottom' id="divTwo" >
                            {loading ? ( // Conditionally render the loading screen or data

                                <div className='loading'>
                                    <div className='load_gif'><img src={loadinggif} alt='Loading' /></div>
                                </div>
                            ) : (
                                data.map((story, i) => {
                                    const date = new Date(story.creationDate)
                                    const currentFirstday = new Date(new Date().setDate(new Date().getDate() - 1))
                                    const currentSecondday = new Date(new Date().setDate(new Date().getDate() - 2))
                                    const currentThirdday = new Date(new Date().setDate(new Date().getDate() - 3))
                                    const currentForthday = new Date(new Date().setDate(new Date().getDate() - 4))
                                    if (
                                        CurrentDate.toDateString() === date.toDateString() ||
                                        currentFirstday.toDateString() === date.toDateString()
                                        ||
                                        currentSecondday.toDateString() === date.toDateString()
                                        ||
                                        currentThirdday.toDateString() === date.toDateString()
                                        ||
                                        currentForthday.toDateString() === date.toDateString()
                                    ) {
                                        return (
                                            <div key={i}>
                                                <div className='web'>

                                                    <div className='ws-stories-box' key={i}
                                                        onClick={() => handleStoryClick(story.storyID)}
                                                    >
                                                        <div className='ws-stories-img'
                                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' loading="eager" />
                                                        </div>
                                                        <div className='ws-stories-text'>
                                                            <div className='ws-stories-title-img' >
                                                                <div className='ws-stories-box-title'
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                >

                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb-new' >
                                                                    <div className='ws-new-tag'>
                                                                        <img src={NewTag} alt='new-tag' />
                                                                    </div>
                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }


                                                                    </div>

                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }
                                                                    </div>



                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date'>
                                                                <div className='ws-stories-box-author'
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)} >By {story.authorName}&nbsp;-&nbsp;</div>
                                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                            </div>
                                                            <div
                                                                className="ws-stories-box-wrapper"
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                style={{ padding: '45px 0', cursor: 'pointer' }}
                                                            >
                                                                <div className="ws-stories-box-summary">
                                                                    {story.summary}
                                                                </div>
                                                            </div>
                                                            <div className='ws-story-box-viewrate'
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewboxtime'>
                                                                    <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                    <div className='ws-story-ratetext'>mins</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='mobile'>
                                                    <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                        <div className='ws-stories-img' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img fetchpriority="high" src={story.storyThumbnailImage} alt='thumbnail' />
                                                        </div>
                                                        <div className='ws-stories-text' >
                                                            <div className='ws-stories-title-img'>
                                                                <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb'>

                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }


                                                                    </div>
                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }
                                                                    </div>

                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                            </div>
                                                            <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                {story.summary}
                                                            </div>
                                                            <div className='ws-stories-box-view-rate'
                                                            >
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-ratebox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewboxtime'>
                                                                    <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                    <div className='ws-story-ratetext'>mins</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    }
                                    return (
                                        <div key={i}>
                                            {story.is_video ? (
                                                <div>
                                                    <div className='web'>

                                                        <div className='ws-stories-box' key={i}
                                                            onClick={() => handleStoryClick(story.storyID)}
                                                        >
                                                            <div className='as-audio-btn' onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                            >
                                                                <div className='as-audio-img' >
                                                                    <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                                </div>
                                                                <div className='as-audio-player-playbtn'>
                                                                    <div>

                                                                        <div><div className='as-audio-play'>
                                                                            <img src={PlayButtonbtn} alt='Play' />
                                                                        </div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='ws-stories-text'>
                                                                <div className='ws-stories-title-img' >
                                                                    <div className='ws-stories-box-title'
                                                                        onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                    >

                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb' >

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>



                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date'
                                                                >
                                                                    <div className='ws-stories-box-author'
                                                                        onClick={() => handlePlayVideo(story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                                    <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                                </div>
                                                                <div
                                                                    className="ws-stories-box-wrapper"
                                                                    onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                    style={{ padding: '45px 0', cursor: 'pointer' }}
                                                                >
                                                                    <div className="ws-stories-box-summary">
                                                                        {story.summary}
                                                                    </div>
                                                                </div>


                                                                <div className='ws-story-box-viewrate'
                                                                    onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='mobile'>
                                                        <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                            <div className='as-audio-btn' onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                            >
                                                                <div className='as-audio-img' >
                                                                    <img fetchpriority="high" src={story.storyThumbnailImage} alt='thumbnail' />

                                                                </div>
                                                                <div className='as-audio-player-playbtn'>
                                                                    <div>

                                                                        <div><div className='as-audio-play'>
                                                                            <img src={PlayButtonbtn} alt='Play' />
                                                                        </div></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='ws-stories-text' >
                                                                <div className='ws-stories-title-img'>
                                                                    <div className='ws-stories-box-title' onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                    >
                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb'>

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>

                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date' onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                    <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                                </div>
                                                                <div className='ws-stories-box-summary' onClick={() => handlePlayVideo(story.storyID, story.title)}
                                                                >
                                                                    {story.summary}
                                                                </div>
                                                                <div className='ws-stories-box-view-rate'
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-ratebox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ) : story.audioStatus ? (
                                                <div>
                                                    <div className='web'>

                                                        <div className='ws-stories-box' key={i}
                                                            onClick={() => handleStoryClick(story.storyID)}
                                                        >
                                                            <div className='as-audio-btn'
                                                                onClick={() => {
                                                                    handlePlayPause(i);
                                                                }}>
                                                                <div className='as-audio-img' onClick={() => {
                                                                    handlePlayPause(i);
                                                                }}>
                                                                    <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                                </div>
                                                                <div className='as-audio-player-playbtn'>
                                                                    <div>

                                                                        <div>
                                                                            {
                                                                                currentPlayingIndex === i ?
                                                                                    <div className='as-audio-pause'>
                                                                                        <img src={PauseButtonbtnList} alt='Pause' />
                                                                                    </div> :
                                                                                    <div className='as-audio-play'>
                                                                                        <img src={PlayButtonbtnList} alt='Play' />

                                                                                    </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='ws-stories-text'>
                                                                <div className='ws-stories-title-img' >
                                                                    <div className='ws-stories-box-title'
                                                                        onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                    >

                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb' >

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>



                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date'
                                                                >
                                                                    <div className='ws-stories-box-author'
                                                                        onClick={() => handlePlayAudio(story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                                    <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                                </div>
                                                                <div
                                                                    className="ws-stories-box-wrapper"
                                                                    onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                    style={{ padding: '45px 0', cursor: 'pointer' }}
                                                                >
                                                                    <div className="ws-stories-box-summary">
                                                                        {story.summary}
                                                                    </div>
                                                                </div>


                                                                <div className='ws-story-box-viewrate'
                                                                    onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='mobile'>
                                                        <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                            <div className='as-audio-btn'
                                                                onClick={() => {
                                                                    handlePlayPause(i);
                                                                }}>
                                                                <div className='as-audio-img' onClick={() => {
                                                                    handlePlayPause(i);
                                                                }}>
                                                                    <img src={story.storyThumbnailImage} alt='thumbnail' />

                                                                </div>
                                                                <div className='as-audio-player-playbtn'>
                                                                    <div>

                                                                        <div>
                                                                            {currentPlayingIndex === i ?
                                                                                <div className='as-audio-pause'>
                                                                                    <img src={PauseButtonbtnList} alt='Pause' />

                                                                                </div>
                                                                                :
                                                                                <div className='as-audio-play'>
                                                                                    <img src={PlayButtonbtnList} alt='Play' />
                                                                                </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='ws-stories-text' >
                                                                <div className='ws-stories-title-img'>
                                                                    <div className='ws-stories-box-title' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                    >
                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb'>

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>

                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                    <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                                </div>
                                                                <div className='ws-stories-box-summary' onClick={() => handlePlayAudio(story.storyID, story.title)}
                                                                >
                                                                    {story.summary}
                                                                </div>
                                                                <div className='ws-stories-box-view-rate'
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-ratebox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            ) : (
                                                <div>
                                                    <div className='web'>

                                                        <div className='ws-stories-box' key={i}
                                                            onClick={() => handleStoryClick(story.storyID)}
                                                        >
                                                            <div className='ws-stories-img'
                                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                            >
                                                                <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text'>
                                                                <div className='ws-stories-title-img' >
                                                                    <div className='ws-stories-box-title'
                                                                        onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                    >

                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb' >

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>



                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date'
                                                                >
                                                                    <div className='ws-stories-box-author'
                                                                        onClick={handleDetailView.bind(null, story.storyID, story.title)}  >By {story.authorName}&nbsp;-&nbsp;</div>
                                                                    <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                                </div>
                                                                <div
                                                                    className="ws-stories-box-wrapper"
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                    style={{ padding: '45px 0', cursor: 'pointer' }}
                                                                >
                                                                    <div className="ws-stories-box-summary">
                                                                        {story.summary}
                                                                    </div>
                                                                </div>


                                                                <div className='ws-story-box-viewrate'
                                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='mobile'>
                                                        <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                            <div className='ws-stories-img' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <img fetchpriority="high" src={story.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text' >
                                                                <div className='ws-stories-title-img'>
                                                                    <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                    >
                                                                        {story.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb'>

                                                                        <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>

                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                    <div className='ws-stories-box-date'>{formatDateMob(story.creationDate)}</div>
                                                                </div>
                                                                <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    {story.summary}
                                                                </div>
                                                                <div className='ws-stories-box-view-rate'
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{story.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-ratebox'>
                                                                        <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewboxtime'>
                                                                        <div className='ws-story-rate'>  {Number(story.readingTime).toFixed(2).replace('.', ':')}</div>
                                                                        <div className='ws-story-ratetext'>mins</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                }
                                )
                            )}
                        </div>}

                    {/* recommendation designed */}
                    {divIdToRender === 'divOne' &&
                        <div className='ws-stories-box-align-bottom' id="divTwo" >
                            {loading ? ( // Conditionally render the loading screen or data
                                <div className='loading'>
                                    <div className='load_gif'><img src={loadinggif} alt='d' /></div>
                                </div>
                            ) : apiCallCompleted && data.length === 0 ? (
                                <div className='ws-stories-storiescount'>No stories found. See more stories to get recommended Stories.</div>
                            ) : (
                                data.map((story, i) => {
                                    return (
                                        <div key={i}>
                                            <div className='ws-stories-storiescount'>Because you read stories like “{story.input_story_title}”</div>
                                            {story.recommendations.map((recommendation, index) => (
                                                <div key={index}>
                                                    <div className='web'>

                                                        <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(recommendation.story_id)}
                                                        >
                                                            <div className='ws-stories-img'
                                                                onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                            >
                                                                <img src={recommendation.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text'>
                                                                <div className='ws-stories-title-img' >
                                                                    <div className='ws-stories-box-title'
                                                                        onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                    >{recommendation.title}

                                                                    </div>
                                                                    <div className='ws-stories-img-hsb' >
                                                                        <div className='ws-heart' onClick={() => handleShowHeart(recommendation.story_id, i)}>
                                                                            {
                                                                                recommendation.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>
                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(recommendation.story_id, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(recommendation.story_id, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(recommendation.story_id, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date'
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {recommendation.authorName}

                                                                        &nbsp;-&nbsp;</div>
                                                                    <div className='ws-stories-box-date'>{formatDate(recommendation.creationDate)}</div>
                                                                </div>

                                                                <div
                                                                    className="ws-stories-box-wrapper"
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                    style={{ padding: '45px 0', cursor: 'pointer' }}
                                                                >
                                                                    <div className="ws-stories-box-summary">
                                                                        {recommendation.summary}
                                                                    </div>
                                                                </div>
                                                                <div className='ws-story-box-viewrate'
                                                                    onClick={handleDetailView.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{recommendation.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-rate'>{Number(recommendation.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className='mobile'>
                                                        <div className='ws-stories-box' onClick={() => handleStoryClick(recommendation.story_id)}>
                                                            <div className='ws-stories-img' onClick={navigateToStory.bind(null, recommendation.storyID, recommendation.title)}
                                                            >
                                                                <img fetchpriority="high" src={recommendation.storyThumbnailImage} alt='thumbnail' />
                                                            </div>
                                                            <div className='ws-stories-text' >
                                                                <div className='ws-stories-title-img'>
                                                                    <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                    >
                                                                        {recommendation.title}
                                                                    </div>
                                                                    <div className='ws-stories-img-hsb'>
                                                                        <div className='ws-heart' onClick={() => handleShowHeart(recommendation.story_id, i)}>
                                                                            {
                                                                                story.favouriteStory ? (
                                                                                    heartedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />

                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    heartedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unfavouriteStory(recommendation.story_id, i)}
                                                                                                src={HeartFilled}
                                                                                                alt='heart'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => favouriteStory(recommendation.story_id, i)}
                                                                                                src={Heart}
                                                                                                alt='heart'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }


                                                                        </div>

                                                                        <div className='ws-bookmark' onClick={() => handleShowBookmark(recommendation.story_id, i)}>
                                                                            {
                                                                                story.is_saved ? (
                                                                                    bookmarkedStories[i] ? (
                                                                                        <img
                                                                                            onClick={() => savedStory(recommendation.story_id, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />



                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )
                                                                                ) : (
                                                                                    bookmarkedStories[i] ?
                                                                                        (
                                                                                            <img
                                                                                                onClick={() => unsavedStory(recommendation.story_id, i)}
                                                                                                src={BookmarkFilled}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        ) : (
                                                                                            <img
                                                                                                onClick={() => savedStory(recommendation.story_id, i)}
                                                                                                src={Bookmark}
                                                                                                alt='bookmark'
                                                                                            />
                                                                                        )

                                                                                )
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    {showPopup && (
                                                                        <div className="pop-up-overlay">
                                                                            <div className="pop-up-box">
                                                                                <p>Login first</p>
                                                                                <button onClick={handleClosePopup}>Close</button>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className='ws-stories-box-auth-date' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    <div className='ws-stories-box-author'>By {recommendation.authorName} - </div>
                                                                    <div className='ws-stories-box-date'>{formatDate(recommendation.creationDate)}</div>
                                                                </div>
                                                                <div className='ws-stories-box-summary' onClick={navigateToStory.bind(null, recommendation.story_id, recommendation.title)}
                                                                >
                                                                    {recommendation.summary}
                                                                </div>
                                                                <div className='ws-stories-box-view-rate'
                                                                >
                                                                    <div className='ws-stories-box-viewbox'>
                                                                        <div className='ws-story-view'>{recommendation.views}</div>
                                                                        <div className='ws-story-viewtext'>views</div>
                                                                    </div>
                                                                    <div className='ws-stories-box-ratebox'>
                                                                        <div className='ws-story-rate'>{Number(recommendation.rating).toFixed(2)}</div>
                                                                        <div className='ws-story-ratetext'>rating</div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )

                                })
                            )}
                        </div>}
                </div>
                <div className='ws-stories-ads'>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                    <div className='ws-stories-ads-block'>
                        <AdsListView />
                    </div>
                </div>
            </div>
            <div className={audioPagination}>
                <div className='ws-stories-pagination'>

                </div>
            </div>
            {divIdToRender === 'divTwo' &&
                <div className={audioPagination}>
                    <div className="pagination" id='divTwo'>
                        <div className="page-count">
                            {page} of {totalPages}
                        </div>
                        <div className="pagination-controls">
                            <div className="pagination-center">
                                <div className="page-numbers">
                                    <div className='web'>
                                        <button className="first-page-button" onClick={goToFirstPage}>
                                            Go to First
                                        </button>
                                    </div>
                                    <button className="prev-page-button" onClick={goToPrevPage}>
                                        <img src={ArrowLeft} />
                                    </button>
                                    {renderPageNumbers()}
                                    {!allStoriesLoaded && page < totalPages && (
                                        <>
                                            <><button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}><img src={ArrowRight} /></button></>
                                            {/* <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>

                                        </button> */}
                                        </>


                                    )}
                                    <div className='web'>
                                        <button className="last-page-button" onClick={goToLastPage}>
                                            Go to Last
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {divIdToRender === 'divOne' &&
                <div className={audioPagination}>
                    <div id='divOne'>
                        <div className="pagination" id='divTwo'>
                            <div className="page-count">
                                {page} of {totalPages}
                            </div>
                            <div className="pagination-controls">
                                <div className="pagination-center">
                                    <div className="page-numbers">
                                        <div className='web'>
                                            <button className="first-page-button" onClick={goToFirstPage}>
                                                Go to First
                                            </button>
                                        </div>
                                        <button className="prev-page-button" onClick={goToPrevPage}>
                                            <img src={ArrowLeft} />
                                        </button>
                                        {renderPageNumbers()}
                                        {!allStoriesLoaded && page < totalPages && (
                                            <>
                                                <><button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}><img src={ArrowRight} /></button></>
                                                {/* <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>

                                        </button> */}
                                            </>


                                        )}
                                        <div className='web'>
                                            <button className="last-page-button" onClick={goToLastPage}>
                                                Go to Last
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className={audioPagination}>
                <div className="ws-stories-pagination-box">

                </div>
            </div>

            {isInputVisible && (
                <div
                    className={expandedClassName}

                >

                    {/* Render story details when available */}
                    {storyDetails.storyImage && (
                        <div>
                            <div className='mobile'>
                                <div className={seekingInputMob}>
                                    <input id='myinput'
                                        type="range"
                                        min={0}
                                        max={duration}
                                        value={currentTime}
                                        onChange={handleSeek}
                                        className='slider'
                                    />
                                </div>
                            </div>
                            <div className={audioPlayerBox}>
                                <div className='as-playstories-img'>
                                    <img src={storyDetails.storyImage} alt='Story' />

                                </div>
                                <div className='as-player-authorName'>
                                    <div className='as-player-storyTitle'>{storyDetails.title} </div>
                                </div>
                                <div className='as-player-rplaypausef'>
                                    <div className='as-player-playpausebtn'>
                                        <div className='as-player-timerewind' onClick={handleTimeRewind}>
                                            <img src={TimeRewind} alt='TimeRewind' />
                                        </div>
                                        <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                            {isPlaying ? (
                                                <img src={PauseButtonbtn} alt='Pause' />
                                            ) : (
                                                <img src={PlayButtonbtn} alt='Play' />
                                            )}
                                        </div>

                                        <div className='as-player-timeforward' onClick={handleTimeForward}>
                                            <img src={TimeForward} alt='Timeforward' />
                                        </div>

                                    </div>
                                    <div className='web'>
                                        <div className='seekbar'>
                                            <div className='as-player-currenttime'>{formatTime(currentTime)}</div>
                                            <div className='seekinginput'>
                                                <input id='myinput'
                                                    type="range"
                                                    min={0}
                                                    max={duration}
                                                    value={currentTime}
                                                    onChange={handleSeek}
                                                    className='slider'
                                                />
                                            </div>
                                            <div className='as-player-currenttime'>{formatTime(duration)}</div>
                                        </div>

                                    </div>

                                </div>
                                <div className='as-expandbox '>
                                    <div className='as-crossbtn'
                                        onClick={handleCross}
                                    >
                                        <img src={Cross} alt='expand' />
                                    </div>
                                    <div className='as-expandbtn'
                                        onClick={onExpandClick}
                                    >
                                        <img src={Expand} alt='expand' />
                                    </div>
                                </div>
                            </div>
                            <div className='web'>
                                <div className={audioPlayerBoxExpand}>

                                    <div className='as-audio-detail-view'>
                                        <div className='as-audio-backbtn'
                                            onClick={() => {
                                                onExpandClose();
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <img src={ArrowLeft} alt='backbtn' />
                                            <div className='as-audio-now-playing'>
                                                Now Playing
                                            </div>
                                        </div>
                                        <div className='as-audio-detailview'>
                                            <div className='as-detailview-thumbnailimg'>
                                                <img src={storyDetails.storyImage} alt='Story' />

                                            </div>
                                            <div className='as-detailview-storyTitle'>
                                                <div className='as-detailview-heart' >
                                                    <div className='as-detailview-heart-icon' onClick={() => handleShowHeart(storyDetails.storyID)}>
                                                        {
                                                            storyDetails.favouriteStory ? (
                                                                heartedStories ? (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />


                                                                ) : (
                                                                    <img
                                                                        onClick={() => favouriteStory(storyDetails.storyID)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />
                                                                )
                                                            ) : (
                                                                heartedStories ?
                                                                    (
                                                                        <img
                                                                            onClick={() => favouriteStory(storyDetails.storyID)}
                                                                            src={Heart}
                                                                            alt='heart'
                                                                        />

                                                                    ) : (
                                                                        <img
                                                                            onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                            src={HeartFilled}
                                                                            alt='heart'
                                                                        />

                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                <div className='as-audio-TitleAuthorNarrator'>
                                                    <div className='as-audio-storyTitle'>{storyDetails.title}</div>
                                                    <div className='as-detailview-authorNarrator'>
                                                        <div>{storyDetails.authorName},&nbsp;

                                                            {storyDetails.narratorName}</div>

                                                    </div>
                                                </div>

                                                <div className='as-detailview-bookmark-share'>
                                                    <div className='ws-bookmarkEX' onClick={() => handleShowBookmark(storyDetails.storyID)}>
                                                        {
                                                            storyDetails.is_saved ? (
                                                                bookmarkedStories ? (


                                                                    <img
                                                                        onClick={() => unsavedStory(storyDetails.storyID)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => savedStory(storyDetails.storyID)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />

                                                                )
                                                            ) : (
                                                                bookmarkedStories ?
                                                                    (<img
                                                                        onClick={() => savedStory(storyDetails.storyID)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />

                                                                    ) : (
                                                                        <img
                                                                            onClick={() => unsavedStory(storyDetails.storyID)}
                                                                            src={BookmarkFilled}
                                                                            alt='bookmark'
                                                                        />

                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='as-detailview-playrate'>
                                                <div className='as-detail-play'><span className='as-detail-playno'>{storyDetails.views}&nbsp;</span> play</div>
                                                <div className='as-detail-rate'> <span className='as-detail-rateno'>{storyDetails.rating}&nbsp; </span>rating</div>
                                            </div>
                                            <div className='as-audio-playpausebtn'>
                                                <div className='as-player-timerewindEX' onClick={handleTimeRewind}>
                                                    <img src={TimeRewindEX} alt='TimeRewind' />
                                                </div>
                                                <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                                    {isPlaying ? (
                                                        <img src={PauseButtonEx} alt='Pause' />
                                                    ) : (
                                                        <img src={PlayButtonEx} alt='Play' />
                                                    )}
                                                </div>

                                                <div className='as-player-timeforwardEX' onClick={handleTimeForward}>
                                                    <img src={TimeForwardEX} alt='Timeforward' />
                                                </div>
                                            </div>
                                            <div className='seekbar-expand'>

                                                <div className='seekinginput-expand'>
                                                    <input id='myinput'
                                                        type="range"
                                                        min={0}
                                                        max={duration}
                                                        value={currentTime}
                                                        onChange={handleSeek}
                                                        className='slider-expand'
                                                    />
                                                </div>
                                            </div>
                                            <div className='as-audio-timeShow'>
                                                <div className='as-player-currenttimeEX'>{formatTime(currentTime)}</div>
                                                <div className='as-player-maxtimeEX'>{formatTime(duration)}</div>
                                            </div>

                                            <div className='as-audio-rating'>
                                                <div className='as-detail-rating'>
                                                    Rate Story
                                                </div>
                                                {(authToken == null || authToken === "no token") ?
                                                    <div className='as-audio-detail-ratestar'

                                                    >

                                                        {filledStars.map((isFilled, index) => (
                                                            <img

                                                                key={index}
                                                                src={isFilled ? StarFill : Star}
                                                                alt={`Star ${index + 1}`}
                                                                onClick={handleShowSignup}
                                                            />
                                                        ))}
                                                    </div>
                                                    :
                                                    <div className='as-audio-detail-ratestar'

                                                    >

                                                        {filledStars.map((isFilled, index) => (
                                                            <img

                                                                key={index}
                                                                src={isFilled ? StarFill : Star}
                                                                alt={`Star ${index + 1}`}
                                                                onClick={() => toggleStar(storyDetails.storyID, storyDetails.sequence, index)}
                                                            />
                                                        ))}
                                                    </div>
                                                }


                                            </div>
                                            {showSignup && (
                                                <div className="speech-bubble-container">


                                                    <div className="speech-bubble" onClick={handleCloseSignup}>
                                                        <div className="box-content">
                                                            <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className='as-detailview-ads'>
                                        <AdsDetailView />
                                    </div>
                                </div>
                            </div>
                            <div className='mobile'>
                                <div className={audioPlayerBoxExpand}>
                                    <div className='as-mob-audio-detail-view'>
                                        <div className='as-mob-navbar'>
                                            <div className='as-mob-arrowdown'
                                                onClick={() => {
                                                    onExpandClose();
                                                    window.scrollTo(0, 0);
                                                }}
                                            >
                                                <img src={ArrowDownDetail} alt='ArrowDown' />
                                            </div>

                                            <div className='as-mob-nowplaying'>
                                                Now Playing
                                            </div>
                                            
                                        </div>
                                        <div className='as-mob-category-img'>
                                            <img src={storyDetails.storyImage} alt='Story' />
                                        </div>
                                        <div className='as-detailview-storyTitle'>
                                            <div className='as-detailview-heart' >
                                                <div className='as-detailview-heart-icon' onClick={() => handleShowHeart(storyDetails.storyID)}>
                                                    {
                                                        storyDetails.favouriteStory ? (
                                                            heartedStories ? (
                                                                <img
                                                                    onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                    src={HeartFilled}
                                                                    alt='heart'
                                                                />


                                                            ) : (
                                                                <img
                                                                    onClick={() => favouriteStory(storyDetails.storyID)}
                                                                    src={Heart}
                                                                    alt='heart'
                                                                />
                                                            )
                                                        ) : (
                                                            heartedStories ?
                                                                (
                                                                    <img
                                                                        onClick={() => favouriteStory(storyDetails.storyID)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />

                                                                )

                                                        )
                                                    }

                                                </div>
                                            </div>
                                            <div className='as-audio-TitleAuthorNarrator'>
                                                <div className='as-audio-storyTitle'>{storyDetails.title}</div>
                                                <div className='as-detailview-authorNarrator'>
                                                    <div>{storyDetails.authorName},&nbsp;&nbsp;

                                                        {storyDetails.narratorName}</div>

                                                </div>
                                            </div>

                                            <div className='as-detailview-bookmark-share'>
                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(storyDetails.storyID)}>
                                                    {
                                                        storyDetails.is_saved ? (
                                                            bookmarkedStories ? (


                                                                <img
                                                                    onClick={() => unsavedStory(storyDetails.storyID)}
                                                                    src={BookmarkFilled}
                                                                    alt='bookmark'
                                                                />

                                                            ) : (
                                                                <img
                                                                    onClick={() => savedStory(storyDetails.storyID)}
                                                                    src={Bookmark}
                                                                    alt='bookmark'
                                                                />

                                                            )
                                                        ) : (
                                                            bookmarkedStories ?
                                                                (<img
                                                                    onClick={() => savedStory(storyDetails.storyID)}
                                                                    src={Bookmark}
                                                                    alt='bookmark'
                                                                />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unsavedStory(storyDetails.storyID)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />

                                                                )

                                                        )
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        <div className='as-detailview-playrate'>
                                            <div className='as-detail-play'><span className='as-detail-playno'>{storyDetails.views}&nbsp;</span> Views</div>
                                            <div className='as-detail-rate'> <span className='as-detail-rateno'>{storyDetails.rating}&nbsp; </span>rating</div>
                                        </div>
                                        <div className='seekbar-expand'>

                                            <div className='seekinginput-expand'>
                                                <input id='myinput'
                                                    type="range"
                                                    min={0}
                                                    max={duration}
                                                    value={currentTime}
                                                    onChange={handleSeek}
                                                    className='slider-expand'
                                                />
                                            </div>
                                        </div>
                                        <div className='as-audio-expand-time'>
                                            <div className='as-audio-currenttime'>
                                                {formatTime(currentTime)}
                                            </div>
                                            <div className='as-audio-maxtime'>
                                                {formatTime(duration)}
                                            </div>
                                        </div>
                                        <div className='as-audio-playpausebtn'>
                                            <div className='as-player-timerewind' onClick={handleTimeRewind}>
                                                <img src={TimeRewindEX} alt='TimeRewind' />
                                            </div>
                                            <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                                {isPlaying ? (
                                                    <img src={PauseButtonEx} alt='Pause' />
                                                ) : (
                                                    <img src={PlayButtonEx} alt='Play' />
                                                )}
                                            </div>

                                            <div className='as-player-timeforward' onClick={handleTimeForward}>
                                                <img src={TimeForwardEX} alt='Timeforward' />
                                            </div>

                                        </div>
                                        <div className='as-audio-rating'>
                                            <div className='as-detail-rating'>
                                                Rate Story
                                            </div>
                                            <div className='as-audio-detail-ratestar'

                                            >
                                                {filledStars.map((isFilled, index) => (
                                                    <img

                                                        key={index}
                                                        src={isFilled ? StarFill : Star}
                                                        alt={`Star ${index + 1}`}
                                                        onClick={handleShowSignup}
                                                    />
                                                ))}
                                            </div>

                                        </div>
                                        {showSignup && (
                                            <div className="speech-bubble-container">


                                                <div className="speech-bubble" onClick={handleCloseSignup}>
                                                    <div className="box-content">
                                                        <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div >
    )
}

export default Stories
