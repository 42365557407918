/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/AuthorAudioStoryCard.css';
import pencil from '../../../Assets/AuthorProfile/Pencil.svg';
import pencilmob from '../../../Assets/AuthorProfile/PencilMob.svg';
import speaker from '../../../Assets/AuthorProfile/Speaker.svg';
import speakermob from '../../../Assets/AuthorProfile/SpeakerMob.svg';
import playButton from './../../../Assets/AuthorProfile/Play button.svg';
import { useNavigate } from 'react-router-dom';

const AuthorAudioStoryCard = ({ storyDetails }) => {
    const navigate = useNavigate();
    const handlePlayAudio = (storyID, title, story) => {
        const formattedTitle = title.replace(/\s+/g, '-');
        const serializableStory = {
            storyID: storyID,
            title: title,
            // creationDate: creationDate,
            // Add other serializable properties of the story object here
        };
        // console.log(serializableStory)
        navigate(`/singleaudio/${storyID}/${formattedTitle}`, { state: { story: serializableStory } });
        window.scrollTo(0, 0);
    };
    return (
        <div>
            <div className='web'>
                <div className='AuthorAudioStoryCardMainSection'>

                    <div className='AuthorAudioStoryCardImgSection' onClick={() => handlePlayAudio(storyDetails.storyID, storyDetails.title)}>
                        <div onClick={() => {
                            navigate(`/kids-bedtime-stories-audio/${storyDetails.storyID}/${storyDetails.sequence}/${storyDetails.storyTitle.replace(/\s+/g, '-')}`
                                //     , { state: { data: story }
                                //  }
                            );
                            window.scrollTo(0, 0);
                        }}>
                            <img src={storyDetails.storyBackGroundImage} />
                        </div>
                        <div className='AuthorAudioStoryCardImgSectionBg'></div>
                        <div id='AuthorAudioStoryCardImgSectionPlay' onClick={() => {
                            if (storyDetails && storyDetails.storyTitle) {
                                navigate(`/kids-bedtime-stories-audio/${storyDetails.storyID}/${storyDetails.sequence}/${storyDetails.storyTitle.replace(/\s+/g, '-')}`
                                    // , { state: { data: story } }
                                );
                                window.scrollTo(0, 0);
                            } else {
                                console.error("storyDetails or storyTitle is undefined");
                            }
                        }}>
                            <img src={playButton} />
                        </div>
                    </div>

                    <div className='AuthorAudioStoryCardInfoSection' onClick={() => handlePlayAudio(storyDetails.storyID, storyDetails.title)}>

                        <div className='AuthorAudioStoryCardInfoSectionStoryTitle'>
                            {storyDetails.title}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionAuthor'>
                            <span>
                                <img src={pencil} />
                            </span>
                            <span> </span>
                            <span className='AuthorAudioStoryCardInfoSectionWriterName'>
                                {storyDetails.authorName}
                            </span>
                            <span>
                                <img src={speaker} />
                            </span>
                            <span> </span>
                            <span className='AuthorAudioStoryCardInfoSectionSpeakerName'>
                                {storyDetails.narratorName}
                            </span>
                            <span className='AuthorAudioStoryCardInfoSectionAuthorPostDate'>
                                - {storyDetails.creationDate}
                            </span>
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionStoryBrief'>
                            {storyDetails.summary}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionData'>
                            <div className='AuthorAudioStoryCardInfoSectionDataViews'>
                                <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                                    {storyDetails.views}
                                </div>

                                <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                                    plays
                                </div>
                            </div>

                            <div className='AuthorAudioStoryCardInfoSectionDataRating'>
                                <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                                    {storyDetails.rating}
                                </div>

                                <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                                    rating
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='mobile'>
                <div className='AuthorAudioStoryCardMainSection'onClick={() => handlePlayAudio(storyDetails.storyID, storyDetails.title)}>

                    <div className='AuthorAudioStoryCardImgSection'>
                        <div>
                            <img src={storyDetails.storyBackGroundImage} />
                        </div>
                        <div className='AuthorAudioStoryCardImgSectionBg'></div>
                        <div id='AuthorAudioStoryCardImgSectionPlay'>
                            <img src={playButton} />
                        </div>
                    </div>

                    <div className='AuthorAudioStoryCardInfoSection'>

                        <div className='AuthorAudioStoryCardInfoSectionStoryTitle'>
                            {storyDetails.title}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionAuthor'>
                            <span>
                                <img src={pencilmob} />
                            </span>
                            <span> </span>
                            <span className='AuthorAudioStoryCardInfoSectionWriterName'>
                                {storyDetails.authorName}
                            </span>


                        </div>
                        <div>
                            <span>
                                <img src={speakermob} />
                            </span>
                            <span> </span>
                            <span className='AuthorAudioStoryCardInfoSectionSpeakerName'>
                                {storyDetails.narratorName}
                            </span>
                            <span className='AuthorAudioStoryCardInfoSectionAuthorPostDate'>
                                - {storyDetails.creationDate}
                            </span>
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionStoryBrief'>
                            {storyDetails.summary}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionData'>
                            <div className='AuthorAudioStoryCardInfoSectionDataViews'>
                                <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                                    {storyDetails.views}
                                </div>

                                <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                                    plays
                                </div>
                            </div>

                            <div className='AuthorAudioStoryCardInfoSectionDataRating'>
                                <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                                    {storyDetails.rating}
                                </div>

                                <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                                    rating
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AuthorAudioStoryCard