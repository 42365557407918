/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : This code is to provide data model for Written stories 
Brief :The code defines various API endpoints used for accessing and managing Written Stories' data. It includes endpoints for retrieving all stories, top-viewed stories, top-rated stories, author lists, updating story ratings and views, accessing stories by author ID, and retrieving detailed information for a specific story by its ID.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

export const all_stories = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages?sortMode=DESC&status=3`
export const videostories = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages?sortMode=DESC&filterBy=video_stories&status=3`
export const audiostories = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages?sortMode=DESC&status=3`
export const top_view = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages?sortMode=DESC&sortBy=views&filterBy=all_stories&status=3`
export const top_rated = `https://wikibedtimestories.com:9091/get_Stories_Pages?sort_by=ratings&user_id=14&sort_mode=DESC&mode=all_stories`;
export const Add_Favourite = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/favourite`
export const Remove_Favourite = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/unfavourite`
export const Add_saved = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/save`;
export const Remove_saved = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/unSave`;

export const author_list = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/authors?`
export const update_story_rating = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/rating`
export const new_user_rating =`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/rating`
export const audio_rating = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/audio/rating`
export const update_story_views = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/view`
export const storiesby_authorid = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/author?sortBy=ratings&user_id=0&sortMode=ASC`;
export const story_detail_story_id = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story?`
export const story_detail_storyTitle = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/by_name?`

export const storycount = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/story_count`
export const airecommended = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/ai/story_status`