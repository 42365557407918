/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Search Result Page 
Brief :
Search, displays search results based on provided data.
It generates a list of stories fetched from a source and renders each story item containing title, author, creation date, summary, views, and rating. 
Clicking on an item navigates to a detailed view of that story. 
It also offers pagination to navigate through multiple pages of search results and allows sharing, bookmarking, and favoriting stories. 
Additionally, it incorporates handling for desktop and mobile views.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import RightArrow from '../../../WS-WrittenStories/Assets/Images/ArrowRight.webp'
import LeftArrow from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import CatNumb from '../../../WS-WrittenStories/Assets/Images/CatNumb.webp'
import { Add_Favourite, Add_saved, airecommended, Remove_Favourite, Remove_saved, update_story_views } from '../../../../../Model/WS-WrittenStories/WrittenStories';
import axios from 'axios';
import ArrowLeft from '../../../WS-WrittenStories/Assets/Images/ArrowLeftPagination.webp'
import ArrowRight from '../../../WS-WrittenStories/Assets/Images/ArrowRightPagination.webp'

function Search({ data, allStoriesLoaded,
    setPage, page, searchTextParam, setData, totalStories,
    totalStoriesObject }) {
    const location = useLocation();
    const [UserID, setUserID] = useState(localStorage.getItem('UserID') || null);

    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);


    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const ai = async (storyID) => {
        const url = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/ai/story_status`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': authToken,

        };
        storyID = String(storyID);
        const body = JSON.stringify({
            "story_id": storyID,
            "story_type": "read",
            "time": "10"
        });

        try {
            const response = await fetch(airecommended, {
                method: 'PUT',
                headers: headers,
                body: body,
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

        } catch (error) {
            console.error('Fetch error:', error);
        }
    };
    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(data.length).fill(false));

    // Function to handle bookmark click for a specific story
    const handleShowBookmark = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedBookmarkedStories = [...bookmarkedStories];

            // Toggle the bookmarked state for the specific story
            updatedBookmarkedStories[index] = !updatedBookmarkedStories[index];

            // Update the bookmarked state for all stories
            setBookmarkedStories(updatedBookmarkedStories);

            // Perform other actions for bookmarking like updating the story if needed
            // updateStoryBookmark(storyID);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);

        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const [heartedStories, setHeartedStories] = useState(new Array(data.length).fill(false));

    // Function to handle heart click for a specific story
    const handleShowHeart = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the heartedStories array
            const updatedHeartedStories = [...heartedStories];

            // Toggle the hearted state for the specific story
            updatedHeartedStories[index] = !updatedHeartedStories[index];

            // Update the hearted state for all stories
            setHeartedStories(updatedHeartedStories);

            // Perform other actions like updating the story if needed
        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    function formatDate(dateString) {
        const date = new Date(dateString);

        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    const PAGE_LIMIT = 10;
    const totalPages = Math.ceil(totalStories / PAGE_LIMIT);

    const goToNextPage = () => {
        if (!allStoriesLoaded) {
            setPage(page + 1);
            setData([]);
        }
    };
    const goToFirstPage = () => {
        if (page > 1) {
            setPage(1);
            setData([]);
            window.scrollTo(0, 0);
        }
    };

    const goToLastPage = () => {
        if (page < totalPages) {
            setPage(totalPages);
            setData([]);
            window.scrollTo(0, 0);
        }
    };

    const goToPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setData([]);
            window.scrollTo(0, 0);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPages = 8; // Set your maximum number of pages
        for (let i = 1; i <= Math.min(totalPages, maxPages); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-number ${i === page ? 'active' : ''}`}
                    onClick={() => {
                        if (i === page) return; // Prevent API call and navigation for the active page
                        setPage(i);
                        setData([]);
                        window.scrollTo(0, 0); // Clear previous data

                    }}
                >
                    {i} {/* Display the page number directly */}
                </button>
            );
        }
        return pageNumbers;
    };
    const handleDetailView = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${title}`, {
            state: { story_id: storyID },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    const handleStoryClick = (storyID) => {
        updateStory(storyID);
        ai(storyID)
    }
    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    return (
        <div className='sm-stories-search'>
            <p className='search-result'>Search results for "{searchTextParam}"</p>
            <div className='ws-stories'>
                <div className='ws-stories-basket'>
                    {data.length === 0 && allStoriesLoaded ? (
                        <div className='no-results'>No Stories Found</div>
                    ) : (

                        data.map((story, i) => (

                            <div key={i}>
                                <div className='web'>

                                    <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(story.storyID)}>
                                        <div className='ws-stories-img'
                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                        >
                                            <img src={story.storyThumbnailImage} alt='thumbnail' />
                                        </div>
                                        <div className='ws-stories-text'>
                                            <div className='ws-stories-title-img' >
                                                <div className='ws-stories-box-title'
                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                >
                                                    {story.title}
                                                </div>
                                                <div className='ws-stories-img-hsb' >
                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                        {
                                                            story.favouriteStory ? (
                                                                heartedStories[i] ? (
                                                                    <img
                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />
                                                                )
                                                            ) : (
                                                                heartedStories[i] ?
                                                                    (
                                                                        <img
                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                            src={HeartFilled}
                                                                            alt='heart'
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                            src={Heart}
                                                                            alt='heart'
                                                                        />
                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                        {
                                                            story.is_saved ? (
                                                                bookmarkedStories[i] ? (
                                                                    <img
                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />



                                                                ) : (
                                                                    <img
                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />
                                                                )
                                                            ) : (
                                                                bookmarkedStories[i] ?
                                                                    (
                                                                        <img
                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                            src={BookmarkFilled}
                                                                            alt='bookmark'
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                            src={Bookmark}
                                                                            alt='bookmark'
                                                                        />
                                                                    )

                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                {showPopup && (
                                                    <div className="pop-up-overlay">
                                                        <div className="pop-up-box">
                                                            <p>Login first</p>
                                                            <button onClick={handleClosePopup}>Close</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='ws-stories-box-auth-date'
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                            >
                                                <div className='ws-stories-box-author'>By {story.authorName}-</div>
                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                            </div>

                                            <div
                                                className="ws-stories-box-wrapper"
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                style={{ padding: '45px 0', cursor: 'pointer' }}
                                            >
                                                <div className="ws-stories-box-summary">
                                                    {story.summary}
                                                </div>
                                            </div>
                                            <div className='ws-story-box-viewrate'
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                            >
                                                <div className='ws-stories-box-viewbox'>
                                                    <div className='ws-story-view'>{story.views}</div>
                                                    <div className='ws-story-viewtext'>views</div>
                                                </div>
                                                <div className='ws-stories-box-viewbox'>
                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                    <div className='ws-story-ratetext'>rating</div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='mobile'>
                                    <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                        <div className='ws-stories-img'
                                            onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                        >
                                            <img src={story.storyThumbnailImage} alt='thumbnail' />
                                        </div>
                                        <div className='ws-stories-text' >
                                            <div className='ws-stories-title-img'>
                                                <div className='ws-stories-box-title'
                                                    onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                                >
                                                    {story.title}
                                                </div>
                                                <div className='ws-stories-img-hsb'>
                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                        {
                                                            story.favouriteStory ? (
                                                                heartedStories[i] ? (
                                                                    <img
                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />
                                                                )
                                                            ) : (
                                                                heartedStories[i] ?
                                                                    (
                                                                        <img
                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                            src={HeartFilled}
                                                                            alt='heart'
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                            src={Heart}
                                                                            alt='heart'
                                                                        />
                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                        {
                                                            story.is_saved ? (
                                                                bookmarkedStories[i] ? (
                                                                    <img
                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />



                                                                ) : (
                                                                    <img
                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />
                                                                )
                                                            ) : (
                                                                bookmarkedStories[i] ?
                                                                    (
                                                                        <img
                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                            src={BookmarkFilled}
                                                                            alt='bookmark'
                                                                        />
                                                                    ) : (
                                                                        <img
                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                            src={Bookmark}
                                                                            alt='bookmark'
                                                                        />
                                                                    )

                                                            )
                                                        }

                                                    </div>


                                                </div>
                                                {showPopup && (
                                                    <div className="pop-up-overlay">
                                                        <div className="pop-up-box">
                                                            <p>Login first</p>
                                                            <button onClick={handleClosePopup}>Close</button>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                            <div className='ws-stories-box-auth-date'
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                            >
                                                <div className='ws-stories-box-author'>By {story.authorName}-</div>
                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                            </div>
                                            <div className='ws-stories-box-summary'
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                            >
                                                {story.summary}
                                            </div>
                                            <div className='ws-stories-box-view-rate'
                                                onClick={handleDetailView.bind(null, story.storyID, story.title)}
                                            >

                                                <div className='ws-stories-box-viewbox'>
                                                    <div className='ws-story-view'>{story.views}</div>
                                                    <div className='ws-story-viewtext'>views</div>
                                                </div>
                                                <div className='ws-stories-box-ratebox'>
                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                    <div className='ws-story-ratetext'>rating</div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    )}
                </div>
                <div className='ws-stories-ads'>


                </div>
            </div>
            <div className='ws-stories-pagination'>

            </div>

            <div className="pagination">
                <div className="page-count">
                    {page} of {totalPages}
                </div>
                <div className="pagination-controls">
                    <div className="pagination-center">
                        {/* {page > 1 && (
                            <button className="prev-button" onClick={() => { goToPrevPage(); window.scrollTo(0, 0); }}>
                                <img src={LeftArrow} alt='LeftArrow' />
                                Prev
                            </button>
                        )} */}
                        <div className="page-numbers">
                            <div className='web'>
                                <button className="first-page-button" onClick={goToFirstPage}>
                                    Go to First
                                </button>
                            </div>
                            <button className="prev-page-button" onClick={goToPrevPage}>
                                <img src={ArrowLeft} />
                            </button>
                            {renderPageNumbers()}
                            {!allStoriesLoaded && page < totalPages && (
                                <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>
                                    {/* Next */}
                                    <img src={ArrowRight} />
                                    {/* <img src={RightArrow} alt='RightArrow' /> */}
                                </button>
                            )}
                            <div className='web'>
                                <button className="last-page-button" onClick={goToLastPage}>
                                    Go to Last
                                </button>
                            </div>
                        </div>
                        {/* {
                            !allStoriesLoaded && page < totalPages ? (
                                <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>
                                    Next
                                    <img src={RightArrow} alt='RightArrow' />
                                </button>
                            ) : null
                        } */}

                    </div>
                </div>
            </div>
            <div className="ws-stories-pagination-box">

            </div>
        </div>
    );
}
export default Search
