/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../../CS/AuthorPublicationCard.css';
import { useNavigate } from 'react-router-dom';

const AuthorPublicationCard = ({ storyDetails }) => {
    const navigate = useNavigate();
    const handleDetailView = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');
    
        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${title}`, {
            state: { story_id: storyID },
        });
    
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };
    return (
        <div >
            <div className='web'>
                <div className='AuthorPublicationCardMainSection'>

                    <div className='AuthorPublicationCardImgSection' onClick={handleDetailView.bind(null, storyDetails.storyID, storyDetails.title)}>
                        <img src={storyDetails.storyBackGroundImage} />
                    </div>

                    <div className='AuthorPublicationCardInfoSection' onClick={handleDetailView.bind(null, storyDetails.storyID, storyDetails.title)}>

                        <div className='AuthorPublicationCardInfoSectionStoryTitle'>
                            {storyDetails.title}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionAuthor'>
                            <span className='AuthorPublicationCardInfoSectionAuthorName'>
                                By {storyDetails.authorName} -
                            </span>
                            <span className='AuthorPublicationCardInfoSectionAuthorPostDate'>
                                {storyDetails.creationDate}
                            </span>
                        </div>

                        <div className='AuthorPublicationCardInfoSectionStoryBrief'>
                            {storyDetails.summary}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionData'>
                            <div className='AuthorPublicationCardInfoSectionDataViews'>
                                <div className='AuthorPublicationCardInfoSectionDataFirst'>
                                    {storyDetails.views}
                                </div>

                                <div className='AuthorPublicationCardInfoSectionDataSecond'>
                                    views
                                </div>
                            </div>

                            <div className='AuthorPublicationCardInfoSectionDataRating'>
                                <div className='AuthorPublicationCardInfoSectionDataFirst'>
                                    {storyDetails.rating}
                                </div>

                                <div className='AuthorPublicationCardInfoSectionDataSecond'>
                                    rating
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className='mobile'>
                <div className='AuthorPublicationCardMainSection'>

                    <div className='AuthorPublicationCardImgSection' onClick={handleDetailView.bind(null, storyDetails.storyID, storyDetails.title)}>
                        <img src={storyDetails.storyThumbnailImage} />
                    </div>

                    <div className='AuthorPublicationCardInfoSection' onClick={handleDetailView.bind(null, storyDetails.storyID, storyDetails.title)}>

                        <div className='AuthorPublicationCardInfoSectionStoryTitle'>
                            {storyDetails.title}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionAuthor'>
                            <span className='AuthorPublicationCardInfoSectionAuthorName'>
                                By {storyDetails.authorName} -
                            </span>
                            <span className='AuthorPublicationCardInfoSectionAuthorPostDate'>
                                {storyDetails.creationDate}
                            </span>
                        </div>

                        <div className='AuthorPublicationCardInfoSectionStoryBrief'>
                            {storyDetails.summary}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionData'>
                            <div className='AuthorPublicationCardInfoSectionDataViews'>
                                <div className='AuthorPublicationCardInfoSectionDataFirst'>
                                    {storyDetails.views}
                                </div>

                                <div className='AuthorPublicationCardInfoSectionDataSecond'>
                                    views
                                </div>
                            </div>

                            <div className='AuthorPublicationCardInfoSectionDataRating'>
                                <div className='AuthorPublicationCardInfoSectionDataFirst'>
                                    {storyDetails.rating}
                                </div>

                                <div className='AuthorPublicationCardInfoSectionDataSecond'>
                                    rating
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}

export default AuthorPublicationCard