/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Term Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'

function Termsforcontibutor() {
    return (
        <div>
            <div className='am-term-page'>
                <div className='am-term'>
                    Terms & Conditions for Contributors
                </div>
                <div className='am-term'>
                    Terms & Conditions for Freelance Contributors
                </div>
                <div className='am-term-heading'>

                </div>
                <p className='am-term-para'>The App &nbsp;<a
                            href="/"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >Wiki Bedtime Stories</a>&nbsp; (hereinafter referred to as “WBS”) is owned by Kushmanda Tech LLC (hereinafter referred as “We, us, Our”)  </p>
                <p className='am-term-para'>The use of this App by You are governed by below Terms for Contributors and the End-user Terms and Condition. If you are a contributor to WBS mean that You have read and agreed to all of the Terms so binding in You and that You are contracting with Us and have undertaken binding obligations with the Company.  </p>

                <div className='am-term-heading'>
                    1. General
                </div>
                <p className='am-term-para'>
                    These Terms and Conditions (“T&Cs”) apply to all the Contributors for the provision of services in connection with the Wiki Bedtime stories (WBS).
                </p>
                <ul>
                    <li className='am-term-para'>“Contents” include but not limited to stories, voice effects, comments, review, etc, related to the services provided by WBS.  </li>
                    <li className='am-term-para'>“Contributors” includes but limited to Authors, Compilers, Content creators, voice artists, reviewers, etc, whose works are published via WBS as contents. </li>
                    <li className='am-term-para'>“Application” means the software program provided by the Company downloaded by You on any electronic device, named Wiki Bedtime Stories.</li>
                    <li className='am-term-para'>“Application Store” means the digital distribution service operated and developed by Google Inc. (Google Play Store) in which the Application has been downloaded. </li>
                    <li className='am-term-para'>“Affiliate” means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.  </li>
                    <li className='am-term-para'>“Country” refers to India. </li>
                    <li className='am-term-para'>“Device” means any device that can access the Service such as a computer, cell phone or a digital tablet.  </li>
                    <li className='am-term-para'>“Feedback” means feedback, innovations or suggestions sent by You regarding the attributes, performance or features of our Service. </li>
                    <li className='am-term-para'>“Service” refers to the service of providing bedtime stories suitable for all ages to the parties accessing the App acting as a platform. </li>
                    <li className='am-term-para'>“Terms and Conditions” (also referred as "Terms") mean these Terms and Conditions that form the entire agreement between You and the Company regarding the use of the Service.  </li>
                    <li className='am-term-para'>“You” means the individual or company that act as a Contributor. </li>

                </ul>
                <p className='am-term-para'>We hold the sole right to modify the Terms without prior permission from You or informing You. The relationship creates on You a duty to periodically check the terms and stay updated on its requirements. If You continue to use the App such a change, this is deemed as consent by You to the so amended policies. As long as You comply with these Terms of Service, We grant You a personal, non-exclusive, non-transferable, limited privilege to enter and use the App. </p>
                <div className='am-term-heading'>
                    2. Standard of content
                </div>
                <ol type="A">
                    <li className='am-term-para'>The contents of the App are made available for end-user of various ages, the stories are to be children friendly.  </li>
                    <li className='am-term-para'>The Content presented must not be in way that it affects the people in any way including but not limited to race, gender, age, religion, sexual orientation, or any social group.  </li>
                    <li className='am-term-para'>We reserve the right to review the contents that are received from the Contributors. On review, the reviewer can make comments over the Content produced, which are to be relied by You. When all the opened points to discuss over a content is closed, the reviewer may approve the Content.
                    </li>
                    <li className='am-term-para'>The Contents can be of any category e.g. fantasy, Divinity, biography etc, but it must in standard appropriate for children. Inappropriate Contents will be completely removed and the presenter will be liable to pay the damages arising out of the Content they presented. </li>
                    <li className='am-term-para'>The approval or Disapproval of a story is Our discretion, We cannot be held liable for the disapproval of the stories. We are not bound to state the reason for any sort of approval or disapproval of a content.  </li>
                </ol>


                <div className='am-term-heading'>
                    3. Terms for Reviewers:
                </div>
                <ol type="A">
                    <li className='am-term-para'>The Reviewers of the Content are to maintain absolute confidentiality of the work proposed to be reviewed by them.    </li>
                    <li className='am-term-para'>The reviewer has the responsibility to review the Contents assigned to them and feed the comments over the areas of concerns. The Comments are to be made visible along with the proposed change to the Contributing individual of the Content.   </li>
                    <li className='am-term-para'>The reviewer can pass the content to the second level of review in case of disapproval.
                    </li>
                    <li className='am-term-para'>When a presented Content is disapproved, the reasons must accompany the rejection.   </li>
                    <li className='am-term-para'>When a Content is found up to the general Standards, it can be approved by the reviewer. </li>
                    <li className='am-term-para'>The Reviewer is liable to damages that arises out the changes that were proposed by the reviewer to the Content.  </li>
                </ol>

                <div className='am-term-heading'>
                    4. For Contributors of Documents:
                </div>
                <p className='am-term-para'>
                    If you contribute documents or reviews in any form or media (“Documents”), you (“Contributor”) give us the necessary rights to edit and post and maintain your Document (and the edited version), and to prevent unauthorized use by users of Documents that we edit. Users are given rights to read your Document, but no rights to republish or otherwise distribute your Document. Contributors agree and understand that once the document is shared with us or submitted to us, it means that they give us the full rights to use the same and also the document or details submitted by them mean that they have full rights to do the same, that is they own the rights for doing so while submitting and are not infringing the same. But document once submitted or shared with us means they are giving their full consent or approval for us to use it. If your Document has been previously published, that publisher may have rights, and if so, you must obtain all required consents before posting or submitting the Document on our App. If your Document contains images, we require that you have obtained consents from all persons depicted, seen, or heard, the owners of any places depicted, and any other rights holders. We shall not retain any rights in such Documents, or have any responsibility for the contents of such Documents. We will not be held liable for any wrong doing by the Users submitting the content.  </p>
                <div className='am-term-heading'>
                    5. You represent and warrant that:
                </div>
                <ol type="a">
                    <li className='am-term-para'>you have all the rights in the Content necessary for you to use the Services and to grant the rights in this Section, and,     </li>
                    <li className='am-term-para'>the storage, use or transmission of the Content will not violate these Terms of Use or applicable laws.    </li>
                    <li className='am-term-para'>You agree that you will promptly address and respond any notices or claims relating to the Content; and</li>
                    <li className='am-term-para'>maintain appropriate security, protection and backup copies of the Content.   </li>
                </ol>


                <div className='am-term-heading'>
                    6. Terms in case of unauthorised use:
                </div>
                <p className='am-term-para'>
                    You will immediately notify Us in writing of any unauthorized use of (a) any Content (b) your account, or (c) the Services and you agree to take reasonable steps to secure from such unauthorized use. Upon Our reasonable request, you will cooperate with and provide assistance to Us in connection with any such unauthorized use. We reserves the right to block or otherwise prevent delivery of any Content in Our sole discretion, if such Content violates these Terms.   </p>

                <div className='am-term-heading'>
                    7. Intellectual Property Rights
                </div>
                <p className='am-term-para'>
                    All rights related to the App design, text, graphics and other content, the selection and arrangements are licensed to the Company. The content of the App is protected by international copyright laws and other intellectual property rights. The company's name and logos mentioned in this App are the trademarks, service-marks or trading names of their respective owners, including us. You may not modify, copy, reproduce, republish, upload, post, transmit or distribute by any means or in any manner, any material or information on the App or downloaded from this App including but not limited to text, graphics, messages, code and/or software without Our prior written consent, except where expressly invited to do so.
                </p>
                <p className='am-term-para'>
                    You may not, republish any portion of the content on any Internet, Intranet or extranet site or incorporate the content in any database, compilation, archive or cache. You may not distribute any content to others, whether or not for payment or other consideration, and you may not modify copy, frame, cache, reproduce, sell, publish, transmit, display or otherwise use any portion of the content. You may not scrape or otherwise copy our content without permission. You agree not to decompile, reverse engineer or disassemble any software or other Services or processes accessible through the App, not to insert any code or manipulate the content of the App in any way that affects the user's experience, and not to use any data mining, data gathering or extraction method.
                </p>
                <p className='am-term-para'>
                    You may not use any of our intellectual property in any manner that is likely to cause confusion among Users, or in any manner that disparages or discredits us in any way
                </p>
                <p className='am-term-para'>If you believe your work has been copied in a way that constitutes infringement or are aware of any infringing material on the App, please email us at legal@kushmanda.tech.
                </p>
                <div className='am-term-heading'>
                    8. Term And Termination
                </div>
                <p className='am-term-para'>
                    We may immediately terminate the Service if you fail to abide by these Terms. In addition, We may, but has no duty to, immediately terminate the Service, it is Our sole discretion to concludes that you are engaged in fraudulent or illegal activities, sharing account with unauthorized users, or activities that may damage the rights of KushmandaTech or others. Termination of the Service may result in the deactivation or deletion of your account.
                </p>
                <p className='am-term-para'>
                    We may ban repeated offenders of copyright after due warning has been given to such a User. Users who use abusive language or are hate speech mongers and spammers will be banned by without giving any warning and with immediate effect from delivering Content on the App or using any further Services on the App.
                </p>
                <div className='am-term-heading'>
                    9. Indemnification and Limitation of Liability
                </div>
                <p className='am-term-para'>
                    You agree to indemnify, defend and hold harmless this App including but not limited to its affiliate vendors, agents and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by us that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms. Further, You agree to hold us harmless against any claims made by any third party due to, or arising out of, or in connection with, your use of the App, any claim that your material caused damage to a third party, your violation of the Terms, or your violation of any rights of another, including any intellectual property rights.
                </p>
                <p className='am-term-para'>
                    In no event shall Kushmanda Tech, its officers, directors, employees, partners or suppliers be liable to you, the vendor or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable or whether or not we have been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the App, services or materials.
                </p>
                <p className='am-term-para'>
                    The limitations and exclusions in this section apply to the maximum extent permitted by applicable law.
                </p>
                <div className='am-term-heading'>
                    10. Severability
                </div>
                <p className='am-term-para'>
                    If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under applicable law and the remaining provisions will continue in full force and effect.
                </p>

                <div className='am-term-heading'>
                    11. Waiver
                </div>
                <p className='am-term-para'>
                    The failure of either party at any time to require performance of any provision of this Agreement in no manner shall affect such party's right at a later time to enforce the same. No waiver by either party of any breach of this Agreement, whether by conduct or otherwise, in any one or more instances, shall be deemed to be, or construed as, a further or continuing waiver of any other such breach, or a waiver of any other breach of this Agreement.                 </p>
                <div className='am-term-heading'>
                    12. Changes to this Agreement
                </div>
                <p className='am-term-para'>
                    The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. What constitutes a material change will be determined at the sole discretion of the Company.
                </p>
                <p className='am-term-para'>
                    By continuing to access or use the Application after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Application.
                </p>
                <div className='am-term-heading'>
                    13. Governing Law and Jurisdiction
                </div>
                <p className='am-term-para'>
                    The laws of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the Application. Your use of the Application may also be subject to other local, state, national, or international laws. The parties agree that the venue for purposes of any and all lawsuits, causes of action, arbitrations or other disputes shall be in Chennai, Tamil Nadu, India.
                </p>

                <div className='am-term-heading'>
                    14. Entire Agreement
                </div>
                <p className='am-term-para'>
                    The Terms for Contributors and User Terms and Condition together constitutes the entire agreement between You and the Company regarding your use of the Application and supersedes all prior and contemporaneous written or oral agreements between You and the Company.
                </p>

                <div className='am-term-heading'>
                    15. Contact Us
                </div>
                <p className='am-term-para'>
                    If you have any questions about this Agreement, You can contact Us:
                </p>
                <p className='am-term-para'>By visiting this page on our website: <a
                            href="https://kushmanda.tech/"
                            style={{ color: 'blue', textDecoration: 'underline' }}
                        >https://kushmanda.tech</a>
                </p>
                <p className='am-term-para'>By sending us an email: legal@kushmanda.tech
                </p>



            </div>
        </div>
    )
}

export default Termsforcontibutor
