import React, { useState } from 'react';
import '../../CS/AgeCategory.css';

function AgeCategory({ selectedAge,
    setSelectedAge }) {


    const handleSelect = (age) => {
        setSelectedAge(age);
    };
    const handlereload = () => {
        window.location.reload(); // Reload the screen
    }
    return (
        <div className="age-category">
            {/* <div className='web'><div className="age-group" onClick={() => handlereload()}>All Ages</div></div> */}
            <div
                className={`age-option ${selectedAge === 0 ? 'selected' : ''}`}
                onClick={() => handleSelect(0)}
            >
                All Ages
            </div>
            <div
                className={`age-option ${selectedAge === 1 ? 'selected' : ''}`}
                onClick={() => handleSelect(1)}
            >
                0-3 years
            </div>
            <div
                className={`age-option ${selectedAge === 2 ? 'selected' : ''}`}
                onClick={() => handleSelect(2)}
            >
                3-6 years
            </div>
            <div
                className={`age-option ${selectedAge === 3 ? 'selected' : ''}`}
                onClick={() => handleSelect(3)}
            >
                6-12 years
            </div>
            <div
                className={`age-option ${selectedAge === 4 ? 'selected' : ''}`}
                onClick={() => handleSelect(4)}
            >
                12+ years
            </div>
        </div>
    );
}

export default AgeCategory;
