/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Story Detailed Viewed 
Brief :
StoryDetailView, retrieves detailed story data based on the provided story ID from an API using fetchStoryData.
It utilizes React Router to get the story ID from the URL parameters. Once the data is fetched, it passes it to the DetailedView component for rendering the detailed story view.
This component also includes Header and Footer components to encapsulate the entire view of the story.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import { fetchStoryData } from '../../../../../Controller/VS-VideoStoeies/StoryViewController'; // Import the API function
import '../../..//WS-WrittenStories/src/CS/StoryDetailView.css'
import DetailedView from './DetailedView';
import fetchData from '../../../../../Controller/VS-VideoStoeies/StoryListController';
import { Helmet } from 'react-helmet';

function VideoDetailView() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [loading, setLoading] = useState(false);
    const [selectedAge, setSelectedAge] = useState(0);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const { story_id } = useParams();
    const [data, setData] = useState({});
    useEffect(() => {
        async function fetchData() {
            try {
                // Set loading to true before fetching data
                setLoading(true);

                const result = await fetchStoryData(story_id, userId);
                setData(result);

                // Set loading to false after data is fetched
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                // Make sure to set loading to false in case of an error
                setLoading(false);
            }
        }

        fetchData();
    }, [story_id, userId]); // Include userId in the dependency array if it's used inside fetchStoryData

    // ListView
    const [stories, setStories] = useState([]);
    const [firstAndLastStoriesNumber, setFirstAndLastStoriesNumber] = useState(0);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [sortby, setSortBy] = useState('last_updated_date');
    const [apiCallCompleted, setApiCallCompleted] = useState(false);
    const [page, setPage] = useState(1);
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const pageNumber = parseInt(searchParams.get('page'), 10);
        setPage(pageNumber || 1); // If pageNumber is NaN or undefined, default to 1
    }, [location.search]);
    useEffect(() => {
        console.log('Page:', page, 'Sortby:', sortby, 'All Stories Loaded:', allStoriesLoaded);
        setLoading(true);
        console.log('UserId:', userId);
        fetchData(page, sortby, userId,selectedAge, allStoriesLoaded, setSortBy, setStories, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading, setAllStoriesLoaded)
            .then(() => {
                console.log('API Call Completed');
                setApiCallCompleted(true);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setApiCallCompleted(true);
                setLoading(false);
            });
            console.log('API Call Completed');

    }, [page, sortby, allStoriesLoaded,userId,selectedAge,]);
    const PAGE_LIMIT = 10; // Your page limit constant
    const [totalPages, setTotalPages] = useState(1); // State to store total pages

    useEffect(() => {
        const calculateTotalPages = () => {
            if (!allStoriesLoaded && totalStories) {
                const totalPagesCount = Math.ceil(totalStories / PAGE_LIMIT);
                setTotalPages(totalPagesCount);
            }
        };

        calculateTotalPages();
    }, [allStoriesLoaded, totalStories]);
    return (
        <div>
            <Header authToken={(location.state) ? location.state.authToken : null} />
            <Helmet>
                <title>Wiki Bedtime Stories: Short bedtime stories for kids</title>
                <meta name="description"
                    content="Write, read, and listen to free short bedtime stories for kids. Enjoy fairy tales, sleeping beauty, Ramayana, Greek mythology, Panchatantra, and more bedtime stories for ages 5 to 12." />


            </Helmet>
            <DetailedView data={data} loading={loading} stories={stories}
                setStories={setStories}
                totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                firstAndLastStoriesNumber={firstAndLastStoriesNumber}
            />

            <Footer />
        </div>
    )
}

export default VideoDetailView
