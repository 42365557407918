/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : This page is Category List View 
Brief :Fetch and display a list of stories associated with specific categories.
Manage pagination for the displayed stories.
Handle user interactions like clicking on stories, sharing, and displaying categories and subcategories.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams, useLocation } from 'react-router-dom';
import DownArrow from '../../../WS-WrittenStories/Assets/Images/Arrow - Down.webp'
import StaticCat from '../../../WS-WrittenStories/Assets/Images/Category image.webp'
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import RightArrow from '../../../WS-WrittenStories/Assets/Images/ArrowRight.webp'
import LeftArrow from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import ArrowDown from '../../../WS-WrittenStories/Assets/Images/Arrow - Down 2.webp'
import Loading from '../../../WS-WrittenStories/Assets/Images/Loading.WebM'
import LoadingImage from '../../../WS-WrittenStories/Assets/Images/LoadingImage.webp'
import ArrowUp from '../../../WS-WrittenStories/Assets/Images/Arrow - Up 2.WebP'
import SortByCat from '../../../WS-WrittenStories/Assets/Images/SortByCat.webp'
import ArrowDown1 from '../../../WS-WrittenStories/Assets/Images/Arrow - Down.webp'
import ArrowUp1 from '../../../WS-WrittenStories/Assets/Images/Arrow - Up.webp'
import NonSelect from '../../../WS-WrittenStories/Assets/Images/NonSelect.webp'
import Select from '../../../WS-WrittenStories/Assets/Images/Select.webp'
import ArrowLeft from '../../../WS-WrittenStories/Assets/Images/ArrowLeftPagination.webp'
import ArrowRight from '../../../WS-WrittenStories/Assets/Images/ArrowRightPagination.webp'
import '../../CS/Category.css'
import axios from 'axios';
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, update_story_views } from '../../../../../Model/WS-WrittenStories/WrittenStories';
import AgeCategory from '../../../WS-WrittenStories/src/JS/StoriesList/AgeCategory';

function CategoryListView({ data, allStoriesLoaded, categoryData,
    loading, setSortBy,
    setPage, page, setData, setCategoryData, setAllStoriesLoaded, totalStories,
    totalStoriesObject,
    firstAndLastStoriesNumber,
    apiCallInProgress,
    selectedAge,
    setSelectedAge
}) {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const { CategoryName, subCategoryName } = useParams();
    const navigate = useNavigate();
    const goToNextPage = () => {
        if (!allStoriesLoaded) {
            setPage(page + 1);
            setCategoryData([]);
        }
    };

    const goToPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setCategoryData([]);
        }
    };

    const goToFirstPage = () => {
        if (page > 1) {
            setPage(1);
            setCategoryData([]);
            window.scrollTo(0, 0);
        }
    };

    const goToLastPage = () => {
        if (page < totalPages) {
            setPage(totalPages);
            setCategoryData([]);
            window.scrollTo(0, 0);
        }
    };
    const PAGE_LIMIT = 10;
    const totalPages = Math.ceil(totalStoriesObject / PAGE_LIMIT);

    const renderPageNumbers = () => {
        const PAGE_LIMIT = 10;
        const maxPages = 8; // Set your maximum number of pages

        const totalPages = Math.ceil(totalStoriesObject / PAGE_LIMIT);

        const pageNumbers = [];
        for (let i = 1; i <= Math.min(totalPages, maxPages); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-number ${i === page ? 'active' : ''}`}
                    onClick={() => {
                        if (i === page) return; // Prevent API call and navigation for the active page
                        setPage(i);
                        setCategoryData([]); // Clear previous data
                    }}
                >
                    {i}
                </button >
            );
        }
        return pageNumbers;
    };
    // sortby start
    const [arrowImage1, setArrowImage1] = useState(ArrowDown1);
    const [isSortByListVisible, setSortByListVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort By');
    const [containerClassName, setContainerClassName] = useState('ws-stories-catsortby-text');

    const toggleSortByList = () => {
        setIsOpen(false);
        setSortByListVisible(!isSortByListVisible);
        setContainerClassName(isSortByListVisible ? 'selected-sortby' : 'other-sortby-name');
        setArrowImage1(isSortByListVisible ? ArrowDown1 : ArrowUp1);

    };
    const [divIdToRender, setDivIdToRender] = useState('divTwo'); // Initialize with an empty string or your default value

    const handleOptionClick = (option) => {
        // Prevent selecting the same option again
        if (option === selectedOption) {
            return; // If the option is already selected, do nothing
        }
        setSelectedOption(option);
        setSortByListVisible(false);
        setContainerClassName('selected-sortby');
        setArrowImage1(ArrowDown1)
        setCategoryData([]);
        setPage(1);
        // Determine the sorting parameter based on the selected option
        let divIdToRender;
        let sortingParameter;
        switch (option) {
            case 'Recommended':
                sortingParameter = 'recommended';

                setDivIdToRender('divOne');
                break;
            case 'Praiseworthy':
                sortingParameter = 'rating';
                setDivIdToRender('divTwo');
                break;
            case 'Recently Released':
                sortingParameter = 'last_approved_date';
                setDivIdToRender('divTwo');
                break;
            case 'Popularly Viewed':
                sortingParameter = 'views';
                setDivIdToRender('divTwo');
                break;
            default:
                sortingParameter = 'default_parameter';
                setDivIdToRender('divTwo');
        }
        setSortBy(sortingParameter);
        setTimeout(() => {

        }, 1000); // Adjust the timeout value if needed
    };
    const getImgSrc = (option) => {
        return selectedOption === option ? Select : NonSelect;
    };
    const categoryBoxRef = useRef(null);
    const sortByBoxRef = useRef(null);
    const handleClickOutside = (event) => {
        if (categoryBoxRef.current && !categoryBoxRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCategoryListVisible(false);
            setArrowImage(ArrowDown);
            setArrowImageCat(DownArrow);
        }
        if (sortByBoxRef.current && !sortByBoxRef.current.contains(event.target)) {
            setSortByListVisible(false);
            setContainerClassName('selected-sortby');
            setArrowImage1(ArrowDown1);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // SortBy end
    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(categoryData.length).fill(false));

    // Function to handle bookmark click for a specific story
    const handleShowBookmark = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedBookmarkedStories = [...bookmarkedStories];

            // Toggle the bookmarked state for the specific story
            updatedBookmarkedStories[index] = !updatedBookmarkedStories[index];

            // Update the bookmarked state for all stories
            setBookmarkedStories(updatedBookmarkedStories);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const [heartedStories, setHeartedStories] = useState(new Array(categoryData.length).fill(false));

    // Function to handle heart click for a specific story
    const handleShowHeart = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the heartedStories array
            const updatedHeartedStories = [...heartedStories];

            // Toggle the hearted state for the specific story
            updatedHeartedStories[index] = !updatedHeartedStories[index];

            // Update the hearted state for all stories
            setHeartedStories(updatedHeartedStories);

            // Perform other actions like updating the story if needed
        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };


    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                navigateToStory();
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    const navigateToStory = (storyID, title) => {
        // Replace spaces with hyphens in the title for the URL
        const formattedTitle = title.replace(/\s+/g, '-');

        // Navigate to the detailed view with the formatted title and pass the storyID and story data via state
        navigate(`/bedtime-short-stories-for-kids/${title}`, {
            state: { story_id: storyID },
        });

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };


    const [isOpen, setIsOpen] = useState(false);
    const [arrowImage, setArrowImage] = useState(ArrowDown);
    const [arrowImageCat, setArrowImageCat] = useState(DownArrow);
    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);
    const toggleCategoryList = () => {
        setSortByListVisible(false);
        setSubcategories([]);
        setIsOpen(!isOpen);
        setIsCategoryListVisible(!isCategoryListVisible);
        setArrowImage(isOpen ? ArrowDown : ArrowUp);
        setArrowImageCat(isOpen ? DownArrow : ArrowUp1);
    };

    const [subcategories, setSubcategories] = useState([]);

    const mainCategories = [
        { id: 3, name: 'Epic' },
        { id: 2, name: 'Folktales' },
        { id: 1, name: 'Mythology' },
        { id: 99, name: 'Region' },
        // Add more main categories as needed
    ];
    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/sub_categories?pageNumber=1&sortMode=ASC&sortBy=id&pageNumber=1&pageSize=20&primaryCategoryId=${categoryId}`);
            const data = await response.json();
            if (data && data.data) {
                setSubcategories(data.data);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        fetchSubcategories(categoryId);
        setIsOpen(true);
    };
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [error, setError] = useState(null);



    // Render conditionally based on loading and error states
    if (loading) {
        return <div className='loading'><img src={Loading} alt='Loading' /></div>
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    const handleStoryClick = (storyID) => {
        updateStory(storyID);
    }
    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };


    return (
        <div>
            <div>
                <div className='ws-stories'>
                    <div className='ws-stories-basket'>
                        <div>
                            <div className='ws-stories-btn'>
                                <div className='ws-stories-corner-left' ref={categoryBoxRef}

                                >
                                    <div className='ws-stories-category-cat'
                                        onClick={toggleCategoryList}
                                    >
                                        <img src={StaticCat} alt='category' />
                                    </div>
                                    <div
                                        className={isCategoryListVisible ? 'other-class-name' : 'ws-stories-category-text'}
                                        onClick={toggleCategoryList}>
                                        Categories
                                        <img src={arrowImage} alt='ArrowDown' className="show-above-950px" />
                                        <img src={arrowImageCat} alt='down' className="show-below-950px" />
                                    </div>
                                    {isOpen && (
                                        <div className='category-list' >
                                            {/* Display static main categories */}
                                            {mainCategories.map((category) => (
                                                <div className='category-item' key={category.id} onClick={() => handleCategoryClick(category.id)}
                                                    onMouseEnter={() => handleCategoryClick(category.id)}
                                                >
                                                    {category.name}
                                                </div>
                                            ))}
                                            {/* Conditionally display fetched subcategories */}
                                            {selectedCategory !== null && subcategories.length > 0 && (
                                                <div className='subcategory-list'>
                                                    {subcategories.map((subcategory) => (
                                                        <div className='subcategory-item' key={subcategory.subCategoryID}
                                                            onClick={() => {
                                                                navigate(`/category/${selectedCategory}/subcategory/${subcategory.subCategoryID}/${subcategory.primaryCategoryName}/${subcategory.subCategoryName}`, {
                                                                    state: { authToken: authToken }
                                                                });
                                                            }}
                                                        >
                                                            {subcategory.subCategoryName}
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            {/* Show nothing (empty div) when no subcategories available */}
                                            {selectedCategory !== null && subcategories.length === 0 && <div></div>}
                                        </div>
                                    )}
                                </div>

                                <div className='ws-stories-corner-right' ref={sortByBoxRef}
                                >
                                    <div className='ws-stories-catsortby' onClick={toggleSortByList}>
                                        <img src={SortByCat} alt='sortby' />
                                    </div>
                                    <div
                                        className={containerClassName}
                                        onClick={toggleSortByList}>
                                        {/* Sort By */}
                                        {selectedOption}
                                        <div className='sortbyarrow'>
                                            <img src={arrowImage1} alt='ArrowDown' />
                                        </div>

                                    </div>
                                    {isSortByListVisible && (
                                        <div className='dropdown-options' >

                                            <div onClick={() => handleOptionClick('Recently Released')}>
                                                <img src={getImgSrc('Recently Released')} alt='select' />

                                                Recently released</div>
                                            <div onClick={() => handleOptionClick('Praiseworthy')}>
                                                <img src={getImgSrc('Praiseworthy')} alt='select' />
                                                Praiseworthy</div>
                                            <div onClick={() => handleOptionClick('Popularly Viewed')}>
                                                <img src={getImgSrc('Popularly Viewed')} alt='select' />
                                                Popularly viewed</div>
                                        </div>
                                    )}
                                </div>

                            </div>
                            <AgeCategory selectedAge={selectedAge}
                                setSelectedAge={setSelectedAge} />
                        </div>
                        <div>
                            <div className='ws-stories-box-align-bottom'>
                                <div className='ws-stories-category-navigation'>
                                    Home&nbsp;&gt;&nbsp;
                                    {CategoryName}
                                    &nbsp;&gt;&nbsp;
                                    <span className='sm-stories-subcategory'>{subCategoryName}</span>
                                </div>

                                {loading ? (
                                    <div className='loading'>
                                        <div className='load'>Loading story</div>
                                        <div className='load_image'><img src={LoadingImage} alt='d' /></div>
                                    </div>
                                ) : (
                                    categoryData.map((story, i) => {
                                        return (
                                            <div key={i}>

                                                <div className='web'>

                                                    <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(story.storyID)}>
                                                        <div className='ws-stories-img' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                        </div>
                                                        <div className='ws-stories-text'>
                                                            <div className='ws-stories-title-img' >
                                                                <div className='ws-stories-box-title' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb' >

                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }

                                                                    </div>

                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date'
                                                                onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-author'>By {story.authorName}-</div>
                                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                            </div>
                                                            <div
                                                                className="ws-stories-box-wrapper"
                                                                onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                style={{ padding: '45px 0', cursor: 'pointer' }}
                                                            >
                                                                <div className="ws-stories-box-summary">
                                                                    {story.summary}
                                                                </div>
                                                            </div>
                                                            <div className='ws-story-box-viewrate'
                                                                onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className='mobile'>
                                                    <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID)}>
                                                        <div className='ws-stories-img'
                                                            onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                        >
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                        </div>
                                                        <div className='ws-stories-text' >
                                                            <div className='ws-stories-title-img'>
                                                                <div className='ws-stories-box-title'
                                                                    onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                                >
                                                                    {story.title}
                                                                </div>
                                                                <div className='ws-stories-img-hsb'>
                                                                    <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                        {
                                                                            story.favouriteStory ? (
                                                                                heartedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />

                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                heartedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                            src={HeartFilled}
                                                                                            alt='heart'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => favouriteStory(story.storyID, i)}
                                                                                            src={Heart}
                                                                                            alt='heart'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }

                                                                    </div>

                                                                    <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                        {
                                                                            story.is_saved ? (
                                                                                bookmarkedStories[i] ? (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />



                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )
                                                                            ) : (
                                                                                bookmarkedStories[i] ?
                                                                                    (
                                                                                        <img
                                                                                            onClick={() => unsavedStory(story.storyID, i)}
                                                                                            src={BookmarkFilled}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    ) : (
                                                                                        <img
                                                                                            onClick={() => savedStory(story.storyID, i)}
                                                                                            src={Bookmark}
                                                                                            alt='bookmark'
                                                                                        />
                                                                                    )

                                                                            )
                                                                        }

                                                                    </div>
                                                                </div>
                                                                {showPopup && (
                                                                    <div className="pop-up-overlay">
                                                                        <div className="pop-up-box">
                                                                            <p>Login first</p>
                                                                            <button onClick={handleClosePopup}>Close</button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <div className='ws-stories-box-auth-date'
                                                                onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                <div className='ws-stories-box-author'>By {story.authorName} - </div>
                                                                <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                            </div>
                                                            <div className='ws-stories-box-summary'
                                                                onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >
                                                                {story.summary}
                                                            </div>
                                                            <div className='ws-stories-box-view-rate' onClick={navigateToStory.bind(null, story.storyID, story.title)}
                                                            >

                                                                <div className='ws-stories-box-viewbox'>
                                                                    <div className='ws-story-view'>{story.views}</div>
                                                                    <div className='ws-story-viewtext'>views</div>
                                                                </div>
                                                                <div className='ws-stories-box-ratebox'>
                                                                    <div className='ws-story-rate'>{Number(story.rating).toFixed(2)}</div>
                                                                    <div className='ws-story-ratetext'>rating</div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })
                                )

                                }
                            </div>
                        </div>
                    </div>
                    <div className='ws-stories-ads'>


                    </div>
                </div>

                <div className='ws-stories-pagination'>

                </div>

                <div className="pagination">
                    <div className="page-count">
                        {page} of {totalPages}
                    </div>
                    <div className="pagination-controls">
                        <div className="pagination-center">
                            <div className="page-numbers">
                                <div className='web'>
                                    <button className="first-page-button" onClick={goToFirstPage}>
                                        Go to First
                                    </button>
                                </div>
                                <button className="prev-page-button" onClick={goToPrevPage}>
                                    <img src={ArrowLeft} />
                                </button>
                                {renderPageNumbers()}
                                {!allStoriesLoaded && page < totalPages && (
                                    <>
                                        <><button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}><img src={ArrowRight} /></button></>
                                        {/* <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>

                                        </button> */}
                                    </>


                                )}
                                <div className='web'>
                                    <button className="last-page-button" onClick={goToLastPage}>
                                        Go to Last
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ws-stories-pagination-box">

                </div>

            </div>
        </div>
    );
}

export default CategoryListView;
