/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Audio Page
Brief :
Handling audio playback:

Managing the playing, pausing, and selection of audio stories.
Utilizing HTML5 audio capabilities to control audio playback and update its time.

Fetching audio story data from an API (fetchStoryData function) and displaying story metadata like title, author, and narrator.
Handling categories and subcategories:

Fetching and displaying subcategories based on the selected main category.
Toggling category lists and handling category clicks.
Managing pop-up and signup features:

Displaying and handling pop-ups and signup modals.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import DownArrow from '../../../WS-WrittenStories/Assets/Images/Arrow - Down.webp'
import SortBy from '../../../WS-WrittenStories/Assets/Images/SortBy.webp'
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import LoadingImage from '../../../WS-WrittenStories/Assets/Images/LoadingImage.webp'
import PlayButton from '../../Assets/Images/Play button.png'
import PauseButton from '../../Assets/Images/Pause button.png'
import PlayButtonbtn from '../../Assets/Images/Play buttonbtn.png'
import PauseButtonbtn from '../../Assets/Images/Pause buttonbtn.png'
import PlayButtonbtnList from '../../Assets/Images/headphone-circle.webp'
import PauseButtonbtnList from '../../Assets/Images/headphone-sound.webp'
import TimeRewind from '../../Assets/Images/Time rewind.webp'
import TimeForward from '../../Assets/Images/Time forward.webp'
import PauseButtonEx from '../../Assets/Images/Pause buttonEx.webp'
import PlayButtonEx from '../../Assets/Images/Play buttonEX.webp'
import TimeRewindEX from '../../Assets/Images/Time rewindEX.webp'
import TimeForwardEX from '../../Assets/Images/Time forwardEX.webp'
import Writter from '../../Assets/Images/Art.png'
import Narrator from '../../Assets/Images/Audio.png'
import StaticCat from '../../../WS-WrittenStories/Assets/Images/Category image.webp'
import ArrowDown from '../../../WS-WrittenStories/Assets/Images/Arrow - Down 2.webp'
import ArrowUp from '../../../WS-WrittenStories/Assets/Images/Arrow - Up 2.WebP'
import RightArrow from '../../../WS-WrittenStories/Assets/Images/ArrowRight.webp'
import LeftArrow from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import CatNumb from '../../../WS-WrittenStories/Assets/Images/CatNumb.webp'
import Cross from '../../Assets/Images/cross_icon.webp'
import Expand from '../../Assets/Images/expand.webp'
import ArrowLeft from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import { fetchStoryData } from '../../../../../Controller/AS-AudioStories/AudioListController';
import AdsDetailView from '../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView';
import StarFill from '../../../WS-WrittenStories/Assets/Images/StarFill.webp'
import Star from '../../../WS-WrittenStories/Assets/Images/Star.webp'
import axios from 'axios';
import ArrowDownDetail from '../../Assets/Images/Arrow - Down.webp'
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, audio_rating, update_story_rating, update_story_views } from '../../../../../Model/WS-WrittenStories/WrittenStories';
// sortby
import SortByCat from '../../../WS-WrittenStories/Assets/Images/SortByCat.webp'
import ArrowDown1 from '../../../WS-WrittenStories/Assets/Images/Arrow - Down.webp'
import ArrowUp1 from '../../../WS-WrittenStories/Assets/Images/Arrow - Up.webp'
import NonSelect from '../../../WS-WrittenStories/Assets/Images/NonSelect.webp'
import Select from '../../../WS-WrittenStories/Assets/Images/Select.webp'
import LoadingGif from '../../../WS-WrittenStories/Assets/Images/Loading.gif'
import NewTag from '../../../WS-WrittenStories/Assets/Images/NewTag.webp'
import { audiolisten } from '../../../../../Model/AS-AudioStories/AudioStories';
import Navigation from '../../../WS-WrittenStories/src/JS/Header/Navigation';
import ArrowLeftPagination from '../../../WS-WrittenStories/Assets/Images/ArrowLeftPagination.webp'
import ArrowRightPagination from '../../../WS-WrittenStories/Assets/Images/ArrowRightPagination.webp'
import AgeCategory from '../../../WS-WrittenStories/src/JS/StoriesList/AgeCategory';


function AudioStoriesList({ data, loading, setPage, page,
    allStoriesLoaded, totalStories,
    totalStoriesObject, setSortBy, setData, selectedAge,
    setSelectedAge, sequence,
    //expand
    expandedClassName, audioList, audioPagination, onExpandClick, audioPlayerBox, audioPlayerBoxExpand, onExpandClose, seekingInputMob
}) {

    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);


    const [totalPages, setTotalPages] = useState(1); // State to store total pages
    const PAGE_LIMIT = 10; // number of items to display per page
    useEffect(() => {
        const calculateTotalPages = () => {
            if (totalStories && !allStoriesLoaded) {
                const totalPagesCount = Math.ceil(totalStories / PAGE_LIMIT);
                setTotalPages(totalPagesCount);
            }
        };

        calculateTotalPages();
    }, [allStoriesLoaded, totalStories]);
    const CurrentDate = new Date(new Date().toUTCString());
    const [playaudio, setPlayaudio] = useState([]);
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [audio, setAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [maxTimeFromAudio, setMaxTimeFromAudio] = useState(0);
    const [isInputVisible, setIsInputVisible] = useState(false);
    const [storyDetails, setStoryDetails] = useState({
        storyImage: '',
        authorName: '',
        narratorName: '',
        storyTitle: ''
    });
    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [allowClick, setAllowClick] = useState(true);

    const toggleStar = (storyID, sequence, index) => {
        const clickedStory = data[index];

        console.log(clickedStory)
        if (!clickedStory) {
            console.error('Invalid index or story data');
            return;
        }

        const story_ID = String(storyID);
        let lastValueOfI; // Declare i outside the loop
        if (allowClick) {
            const updatedFilledStars = [...filledStars];
            for (let i = 0; i <= index; i++) {

                updatedFilledStars[i] = true;
                lastValueOfI = i + 1; // Store the value of i in lastValueOfI
            }
            const requestBody = {
                story_id: story_ID,
                sequence: String(sequence),
                rating: lastValueOfI.toString()
            };
            const requestHeaders = {
                Authorization: `Bearer ${authToken}`,
            };
            axios
                .patch(audio_rating, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Handle the API response here
                })
                .catch((error) => {
                    console.error(error); // Handle errors here
                });
            setFilledStars(updatedFilledStars);
            setAllowClick(false); // Prevent further clicks
        }
    };
    const [showSignup, setShowSignup] = useState(false);

    const handleShowSignup = () => {
        setShowSignup(true);
    };

    const handleCloseSignup = () => {
        navigate('/loginpop');
    };
    // Initialize bookmarked state for all stories
    const [bookmarkedStories, setBookmarkedStories] = useState(new Array(data.length).fill(false));

    // Function to handle bookmark click for a specific story
    const handleShowBookmark = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedBookmarkedStories = [...bookmarkedStories];

            // Toggle the bookmarked state for the specific story
            updatedBookmarkedStories[index] = !updatedBookmarkedStories[index];

            // Update the bookmarked state for all stories
            setBookmarkedStories(updatedBookmarkedStories);

            // Perform other actions for bookmarking like updating the story if needed
            // updateStoryBookmark(storyID);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const [heartedStories, setHeartedStories] = useState(new Array(data.length).fill(false));

    // Function to handle heart click for a specific story
    const handleShowHeart = (storyID, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the heartedStories array
            const updatedHeartedStories = [...heartedStories];

            // Toggle the hearted state for the specific story
            updatedHeartedStories[index] = !updatedHeartedStories[index];

            // Update the hearted state for all stories
            setHeartedStories(updatedHeartedStories);

            // Perform other actions like updating the story if needed
        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        }
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };


    useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);

        return () => {
            if (newAudio) {
                newAudio.pause();
                newAudio.removeEventListener('timeupdate', handleTimeUpdate);
                newAudio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
            setAudio(null);
        };
    }, []);

    const handleLoadedMetadata = () => {
        setMaxTimeFromAudio(audio);
        setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audio.currentTime);
    };

    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', handleTimeUpdate);
            audio.addEventListener('loadedmetadata', handleLoadedMetadata);
        }

        return () => {
            if (audio) {
                audio.removeEventListener('timeupdate', handleTimeUpdate);
                audio.removeEventListener('loadedmetadata', handleLoadedMetadata);
            }
        };
    }, [audio]);

    const navigate = useNavigate();
    const handlePlayPause = (index) => {
        const clickedStory = data[index]; // Get the clicked story from the data array

        if (!clickedStory) {
            console.error('Invalid index or story data');
            return;
        }
        const { audioLocation, storyThumbnailImage, authorName, narratorName, storyTitle, storyRatings, storyViews, storyID, favouriteStory, is_saved, sequence, ListeningCount } = clickedStory;

        if (index === currentPlayingIndex) {
            // Clicked on the currently playing story, so pause it
            audio.pause();
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
            setIsInputVisible(true);
            // Add logic to pause the audio for the current story
            // Set the story details when pausing
            setStoryDetails({
                storyImage: '',
                authorName: '',
                narratorName: '',
                storyTitle: '',
                storyRatings: '',
                storyViews: '',
                storyID: '',
                favouriteStory: '',
                is_saved: '',
                sequence: '',
                ListeningCount: ''
            });
        } else {
            // Clicked on a new story, stop the previously playing story
            if (currentPlayingIndex !== null) {
                audio.pause();
                console.log('Paused previous audio');
                setIsInputVisible(false);
                // Pause the previous story if there was one
                // Add logic to pause the audio for the previously playing story
            }

            // Start playing the new story

            audio.src = audioLocation; // Set the new audio source
            audio.play().catch(error => {
                console.error('Play error:', error);
            });

            setIsPlaying(true);
            setCurrentPlayingIndex(index);
            setIsInputVisible(true);
            // Add logic to play the audio for the new story
            // Set the story details when playing
            setStoryDetails({
                storyImage: storyThumbnailImage,
                authorName,
                narratorName,
                storyTitle,
                storyRatings,
                storyViews,
                storyID,
                favouriteStory,
                is_saved,
                sequence,
                ListeningCount
            });
        }
    };
    const handleCross = () => {
        // Pause the current audio if playing
        if (currentPlayingIndex !== null) {
            audio.pause(); // Stop the audio
            setIsPlaying(false); // Update the play/pause state to show play symbol
            setCurrentPlayingIndex(null); // Reset the current playing index
        }

        // Reset the story details to hide the player and associated details
        setStoryDetails({
            storyImage: '',
            authorName: '',
            narratorName: '',
            storyTitle: '',
            storyRatings: '',
            storyViews: '',
            storyID: '',
            favouriteStory: '',
            is_saved: '',
            sequence: '',
            ListeningCount: ''
        });

        // Hide the input section or other UI elements as necessary
        setIsInputVisible(false);
    };

    useEffect(() => {
        const newAudio = new Audio();
        setAudio(newAudio);
        return () => {
            newAudio.pause();
            setAudio(null);
        };
    }, []);
    const [currentTime, setCurrentTime] = useState('00:00');
    const [duration, setDuration] = useState(0);
    const handleSeek = (e) => {
        const { value } = e.target;
        audio.currentTime = parseFloat(value); // Convert value to a float
        setCurrentTime(parseFloat(value)); // Update the state with the float value
    };
    const handleTimeRewind = () => {
        if (!audio) return;
        audio.currentTime -= 10; // Rewind 10 seconds
    };

    const handleTimeForward = () => {
        if (!audio) return;
        audio.currentTime += 10; // Forward 10 seconds
    };
    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', () => {
                setCurrentTime(audio.currentTime);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            audio.addEventListener('loadedmetadata', () => {
                setDuration(audio.duration);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            audio.addEventListener('timeupdate', () => {
                setCurrentTime(audio.currentTime);
            });
        }
    }, [audio]);
    const handleBoxPlayPause = () => {
        if (!audio) return;

        if (isPlaying) {
            audio.pause();
        } else {
            audio.play().catch(error => {
                console.error('Play error:', error);
            });
        }
        setIsPlaying(!isPlaying);
    };
    const goToNextPage = () => {
        if (!allStoriesLoaded) {
            setPage(page + 1);
            setData([]);
        }
    };

    const goToFirstPage = () => {
        if (page > 1) {
            setPage(1);
            setData([]);
            window.scrollTo(0, 0);
        }
    };

    const goToLastPage = () => {
        if (page < totalPages) {
            setPage(totalPages);
            setData([]);
            window.scrollTo(0, 0);

        }
    };

    const goToPrevPage = () => {
        if (page > 1) {
            setPage(page - 1);
            setData([]);
            window.scrollTo(0, 0);

        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        const maxPages = 8; // Set your maximum number of pages
        for (let i = 1; i <= Math.min(totalPages, maxPages); i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`page-number ${i === page ? 'active' : ''}`}
                    onClick={() => {
                        if (i === page) return; // Prevent API call and navigation for the active page
                        setPage(i);
                        setData([]);
                        window.scrollTo(0, 0); // Clear previous data

                    }}
                >
                    {i} {/* Display the page number directly */}
                </button>
            );
        }
        return pageNumbers;
    };
    // sortby start
    const [arrowImage1, setArrowImage1] = useState(ArrowDown1);
    const [isSortByListVisible, setSortByListVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Sort By');
    const [containerClassName, setContainerClassName] = useState('ws-stories-catsortby-text');

    const toggleSortByList = () => {
        setIsOpen(false);
        setSortByListVisible(!isSortByListVisible);
        setContainerClassName(isSortByListVisible ? 'selected-sortby' : 'other-sortby-name');
        setArrowImage1(isSortByListVisible ? ArrowDown1 : ArrowUp1);

    };
    const [divIdToRender, setDivIdToRender] = useState('divTwo'); // Initialize with an empty string or your default value

    const handleOptionClick = (option) => {
        // Prevent selecting the same option again
        if (option === selectedOption) {
            return; // If the option is already selected, do nothing
        }
        setSelectedOption(option);
        setSortByListVisible(false);
        setContainerClassName('selected-sortby');
        setArrowImage1(ArrowDown1)
        setData([]);
        setPage(1);
        // Determine the sorting parameter based on the selected option
        let divIdToRender;
        let sortingParameter;
        switch (option) {
            case 'Recommended':
                sortingParameter = 'recommended';

                setDivIdToRender('divOne');
                break;
            case 'Praiseworthy':
                sortingParameter = 'rating';
                setDivIdToRender('divTwo');
                break;
            case 'Recently Released':
                sortingParameter = 'recent';
                setDivIdToRender('divTwo');
                break;
            case 'Popularly Viewed':
                sortingParameter = 'views';
                setDivIdToRender('divTwo');
                break;
            default:
                sortingParameter = 'default_parameter';
                setDivIdToRender('divTwo');
        }
        setSortBy(sortingParameter);
        // setData([]); window.scrollTo(0, 0);// Clear previous data

        console.log('Before clearing data:', data);
        console.log('After clearing data:', data);

        // Add a timeout to allow console logs to show current state
        setTimeout(() => {

            console.log('After handling selected option:', data);
        }, 1000); // Adjust the timeout value if needed
    };
    const getImgSrc = (option) => {
        return selectedOption === option ? Select : NonSelect;
    };

    // SortBy end

    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const [isOpen, setIsOpen] = useState(false);
    const [arrowImage, setArrowImage] = useState(ArrowDown);
    const [arrowImageCat, setArrowImageCat] = useState(DownArrow);
    const [isCategoryListVisible, setIsCategoryListVisible] = useState(false);
    const toggleCategoryList = () => {
        setSortByListVisible(false);
        setIsCategoryListVisible(!isCategoryListVisible);
        setIsOpen(!isOpen);
        setArrowImage(isOpen ? ArrowDown : ArrowUp);
        setArrowImageCat(isOpen ? DownArrow : ArrowUp1);
    };

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);

    const mainCategories = [
        { id: 3, name: 'Epic' },
        { id: 2, name: 'Folktales' },
        { id: 1, name: 'Mythology' },
        { id: 99, name: 'Region' },
        // Add more main categories as needed
    ];
    const fetchSubcategories = async (categoryId) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/sub_categories?pageNumber=1&sortMode=ASC&sortBy=id&pageNumber=1&pageSize=20&primaryCategoryId=${categoryId}`);
            const data = await response.json();
            if (data && data.data) {
                setSubcategories(data.data);
            }
        } catch (error) {
            console.error('Error fetching subcategories:', error);
        }
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        fetchSubcategories(categoryId);
        setIsOpen(true);
    };
    const categoryBoxRef = useRef(null);

    const sortByBoxRef = useRef(null);

    const handleClickOutside = (event) => {
        if (categoryBoxRef.current && !categoryBoxRef.current.contains(event.target)) {
            setIsOpen(false);
            setIsCategoryListVisible(false);
            setArrowImage(ArrowDown);
            setArrowImageCat(DownArrow);
        }
        if (sortByBoxRef.current && !sortByBoxRef.current.contains(event.target)) {
            setSortByListVisible(false);
            setContainerClassName('selected-sortby');
            setArrowImage1(ArrowDown1);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const handleStoryClick = (storyID, sequence) => {
        updateStory(storyID);
        ListenStory(storyID, sequence);
    }
    const updateStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(update_story_views, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const ListenStory = (storyID, sequence) => {
        storyID = String(storyID);
        sequence = String(sequence);
        // Define the request body
        const requestBody = {
            story_id: storyID,
            sequence: sequence,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `Bearer ${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(audiolisten, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    return (
        <div>
            <div className={audioList}>
                <div className='ws-stories-basket'>
                    <div>
                        <div className='ws-stories-btn'>
                            <div className='ws-stories-corner-left' ref={categoryBoxRef}>
                                <div className='ws-stories-category-cat'
                                    onClick={toggleCategoryList}
                                >
                                    <img src={StaticCat} alt='category' />
                                </div>
                                <div
                                    className={isCategoryListVisible ? 'other-class-name' : 'ws-stories-category-text'}
                                    onClick={toggleCategoryList}>
                                    Categories
                                    <img src={arrowImage} alt='ArrowDown' className="show-above-950px" />
                                    <img src={arrowImageCat} alt='down' className="show-below-950px" />
                                </div>
                                {isOpen && (
                                    <div className='category-list' >
                                        {/* Display static main categories */}
                                        {mainCategories.map((category) => (
                                            <div className='category-item' key={category.id} onClick={() => handleCategoryClick(category.id)}
                                                onMouseEnter={() => handleCategoryClick(category.id)}
                                            >
                                                {category.name}
                                            </div>
                                        ))}
                                        {/* Conditionally display fetched subcategories */}
                                        {selectedCategory !== null && subcategories.length > 0 && (
                                            <div className='subcategory-list'>
                                                {subcategories.map((subcategory) => (
                                                    <div className='subcategory-item' key={subcategory.subCategoryID}
                                                        onClick={() => { navigate(`/category/${selectedCategory}/subcategory/${subcategory.subCategoryID}/${subcategory.primaryCategoryName}/${subcategory.subCategoryName}`) }}
                                                    >
                                                        {subcategory.subCategoryName}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {/* Show nothing (empty div) when no subcategories available */}
                                        {selectedCategory !== null && subcategories.length === 0 && <div></div>}
                                    </div>
                                )}
                            </div>

                            <div className='ws-stories-corner-right' ref={sortByBoxRef}>
                                <div className='ws-stories-catsortby' onClick={toggleSortByList}>
                                    <img src={SortByCat} alt='sortby' />
                                </div>
                                <div
                                    className={containerClassName}
                                    onClick={toggleSortByList}>
                                    {/* Sort By */}
                                    {selectedOption}
                                    <div className='sortbyarrow'>
                                        <img src={arrowImage1} alt='ArrowDown' />
                                    </div>

                                </div>
                                {isSortByListVisible && (
                                    <div className='dropdown-options' >
                                        <div onClick={() => handleOptionClick('Recently Released')}>
                                            <img src={getImgSrc('Recently Released')} alt='select' />

                                            Recently released</div>
                                        <div onClick={() => handleOptionClick('Praiseworthy')}>
                                            <img src={getImgSrc('Praiseworthy')} alt='select' />
                                            Praiseworthy</div>
                                        <div onClick={() => handleOptionClick('Popularly Viewed')}>
                                            <img src={getImgSrc('Popularly Viewed')} alt='select' />
                                            Popularly viewed</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='mobile'>
                            <Navigation />
                        </div>
                        <AgeCategory selectedAge={selectedAge}
                            setSelectedAge={setSelectedAge} />
                    </div>

                    <div className='ws-stories-box-align-bottom'>
                        <div className='ws-stories-storiescount'>Stories {totalStoriesObject} out of {totalStories}</div>

                        {loading ? ( // Conditionally render the loading screen or data

                            <div className='loading'>
                                <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                            </div>
                        ) : (
                            data.map((story, i) => {
                                const date = new Date(story.creationDate)
                                const currentFirstday = new Date(new Date().setDate(new Date().getDate() - 1))
                                const currentSecondday = new Date(new Date().setDate(new Date().getDate() - 2))
                                const currentThirdday = new Date(new Date().setDate(new Date().getDate() - 3))
                                const currentForthday = new Date(new Date().setDate(new Date().getDate() - 4))
                                if (
                                    CurrentDate.toDateString() === date.toDateString() ||
                                    currentFirstday.toDateString() === date.toDateString()
                                    ||
                                    currentSecondday.toDateString() === date.toDateString()
                                    ||
                                    currentThirdday.toDateString() === date.toDateString()
                                    ||
                                    currentForthday.toDateString() === date.toDateString()
                                ) {
                                    return (
                                        <div key={i}>
                                            <div className='web'>

                                                <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(story.storyID, story.sequence)}>
                                                    <div className='as-audio-btn'
                                                        onClick={() => {
                                                            handlePlayPause(i);
                                                        }}>
                                                        <div className='as-audio-img' onClick={() => {
                                                            handlePlayPause(i);
                                                        }}>
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                        </div>
                                                        <div className='as-audio-player-playbtn'>
                                                            <div>

                                                                <div>
                                                                    {
                                                                        currentPlayingIndex === i ?
                                                                            <div className='as-audio-pause'>
                                                                                <img src={PauseButtonbtnList} alt='Pause' />
                                                                            </div> :
                                                                            <div className='as-audio-play'>
                                                                                <img src={PlayButtonbtnList} alt='Play' />

                                                                            </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='as-stories-text'>

                                                        <div className='ws-stories-title-img' onClick={() => {
                                                        }}>
                                                            <div className='ws-stories-box-title'
                                                                onClick={() => {
                                                                    navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                        , { state: { data: story } });
                                                                    window.scrollTo(0, 0);
                                                                }}
                                                            >
                                                                {story.storyTitle}

                                                            </div>
                                                            <div className='ws-stories-img-hsb' >
                                                                <div className='ws-new-tag'>
                                                                    <img src={NewTag} alt='new-tag' />
                                                                </div>
                                                                <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                    {
                                                                        story.favouriteStory ? (
                                                                            heartedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />

                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            heartedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }

                                                                </div>
                                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                    {
                                                                        story.is_saved ? (
                                                                            bookmarkedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />



                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            bookmarkedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }

                                                                </div>

                                                            </div>
                                                            {showPopup && (
                                                                <div className="pop-up-overlay">
                                                                    <div className="pop-up-box">
                                                                        <p>Login first</p>
                                                                        <button onClick={handleClosePopup}>Close</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='ws-stories-box-auth-date' onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                , { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                        >
                                                            <div className='ws-stories-box-author'>
                                                                <img src={Writter} alt='author' />
                                                                {story.authorName}</div>
                                                            <div className='as-audio-box-narrator'>
                                                                <img src={Narrator} alt='narrator' />
                                                                {story.narratorName}&nbsp;-&nbsp;
                                                            </div>
                                                            <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                        </div>
                                                        <div
                                                            className="ws-stories-box-wrapper"
                                                            style={{ padding: '45px 0', cursor: 'pointer' }} // Move the style here
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`,
                                                                    { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            <div className="ws-stories-box-summary">
                                                                {story.summary}
                                                            </div>
                                                        </div>
                                                        <div className='ws-story-box-viewrate' onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                , { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                        >
                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='ws-story-view'>{story.ListeningCount}</div>
                                                                <div className='ws-story-viewtext'>play</div>
                                                            </div>
                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='as-story-rate'>{Number(story.storyRatings).toFixed(2)}</div>
                                                                <div className='as-story-ratetext'>rating</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                            <div className='mobile'>
                                                <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID, story.sequence)}>
                                                    <div className='as-audio-btn'
                                                        onClick={() => {
                                                            handlePlayPause(i);
                                                        }}>
                                                        <div className='as-audio-img' onClick={() => {
                                                            handlePlayPause(i);
                                                        }}>
                                                            <img src={story.storyThumbnailImage} alt='thumbnail' />

                                                        </div>
                                                        <div className='as-audio-player-playbtn'>
                                                            <div>

                                                                <div>{currentPlayingIndex === i ? <div className='as-audio-pause'>
                                                                    <img src={PauseButtonbtnList} alt='Pause' />

                                                                </div> : <div className='as-audio-play'>
                                                                    <img src={PlayButtonbtnList} alt='Play' />
                                                                </div>}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='as-stories-text' >
                                                        <div className='ws-stories-title-img'>
                                                            <div className='ws-stories-box-title'
                                                                onClick={() => {
                                                                    navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                        , { state: { data: story } });
                                                                    window.scrollTo(0, 0);
                                                                }}
                                                            >
                                                                {story.storyTitle}
                                                            </div>
                                                            <div className='ws-stories-img-hsb'>
                                                                <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                    {
                                                                        story.favouriteStory ? (
                                                                            heartedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />

                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            heartedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                        src={HeartFilled}
                                                                                        alt='heart'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => favouriteStory(story.storyID, i)}
                                                                                        src={Heart}
                                                                                        alt='heart'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }

                                                                </div>
                                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                    {
                                                                        story.is_saved ? (
                                                                            bookmarkedStories[i] ? (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />



                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )
                                                                        ) : (
                                                                            bookmarkedStories[i] ?
                                                                                (
                                                                                    <img
                                                                                        onClick={() => unsavedStory(story.storyID, i)}
                                                                                        src={BookmarkFilled}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                ) : (
                                                                                    <img
                                                                                        onClick={() => savedStory(story.storyID, i)}
                                                                                        src={Bookmark}
                                                                                        alt='bookmark'
                                                                                    />
                                                                                )

                                                                        )
                                                                    }

                                                                </div>

                                                            </div>
                                                            {showPopup && (
                                                                <div className="pop-up-overlay">
                                                                    <div className="pop-up-box">
                                                                        <p>Login first</p>
                                                                        <button onClick={handleClosePopup}>Close</button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className='ws-stories-box-auth-date'
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                    , { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            <div className='ws-stories-box-author'>By {story.authorName}-</div>

                                                            <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                        </div>
                                                        <div className='ws-stories-box-summary'
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                    , { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            {story.summary}
                                                        </div>
                                                        <div className='ws-stories-box-view-rate'
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                    , { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >

                                                            <div className='ws-stories-box-viewbox'>
                                                                <div className='ws-story-view'>{story.ListeningCount}</div>
                                                                <div className='ws-story-viewtext'>play</div>
                                                            </div>
                                                            <div className='ws-stories-box-ratebox'>
                                                                <div className='ws-story-rate'>{Number(story.storyRatings).toFixed(2)}</div>
                                                                <div className='ws-story-ratetext'>rating</div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                                return (
                                    <div key={i}>
                                        <div className='web'>

                                            <div className='ws-stories-box' key={i} onClick={() => handleStoryClick(story.storyID, story.sequence)}>
                                                <div className='as-audio-btn'
                                                    onClick={() => {
                                                        handlePlayPause(i);
                                                    }}>
                                                    <div className='as-audio-img' onClick={() => {
                                                        handlePlayPause(i);
                                                    }}>
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />
                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            <div>
                                                                {
                                                                    currentPlayingIndex === i ?
                                                                        <div className='as-audio-pause'>
                                                                            <img src={PauseButtonbtnList} alt='Pause' />
                                                                        </div> :
                                                                        <div className='as-audio-play'>
                                                                            <img src={PlayButtonbtnList} alt='Play' />

                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='as-stories-text'>

                                                    <div className='ws-stories-title-img' onClick={() => {
                                                    }}>
                                                        <div className='ws-stories-box-title'
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                    , { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            {story.storyTitle}

                                                        </div>
                                                        <div className='ws-stories-img-hsb' >
                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }

                                                            </div>
                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }

                                                            </div>

                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date' onClick={() => {
                                                        navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                            , { state: { data: story } });
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    >
                                                        <div className='ws-stories-box-author'>
                                                            <img src={Writter} alt='author' />
                                                            {story.authorName}</div>
                                                        <div className='as-audio-box-narrator'>
                                                            <img src={Narrator} alt='narrator' />
                                                            {story.narratorName}&nbsp;-&nbsp;
                                                        </div>
                                                        <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                    </div>
                                                    {/* <div className='ws-stories-box-summary' onClick={() => {
                                                    navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                        , { state: { data: story } });
                                                    window.scrollTo(0, 0);
                                                }}
                                                >
                                                    {story.summary}
                                                </div> */}
                                                    <div
                                                        className="ws-stories-box-wrapper"
                                                        style={{ padding: '45px 0', cursor: 'pointer' }} // Move the style here
                                                        onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`,
                                                                { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        <div className="ws-stories-box-summary">
                                                            {story.summary}
                                                        </div>
                                                    </div>
                                                    <div className='ws-story-box-viewrate' onClick={() => {
                                                        navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                            , { state: { data: story } });
                                                        window.scrollTo(0, 0);
                                                    }}
                                                    >
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.ListeningCount}</div>
                                                            <div className='ws-story-viewtext'>play</div>
                                                        </div>
                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='as-story-rate'>{Number(story.storyRatings).toFixed(2)}</div>
                                                            <div className='as-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div className='mobile'>
                                            <div className='ws-stories-box' onClick={() => handleStoryClick(story.storyID, story.sequence)}>
                                                <div className='as-audio-btn'
                                                    onClick={() => {
                                                        handlePlayPause(i);
                                                    }}>
                                                    <div className='as-audio-img' onClick={() => {
                                                        handlePlayPause(i);
                                                    }}>
                                                        <img src={story.storyThumbnailImage} alt='thumbnail' />

                                                    </div>
                                                    <div className='as-audio-player-playbtn'>
                                                        <div>

                                                            <div>
                                                                {currentPlayingIndex === i ?
                                                                    <div className='as-audio-pause'>
                                                                        <img src={PauseButtonbtnList} alt='Pause' />

                                                                    </div>
                                                                    :
                                                                    <div className='as-audio-play'>
                                                                        <img src={PlayButtonbtnList} alt='Play' />
                                                                    </div>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='as-stories-text' >
                                                    <div className='ws-stories-title-img'>
                                                        <div className='ws-stories-box-title'
                                                            onClick={() => {
                                                                navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                    , { state: { data: story } });
                                                                window.scrollTo(0, 0);
                                                            }}
                                                        >
                                                            {story.storyTitle}
                                                        </div>
                                                        <div className='ws-stories-img-hsb'>
                                                            <div className='ws-heart' onClick={() => handleShowHeart(story.storyID, i)}>
                                                                {
                                                                    story.favouriteStory ? (
                                                                        heartedStories[i] ? (
                                                                            <img
                                                                                onClick={() => favouriteStory(story.storyID, i)}
                                                                                src={Heart}
                                                                                alt='heart'
                                                                            />

                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                src={HeartFilled}
                                                                                alt='heart'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        heartedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unfavouriteStory(story.storyID, i)}
                                                                                    src={HeartFilled}
                                                                                    alt='heart'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => favouriteStory(story.storyID, i)}
                                                                                    src={Heart}
                                                                                    alt='heart'
                                                                                />
                                                                            )

                                                                    )
                                                                }

                                                            </div>
                                                            <div className='ws-bookmark' onClick={() => handleShowBookmark(story.storyID, i)}>
                                                                {
                                                                    story.is_saved ? (
                                                                        bookmarkedStories[i] ? (
                                                                            <img
                                                                                onClick={() => savedStory(story.storyID, i)}
                                                                                src={Bookmark}
                                                                                alt='bookmark'
                                                                            />



                                                                        ) : (
                                                                            <img
                                                                                onClick={() => unsavedStory(story.storyID, i)}
                                                                                src={BookmarkFilled}
                                                                                alt='bookmark'
                                                                            />
                                                                        )
                                                                    ) : (
                                                                        bookmarkedStories[i] ?
                                                                            (
                                                                                <img
                                                                                    onClick={() => unsavedStory(story.storyID, i)}
                                                                                    src={BookmarkFilled}
                                                                                    alt='bookmark'
                                                                                />
                                                                            ) : (
                                                                                <img
                                                                                    onClick={() => savedStory(story.storyID, i)}
                                                                                    src={Bookmark}
                                                                                    alt='bookmark'
                                                                                />
                                                                            )

                                                                    )
                                                                }

                                                            </div>

                                                        </div>
                                                        {showPopup && (
                                                            <div className="pop-up-overlay">
                                                                <div className="pop-up-box">
                                                                    <p>Login first</p>
                                                                    <button onClick={handleClosePopup}>Close</button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className='ws-stories-box-auth-date'
                                                        onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                , { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        <div className='ws-stories-box-author'>By {story.authorName}-</div>

                                                        <div className='ws-stories-box-date'>{formatDate(story.creationDate)}</div>
                                                    </div>
                                                    <div className='ws-stories-box-summary'
                                                        onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                , { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >
                                                        {story.summary}
                                                    </div>
                                                    <div className='ws-stories-box-view-rate'
                                                        onClick={() => {
                                                            navigate(`/kids-bedtime-stories-audio/${story.storyID}/${story.sequence}/${story.storyTitle.replace(/\s+/g, '-')}`
                                                                , { state: { data: story } });
                                                            window.scrollTo(0, 0);
                                                        }}
                                                    >

                                                        <div className='ws-stories-box-viewbox'>
                                                            <div className='ws-story-view'>{story.ListeningCount}</div>
                                                            <div className='ws-story-viewtext'>play</div>
                                                        </div>
                                                        <div className='ws-stories-box-ratebox'>
                                                            <div className='ws-story-rate'>{Number(story.storyRatings).toFixed(2)}</div>
                                                            <div className='ws-story-ratetext'>rating</div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )}
                    </div>
                </div>
                <div className='ws-stories-ads'>


                </div>
            </div>
            <div className={audioPagination}>
                <div className='ws-stories-pagination'>

                </div>
            </div>
            <div className={audioPagination}>
                <div className="pagination">
                    <div className="page-count">
                        Page {page} of {totalPages}
                    </div>
                    <div className="pagination-controls">
                        <div className="pagination-center">
                            <div className="page-numbers">
                                <div className='web'>
                                    <button className="first-page-button" onClick={goToFirstPage}>
                                        Go to First
                                    </button>
                                </div>
                                <button className="prev-page-button" onClick={goToPrevPage}>
                                    <img src={ArrowLeftPagination} />
                                </button>
                                {renderPageNumbers()}

                                {!allStoriesLoaded && (
                                    <button className="next-button" onClick={() => { goToNextPage(); window.scrollTo(0, 0); }}>
                                        <img src={ArrowRightPagination} />
                                    </button>
                                )}
                                <div className='web'>
                                    <button className="last-page-button" onClick={goToLastPage}>
                                        Go to Last
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="ws-stories-pagination-box">
                </div>
            </div>

            {isInputVisible && (
                <div
                    className={expandedClassName}

                >

                    {/* Render story details when available */}
                    {storyDetails.storyImage && (
                        <div>
                            <div className='mobile'>
                                <div className={seekingInputMob}>
                                    <input id='myinput'
                                        type="range"
                                        min={0}
                                        max={duration}
                                        value={currentTime}
                                        onChange={handleSeek}
                                        className='slider'
                                    />
                                </div>
                            </div>
                            <div className={audioPlayerBox}>
                                <div className='as-playstories-img'>
                                    <img src={storyDetails.storyImage} alt='Story' />

                                </div>
                                <div className='as-player-authorName'>
                                    <div className='as-player-storyTitle'>{storyDetails.storyTitle} </div>
                                </div>
                                <div className='as-player-rplaypausef'>
                                    <div className='as-player-playpausebtn'>
                                        <div className='as-player-timerewind' onClick={handleTimeRewind}>
                                            <img src={TimeRewind} alt='TimeRewind' />
                                        </div>
                                        <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                            {isPlaying ? (
                                                <img src={PauseButtonbtn} alt='Pause' />
                                            ) : (
                                                <img src={PlayButtonbtn} alt='Play' />
                                            )}
                                        </div>

                                        <div className='as-player-timeforward' onClick={handleTimeForward}>
                                            <img src={TimeForward} alt='Timeforward' />
                                        </div>

                                    </div>
                                    <div className='web'>
                                        <div className='seekbar'>
                                            <div className='as-player-currenttime'>{formatTime(currentTime)}</div>
                                            <div className='seekinginput'>
                                                <input id='myinput'
                                                    type="range"
                                                    min={0}
                                                    max={duration}
                                                    value={currentTime}
                                                    onChange={handleSeek}
                                                    className='slider'
                                                />
                                            </div>
                                            <div className='as-player-currenttime'>{formatTime(duration)}</div>
                                        </div>

                                    </div>

                                </div>
                                <div className='as-expandbox '>
                                    <div className='as-crossbtn'
                                        onClick={handleCross}
                                    >
                                        <img src={Cross} alt='expand' />
                                    </div>
                                    <div className='as-expandbtn'
                                        onClick={onExpandClick}
                                    >
                                        <img src={Expand} alt='expand' />
                                    </div>
                                </div>
                            </div>
                            <div className='web'>
                                <div className={audioPlayerBoxExpand}>

                                    <div className='as-audio-detail-view'>
                                        <div className='as-audio-backbtn'
                                            onClick={() => {
                                                onExpandClose();
                                                window.scrollTo(0, 0);
                                            }}
                                        >
                                            <img src={ArrowLeft} alt='backbtn' />
                                            <div className='as-audio-now-playing'>
                                                Now Playing
                                            </div>
                                        </div>
                                        <div className='as-audio-detailview'>
                                            <div className='as-detailview-thumbnailimg'>
                                                <img src={storyDetails.storyImage} alt='Story' />

                                            </div>
                                            <div className='as-detailview-storyTitle'>
                                                <div className='as-detailview-heart' >
                                                    <div className='as-detailview-heart-icon' onClick={() => handleShowHeart(storyDetails.storyID)}>
                                                        {
                                                            storyDetails.favouriteStory ? (
                                                                heartedStories ? (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />


                                                                ) : (
                                                                    <img
                                                                        onClick={() => favouriteStory(storyDetails.storyID)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />
                                                                )
                                                            ) : (
                                                                heartedStories ?
                                                                    (
                                                                        <img
                                                                            onClick={() => favouriteStory(storyDetails.storyID)}
                                                                            src={Heart}
                                                                            alt='heart'
                                                                        />

                                                                    ) : (
                                                                        <img
                                                                            onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                            src={HeartFilled}
                                                                            alt='heart'
                                                                        />

                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                                <div className='as-audio-TitleAuthorNarrator'>
                                                    <div className='as-audio-storyTitle'>{storyDetails.storyTitle}</div>
                                                    <div className='as-detailview-authorNarrator'>
                                                        <div>{storyDetails.authorName},&nbsp;

                                                            {storyDetails.narratorName}</div>

                                                    </div>
                                                </div>

                                                <div className='as-detailview-bookmark-share'>
                                                    <div className='ws-bookmarkEX' onClick={() => handleShowBookmark(storyDetails.storyID)}>
                                                        {
                                                            storyDetails.is_saved ? (
                                                                bookmarkedStories ? (


                                                                    <img
                                                                        onClick={() => unsavedStory(storyDetails.storyID)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => savedStory(storyDetails.storyID)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />

                                                                )
                                                            ) : (
                                                                bookmarkedStories ?
                                                                    (<img
                                                                        onClick={() => savedStory(storyDetails.storyID)}
                                                                        src={Bookmark}
                                                                        alt='bookmark'
                                                                    />

                                                                    ) : (
                                                                        <img
                                                                            onClick={() => unsavedStory(storyDetails.storyID)}
                                                                            src={BookmarkFilled}
                                                                            alt='bookmark'
                                                                        />

                                                                    )

                                                            )
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='as-detailview-playrate'>
                                                <div className='as-detail-play'><span className='as-detail-playno'>{storyDetails.ListeningCount}&nbsp;</span> play</div>
                                                <div className='as-detail-rate'> <span className='as-detail-rateno'>{storyDetails.storyRatings}&nbsp; </span>rating</div>
                                            </div>

                                            <div className='as-audio-playpausebtn'>
                                                <div className='as-player-timerewindEX' onClick={handleTimeRewind}>
                                                    <img src={TimeRewindEX} alt='TimeRewind' />
                                                </div>
                                                <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                                    {isPlaying ? (
                                                        <img src={PauseButtonEx} alt='Pause' />
                                                    ) : (
                                                        <img src={PlayButtonEx} alt='Play' />
                                                    )}
                                                </div>

                                                <div className='as-player-timeforwardEX' onClick={handleTimeForward}>
                                                    <img src={TimeForwardEX} alt='Timeforward' />
                                                </div>
                                            </div>
                                            <div className='seekbar-expand'>

                                                <div className='seekinginput-expand'>
                                                    <input id='myinput'
                                                        type="range"
                                                        min={0}
                                                        max={duration}
                                                        value={currentTime}
                                                        onChange={handleSeek}
                                                        className='slider-expand'
                                                    />
                                                </div>
                                            </div>
                                            <div className='as-audio-timeShow'>
                                                <div className='as-player-currenttimeEX'>{formatTime(currentTime)}</div>
                                                <div className='as-player-maxtimeEX'>{formatTime(duration)}</div>
                                            </div>
                                            <div className='as-audio-rating'>
                                                <div className='as-detail-rating'>
                                                    Rate Story
                                                </div>
                                                {(authToken == null || authToken === "no token") ?
                                                    <div className='as-audio-detail-ratestar'

                                                    >

                                                        {filledStars.map((isFilled, index) => (
                                                            <img

                                                                key={index}
                                                                src={isFilled ? StarFill : Star}
                                                                alt={`Star ${index + 1}`}
                                                                onClick={handleShowSignup}
                                                            />
                                                        ))}
                                                    </div>
                                                    :
                                                    <div className='as-audio-detail-ratestar'

                                                    >

                                                        {filledStars.map((isFilled, index) => (
                                                            <img

                                                                key={index}
                                                                src={isFilled ? StarFill : Star}
                                                                alt={`Star ${index + 1}`}
                                                                onClick={() => toggleStar(storyDetails.storyID, storyDetails.sequence, index)}
                                                            />
                                                        ))}
                                                    </div>
                                                }


                                            </div>
                                            {showSignup && (
                                                <div className="speech-bubble-container">


                                                    <div className="speech-bubble" onClick={handleCloseSignup}>
                                                        <div className="box-content">
                                                            <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                                        </div>
                                                    </div>

                                                </div>
                                            )}

                                        </div>
                                    </div>
                                    <div className='as-detailview-ads'>
                                        <AdsDetailView />
                                    </div>
                                </div>
                            </div>
                            <div className='mobile'>
                                <div className={audioPlayerBoxExpand}>
                                    <div className='as-mob-audio-detail-view'>
                                        <div className='as-mob-navbar'>
                                            <div className='as-mob-arrowdown'
                                                onClick={() => {
                                                    onExpandClose();
                                                    window.scrollTo(0, 0);
                                                }}
                                            >
                                                <img src={ArrowDownDetail} alt='ArrowDown' />
                                            </div>

                                            <div className='as-mob-nowplaying'>
                                                Now Playing
                                            </div>
                                           
                                        </div>
                                        <div className='as-mob-category-img'>
                                            <img src={storyDetails.storyImage} alt='Story' />
                                        </div>
                                        <div className='as-detailview-storyTitle'>
                                            <div className='as-detailview-heart' >
                                                <div className='as-detailview-heart-icon' onClick={() => handleShowHeart(storyDetails.storyID)}>
                                                    {
                                                        storyDetails.favouriteStory ? (
                                                            heartedStories ? (
                                                                <img
                                                                    onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                    src={HeartFilled}
                                                                    alt='heart'
                                                                />


                                                            ) : (
                                                                <img
                                                                    onClick={() => favouriteStory(storyDetails.storyID)}
                                                                    src={Heart}
                                                                    alt='heart'
                                                                />
                                                            )
                                                        ) : (
                                                            heartedStories ?
                                                                (
                                                                    <img
                                                                        onClick={() => favouriteStory(storyDetails.storyID)}
                                                                        src={Heart}
                                                                        alt='heart'
                                                                    />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unfavouriteStory(storyDetails.storyID)}
                                                                        src={HeartFilled}
                                                                        alt='heart'
                                                                    />

                                                                )

                                                        )
                                                    }

                                                </div>
                                            </div>
                                            <div className='as-audio-TitleAuthorNarrator'>
                                                <div className='as-audio-storyTitle'>{storyDetails.storyTitle}</div>
                                                <div className='as-detailview-authorNarrator'>
                                                    <div>{storyDetails.authorName},&nbsp;&nbsp;

                                                        {storyDetails.narratorName}</div>

                                                </div>
                                            </div>

                                            <div className='as-detailview-bookmark-share'>
                                                <div className='ws-bookmark' onClick={() => handleShowBookmark(storyDetails.storyID)}>
                                                    {
                                                        storyDetails.is_saved ? (
                                                            bookmarkedStories ? (


                                                                <img
                                                                    onClick={() => unsavedStory(storyDetails.storyID)}
                                                                    src={BookmarkFilled}
                                                                    alt='bookmark'
                                                                />

                                                            ) : (
                                                                <img
                                                                    onClick={() => savedStory(storyDetails.storyID)}
                                                                    src={Bookmark}
                                                                    alt='bookmark'
                                                                />

                                                            )
                                                        ) : (
                                                            bookmarkedStories ?
                                                                (<img
                                                                    onClick={() => savedStory(storyDetails.storyID)}
                                                                    src={Bookmark}
                                                                    alt='bookmark'
                                                                />

                                                                ) : (
                                                                    <img
                                                                        onClick={() => unsavedStory(storyDetails.storyID)}
                                                                        src={BookmarkFilled}
                                                                        alt='bookmark'
                                                                    />

                                                                )

                                                        )
                                                    }

                                                </div>
                                            </div>

                                        </div>
                                        <div className='as-detailview-playrate'>
                                            <div className='as-detail-play'><span className='as-detail-playno'>{storyDetails.storyViews}&nbsp;</span> Views</div>
                                            <div className='as-detail-rate'> <span className='as-detail-rateno'>{storyDetails.storyRatings}&nbsp; </span>rating</div>
                                        </div>
                                        <div className='seekbar-expand'>

                                            <div className='seekinginput-expand'>
                                                <input id='myinput'
                                                    type="range"
                                                    min={0}
                                                    max={duration}
                                                    value={currentTime}
                                                    onChange={handleSeek}
                                                    className='slider-expand'
                                                />
                                            </div>
                                        </div>
                                        <div className='as-audio-expand-time'>
                                            <div className='as-audio-currenttime'>
                                                {formatTime(currentTime)}
                                            </div>
                                            <div className='as-audio-maxtime'>
                                                {formatTime(duration)}
                                            </div>
                                        </div>
                                        <div className='as-audio-playpausebtn'>
                                            <div className='as-player-timerewind' onClick={handleTimeRewind}>
                                                <img src={TimeRewindEX} alt='TimeRewind' />
                                            </div>
                                            <div className='as-player-playpause' onClick={handleBoxPlayPause}>
                                                {isPlaying ? (
                                                    <img src={PauseButtonEx} alt='Pause' />
                                                ) : (
                                                    <img src={PlayButtonEx} alt='Play' />
                                                )}
                                            </div>

                                            <div className='as-player-timeforward' onClick={handleTimeForward}>
                                                <img src={TimeForwardEX} alt='Timeforward' />
                                            </div>

                                        </div>
                                        <div className='as-audio-rating'>
                                            <div className='as-detail-rating'>
                                                Rate Story
                                            </div>
                                            <div className='as-audio-detail-ratestar'

                                            >
                                                {filledStars.map((isFilled, index) => (
                                                    <img

                                                        key={index}
                                                        src={isFilled ? StarFill : Star}
                                                        alt={`Star ${index + 1}`}
                                                        onClick={handleShowSignup}
                                                    />
                                                ))}
                                            </div>

                                        </div>
                                        {showSignup && (
                                            <div className="speech-bubble-container">


                                                <div className="speech-bubble" onClick={handleCloseSignup}>
                                                    <div className="box-content">
                                                        <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                                    </div>
                                                </div>

                                            </div>
                                        )}

                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default AudioStoriesList
