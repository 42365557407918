/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : App Page will deal with Route page 
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import './App.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import AboutUs from './View/Web/AM-AplicationManagement/src/JS/AboutUs/AboutUs';
import CookieDisclaimer from './View/Web/AM-AplicationManagement/src/JS/Cookie disclaimer/CookieDisclaimer';
import PrivacyPolicy from './View/Web/AM-AplicationManagement/src/JS/PrivacyPolicy/PrivacyPolicy';
import TermOfUse from './View/Web/AM-AplicationManagement/src/JS/TermOfUse/TermOfUse';
import StoriesList from './View/Web/WS-WrittenStories/src/JS/StoriesList/StoriesList';
import WritingSection from './View/Web/CN-ContributionNetwork/src/JS/WritingSection';
import StoryDetailView from './View/Web/WS-WrittenStories/src/JS/StoryDetailView/StoryDetailView';
import AudioStories from './View/Web/AS-AudioStories/JS/AudioListView/AudioStories';
import SearchResult from './View/Web/SM-StoriesMetadata/JS/SearchContent/SearchResult';
import CategoryList from './View/Web/SM-StoriesMetadata/JS/Category/CategoryList';
import AuthorList from './View/Web/CN-ContributionNetwork/src/JS/AuthorList/AuthorList';
import AuthorProfile from './View/Web/CN-ContributionNetwork/src/JS/AuthorProfile/AuthorProfile';
import SignUpPop from './View/Web/UM-UserManagement/src/JS/SignUpPop';
import LoginPop from './View/Web/UM-UserManagement/src/JS/LoginPop';
import DiscoverAuthor from './View/Web/CN-ContributionNetwork/src/JS/DiscoverAuthor/DiscoverAuthor';
import FavoriteAuthor from './View/Web/CN-ContributionNetwork/src/JS/FavoriteAuthor/FavoriteAuthor';
import ForgotPassword from './View/Web/UM-UserManagement/src/JS/ForgotPassword';
import Verification from './View/Web/UM-UserManagement/src/JS/Verification';
import CreatePassword from './View/Web/UM-UserManagement/src/JS/CreatePassword';
import AuthorCommunity from './View/Web/CN-ContributionNetwork/src/JS/AuthorCommunity/AuthorCommunity';
import NotFound from './View/Web/AM-AplicationManagement/src/JS/NotFound/NotFound';
import UserProfile from './View/Web/UM-UserManagement/src/JS/UserProfile/UserProfile';
import EditStory from './View/Web/CN-ContributionNetwork/src/JS/EditStory';
import FavoriteAuthorSearch from './View/Web/CN-ContributionNetwork/src/JS/FavoriteAuthor/FavoriteAuthorSearch';
import AudioDetailView from './View/Web/AS-AudioStories/JS/AudioDetailView/AudioDetailView';
import ModeratorEdit from './View/Web/CN-ContributionNetwork/src/JS/ModeratorEdit/ModeratorEdit';
import ModeratorPreview from './View/Web/CN-ContributionNetwork/src/JS/ModeratorEdit/ModeratorPreview';
import SitemapXml from './sitemap.xml'
import ModeratorApprovedEdit from './View/Web/CN-ContributionNetwork/src/JS/ModeratorApprovedEdit/ModeratorEdit/ModeratorApprovedEdit';
import VideoPlayer from './View/Web/VS-VideoStories/JS/VideoPlayer';
import AppPrivacyPolicy from './View/Web/AM-AplicationManagement/src/JS/AppPolicy/AppPrivacyPolicy';
import AppTermPolicy from './View/Web/AM-AplicationManagement/src/JS/AppPolicy/AppTermPolicy';
import VideoList from './View/Web/VS-VideoStories/JS/VideoList/VideoList';
import VideoDetailView from './View/Web/VS-VideoStories/JS/VideoDetail/VideoDetailView';
import ServerDown from './View/Web/AM-AplicationManagement/src/JS/ServerDown/ServerDown';
import SingleAudio from './View/Web/AS-AudioStories/JS/AudioSingle/SingleAudio';
const Sitemap = () => {
  return (
    <pre>{SitemapXml}</pre> // Render the content of the sitemap.xml file
  );
};


function App() {
  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          {/* Route for the sitemap.xml file */}
          <Route path="/sitemap.xml" component={Sitemap} />
          {/* WS-route */}
          <Route exact path="/" element={<StoriesList />} />
          <Route exact path="/bedtime-short-stories-for-kids/:title" element={<StoryDetailView />} />
          {/* VS-route */}
          <Route exact path="/vs" element={<VideoPlayer />} />
          <Route exact path='/videostories' element={<VideoList />} />
          <Route exact path='/videostories/:story_id/' element={<VideoDetailView />} />
          {/* AM-route */}
          <Route exact path="/Legal/WBS/privacy.html" element={<AppPrivacyPolicy />} />
          <Route exact path="/Legal/WBS/Termsandconditions.html" element={<AppTermPolicy />} />
          <Route exact path="*" element={<Navigate to="/notfound" />} />
          <Route exact path="/newserver" element={<ServerDown />} />
          <Route exact path="/notfound" element={<NotFound />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/cookie" element={<CookieDisclaimer />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
          <Route exact path="/term" element={<TermOfUse />} />
          {/* CN-Route */}
          <Route exact path='/writingSection' element={<WritingSection />} />
          <Route exact path='/editstory/:storyID' element={<EditStory />} />
          <Route exact path='/moderatoreditstory/:storyID/:authorUserID' element={<ModeratorEdit />} />
          <Route exact path='/moderatorapprovededitstory/:storyID/:authorUserID' element={<ModeratorApprovedEdit />} />
          <Route exact path='/moderatorpreview' element={<ModeratorPreview />} />
          <Route exact path='/authorlist' element={<AuthorList />} />
          <Route exact path='/authorcommunity' element={<AuthorCommunity />} />
          {/* AS-Route */}
          <Route exact path='/kids-bedtime-stories-audio' element={<AudioStories />} />
          <Route exact path="/kids-bedtime-stories-audio/:story_id/:sequence/:title" element={<AudioDetailView />} />
          <Route exact path='/singleaudio/:story_id/:title' element={<SingleAudio/>}/>
          {/* SM-StoriesMetadata */}
          <Route exact path="/search-results" element={<SearchResult />} />
          <Route exact path="/category/:selectedCategory/subcategory/:subCategoryID/:CategoryName/:subCategoryName" element={<CategoryList />} />
          {/* UM-UserManagement */}
          <Route exact path='/loginpop' element={<LoginPop />} />
          <Route exact path='/signuppop' element={<SignUpPop />} />
          <Route exact path='/forgotpassword' element={<ForgotPassword />} />
          <Route exact path='/verification' element={<Verification />} />
          <Route exact path='/createpassword' element={<CreatePassword />} />
          <Route exact path='/userprofile' element={<UserProfile />} />
          <Route exact path='/favoriteauthor/:searchText' element={<FavoriteAuthorSearch />} />
          <Route exact path='/authorProfile/:authorId/:authorName' element={<AuthorProfile />} />
          <Route exact path='/discoverauthor' element={<DiscoverAuthor />} />
          <Route exact path='/favoriteauthor' element={<FavoriteAuthor />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
