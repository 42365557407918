/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : Login PopUp 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import LoginBanner from '../../Assets/LoginBG.webp'
import '../CS/CreatePassword.css'
import SignUpPop from './SignUpPop'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { UM_Login, changepassword } from '../../../../../Model/UM-UserManagement/UserManagement';
import EyeOpen from '../../Assets/eye.webp'
import EyeClosed from '../../Assets/eye hide password .webp'
import Lock from '../../Assets/Lock.webp'

function CreatePassword({ closePopup }) {
    const navigate = useNavigate();
    const location = useLocation();
    const authToken = location.state && location.state.authToken;
    const popupRef = useRef(null);
    const [showSignup, setShowSignup] = useState(false); // State to control signup overlay visibility
    const [passwordVal, setPasswordVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [confirmPasswordVal, setConfirmPasswordVal] = useState(''); // New state for confirm password

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleSaveChanges = () => {
            // Check if the passwords match
    if (passwordVal !== confirmPasswordVal) {
        alert('Passwords do not match');
        return;
    }
        try {
            setLoading(true);

            const url = changepassword;
            const requestData = {
                password: passwordVal,
                loginType: 'normal',
            };
            const requestHeaders = {
                Authorization: `${authToken}`,
            };

            // Make an API call to change the password with authToken
            axios.patch(url, requestData, { headers: requestHeaders })
                .then(response => {
                    // console.log(response.data);

                    if (response.data.status === 200) {
                        navigate('/loginpop');
                    } else {
                        alert(response.data.message);
                    }

                    // Set loading to false after the API call is complete (success or failure)
                    setLoading(false);
                })
                .catch(error => {
                    console.error('Error changing password:', error);
                    // Handle the error as needed

                    // Set loading to false after the API call is complete (success or failure)
                    setLoading(false);
                });
        } catch (error) {
            // Handle errors as needed

            // Set loading to false after the API call is complete (success or failure)
            setLoading(false);
        }
    };
    const handlepopup = (event) => {
        const targetId = event.target.id;

        // Check the id and navigate accordingly
        if (targetId === 'pop-up-overlay') {
            navigate('/');
        } else if (targetId === 'um-forgot') {
            // Handle um-login click (e.g., show a popup or perform other actions)
        }
    };
    return (

        <div className="pop-up-overlay"
            onClick={handlepopup}
            id='pop-up-overlay'
        >
            <div className='um-forgot'
            >
                <div className='um-login-banner'>
                    <div className='um-login-img'>
                        <img src={LoginBanner} alt='LoginBanner' />
                    </div>
                </div>
                <div className='um-forgot-detail'>
                    <div>
                        <div className='um-forgot-account'>
                            Create new password
                        </div>
                        <div >
                            <div className='um-login-forgot'>
                                We'll ask for this password whenever you log in
                            </div>

                        </div>
                        <div className='um-forgot-email-text'>New password</div>
                        <div className='um-login-passwordbox'>
                            <div className='um-password-lock-textbox'>
                                <div className='um-password-lock'>
                                    <img src={Lock} alt='lock' />

                                </div>
                                <input
                                    onChange={(e) => setPasswordVal(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    className='um-password-inputbox'
                                    placeholder="New passsword"
                                />
                                <div className='um-create-eye' onClick={togglePasswordVisibility}>
                                    <img src={showPassword ? EyeClosed : EyeOpen} alt={showPassword ? 'eyeclosed' : 'eyeopen'} />
                                </div>
                            </div>
                        </div>
                        <div className='um-forgot-email-text'>Confirm new password</div>

                        <div className='um-login-passwordbox'>
                            <div className='um-password-lock-textbox'>
                                <div className='um-password-lock'>
                                    <img src={Lock} alt='lock' />
                                </div>
                                <input
                                    onChange={(e) => setConfirmPasswordVal(e.target.value)} // Handle confirm password change
                                    type={showPassword ? 'text' : 'password'}
                                    className='um-password-inputbox'
                                    placeholder="Confirm new password"
                                />
                                <div className='um-create-eye' onClick={togglePasswordVisibility}>
                                    <img src={showPassword ? EyeClosed : EyeOpen} alt={showPassword ? 'eyeclosed' : 'eyeopen'} />
                                </div>
                            </div>
                        </div>

                        <div className='um-sendotp-btn'
                            onClick={handleSaveChanges}
                        >
                            Save Changes
                        </div>
                        {/* Display loading overlay or spinner based on the loading state */}
                        {loading && (
                            <div className="loading-overlay">
                                {/* Add your loading spinner or message here */}
                                Loading...
                            </div>
                        )}
                    </div>


                </div>


            </div>
            {/* Display signup overlay if showSignup state is true */}
            {showSignup && (
                <div className="signup-overlay">
                    <div className="signup-form">
                        <SignUpPop />
                    </div>
                </div>
            )}
        </div>

    )
}

export default CreatePassword
