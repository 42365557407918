/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Humberger Menu Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import logo from '../../../Assets/Images/WBSLogo.png'
import SearchMob from '../../../Assets/Images/Search2.webp';
import PlayStore from '../../../Assets/Images/GooglePlay.webp'
import Instagram from '../../../Assets/Images/Instagram.webp'
import Facebook from '../../../Assets/Images/Facebook.webp'
import Twitter from '../../../Assets/Images/Twitter.webp'
import YouTube from '../../../Assets/Images/youtube.webp'
import Cross from '../../../Assets/Images/Cross.webp'
import Hamburger from '../../../Assets/Images/Menu Hamburger.webp'
import './Hamburger.css'; // Ensure this is included
import { useLocation, useNavigate } from 'react-router-dom';

const HamburgerMenu = (props) => {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem('IsAdmin') || null);
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const suggestionBoxRef = useRef(null);

    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
        setIsAdmin(localStorage.getItem('IsAdmin') || null);
    }, []);

    const navigate = useNavigate();
    const totalStories = props.totalStories;
    const [isOpen, setIsOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [activeTab, setActiveTab] = useState('home'); // Initial active tab


    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };
    const yellowColor = {
        color: ' #403085',
    };
    const [searchInputVisible, setSearchInputVisible] = useState(false);
    const handleSearchIconClick = () => {
        setSearchInputVisible(!searchInputVisible); // Toggle input visibility
    };
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        if (searchText.trim() !== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchText]);

    const handleSuggestionClick = (clickedTitle) => {
        navigate(`/search-results?searchText=${clickedTitle}`);
        window.location.reload(); // Reload the screen
    };
    const handleClickOutside = (event) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setSuggestions([]); // Hide suggestions when clicking outside the box
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };
    const fetchSuggestions = async () => {
        const PAGE_LIMIT = 10; // Define your page limit
        const page = 1; // Set the page number

        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=ASC&sortBy=views&user_id=1&tags=${searchText}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&user_id=${userId}`);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.length > 0) {
                    const extractedSuggestions = responseData.data.map(item => ({
                        storyID: item.storyID,
                        title: item.title,
                        summary: item.summary,
                    }));
                    setSuggestions(extractedSuggestions);
                    setShowNoSuggestions(false); // Hide "No suggestions found" message
                } else {
                    setSuggestions([]);
                    setShowNoSuggestions(true); // Show "No suggestions found" message
                }
            } else {
                console.error('Failed to fetch suggestions');
                setSuggestions([]);
                setShowNoSuggestions(true); // Show "No suggestions found" message
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
            setShowNoSuggestions(true); // Show "No suggestions found" message
        }
    };
    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            navigate(`/search-results?searchText=${searchText}&totalStories=${totalStories}`);
            window.location.reload(); // Reload the screen
        }
    };
    useEffect(() => {
        // Reset the active tab when the route changes
        const pathname = location.pathname;
        switch (pathname) {
            case '/kids-bedtime-stories-audio':
                setActiveTab('kids-bedtime-stories-audio');
                break;
            case '/': // Add other route cases if needed
                setActiveTab('home');
                break;
            case '/authorlist': // Add other route cases if needed
                setActiveTab('authorlist');
                break;
            case '/videostories': // Add other route cases if needed
                setActiveTab('videostories');
                break;
            // Add additional cases for other routes if needed
            default:
                setActiveTab(''); // Reset to default active tab when route doesn't match
                break;
        }
    }, [location.pathname]);

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName === 'home' ? '' : tabName}`); // Navigate based on tab clicked
    };
    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }

    const handleLogout = () => {
        // Handle logout logic here
        // Clear values from localStorage and update component state
        localStorage.removeItem('token');
        localStorage.removeItem('UserID');
        localStorage.removeItem('IsAdmin');
        setAuthToken(null);
        setUserID(null);
        setIsAdmin(null);
        navigate('/'
        );

        window.location.reload();
        navigate('/'
        );


    };
    return (
        <div>
            <header className="header">
                {/* Hamburger Menu on the right */}
                <div className="menu-icon" onClick={toggleMenu}>
                    {isOpen ?
                        // '☰'
                        <>
                            <img src={Hamburger} alt='Hamburger' />
                        </>
                        :
                        <img src={Hamburger} alt='Hamburger' />
                    } {/* Hamburger icon only when menu is not open */}
                </div>


                {/* Title in the middle */}
                <h1 className="title"><span style={yellowColor}>Wiki</span>&nbsp;bedtime&nbsp;stories</h1>
                <div className='headerspacemob3'>
                    <img src={SearchMob} alt='searchmob' onClick={handleSearchIconClick} />
                </div>
                {/* Logo on the left */}
                <div className="logo-container" onClick={() => { navigate('/'); window.scrollTo(0, 0); }}>
                    <img src={logo} alt="logo" className="logo" />
                </div>


                {/* Navigation Menu */}
                <div className={`overlay ${isOpen ? 'show' : ''}`} onClick={toggleMenu}></div>
                <nav className={`menu ${isOpen ? 'show' : ''}`}>
                    {/* Close Icon */}
                    <div className="close-icon" onClick={toggleMenu}>
                        {isOpen ?
                            <>
                                <img src={Cross} alt='cross' />
                            </>
                            :
                            ''
                        } {/* X icon only when menu is open */}
                    </div>
                    {/* '✖' */}
                    <div className='menu-data'>
                        <ul>

                            <li>
                                {(authToken == null || authToken === "no token") ?
                                    <div onClick={handleLoginSignUp}>Log in/ Sign up</div>
                                    :
                                    <div></div>}
                            </li>
                            <li><div onClick={() => { navigate('/') }} >Home
                            </div></li>
                            <li><div onClick={() => {
                                handleTabClick('kids-bedtime-stories-audio'); navigate('/kids-bedtime-stories-audio'
                                    // , { state: { authToken: authToken, from: '' } }
                                );
                            }}
                            >Audio</div></li>
                            <li><div onClick={() => {
                                handleTabClick('videostories'); navigate('/videostories'
                                    // , { state: { authToken: authToken, from: '' } }
                                );
                            }}
                            >Video</div></li>
                            <li><div onClick={() => {
                                handleTabClick('authorlist'); navigate('/authorlist'
                                    // , { state: { authToken: authToken, from: '' } }
                                );
                            }}>Author</div></li>
                        </ul>
                        <div className='header-am'>
                            <div onClick={() => { navigate('/aboutus'); window.scrollTo(0, 0); }}>Our Story</div>
                            <div onClick={() => { navigate('/cookie'); window.scrollTo(0, 0); }}>Cookie Policy</div>
                            <div onClick={() => { navigate('/privacy'); window.scrollTo(0, 0); }}>Privacy Policy</div>
                            <div onClick={() => { navigate('/term'); window.scrollTo(0, 0); }}>Terms of Use</div>
                            {(authToken == null || authToken === "no token") ? <div></div> : <div onClick={handleLogout}>Logout</div>}
                        </div>
                        {isOpen && (
                            <div className='bottom-nav'>
                                <div className='header-app-store'>
                                    <div>Use the App</div>
                                    <div onClick={() => { window.open('//play.google.com/store/apps/details?id=tech.kushmanda.wikibedtimestories&hl=en&gl=US', '_blank') }}> <img src={PlayStore} alt='PlayStore' /></div>
                                </div>
                                <div className='header-follow-us'>
                                    Follow Us
                                </div>
                                <div className='social-media'>

                                    <div className='social-logo' onClick={() => { window.open('//www.facebook.com/profile.php?id=100076326081861', '_blank') }}>
                                        <img src={Facebook} alt='facebook' />
                                    </div>
                                    <div className='social-logo'>
                                        <img src={Twitter} alt='twitter' onClick={() => { window.open('//twitter.com/wikibedtime', '_blank') }} />
                                    </div>
                                    <div className='social-logo' onClick={() => { window.open('//www.instagram.com/wikibedtimestories/', '_blank') }}>
                                        <img src={Instagram} alt='instagram' />
                                    </div>
                                    <div className='social-logo'>
                                        <img src={YouTube} alt='youtube' onClick={() => { window.open('//youtube.com/@Wikibedtimestories', '_blank') }} />
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* <div className='header-rights'>© All rights reserved.</div> */}
                    </div>

                </nav>
            </header>
            <div className={`mob-search-input ${searchInputVisible ? 'active' : ''}`}>
                <input
                    id='mobsearchInput'
                    placeholder="Search stories, audios and authors"
                    type="text"
                    value={searchText}
                    onChange={handleChange}
                    onKeyPress={handleSearch}
                    onClick={handleInputClick} // Add onClick event to handle input click
                />
            </div>
            {showSuggestions && (
                <div className='suggestion-box' ref={suggestionBoxRef}>
                    {suggestions && suggestions.length > 0 ? (
                        suggestions.map((suggestion, index) => (
                            <div key={index}>
                                <div onClick={() => handleSuggestionClick(suggestion.title)}>{suggestion.title}</div>
                            </div>
                        ))
                    ) : null}
                    {showNoSuggestions && searchText.length > 0 && suggestions.length === 0 && (
                        <div className='no-suggestion'>No suggestions found</div>
                    )}
                </div>
            )}
        </div>

    );
};

export default HamburgerMenu;
