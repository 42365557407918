import React, { useEffect, useState } from 'react'
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import { fetchSubPlaylistData } from '../../../../../Controller/AS-AudioStories/SubPlaylistController'
import { useLocation, useParams } from 'react-router';
// import { fetchStoryData } from '../../../../../Controller/AS-AudioStories/PlayController copy';
import { fetchStoryData } from '../../../../../Controller/AS-AudioStories/SingleAudioController';
import { fetchStoryDetail } from '../../../../../Controller/AS-AudioStories/AudioStoryDataController';
import { Helmet } from 'react-helmet';
import SingleAudioView from './SingleAudioView';
import fetchAudioData from '../../../../../Controller/AS-AudioStories/StoryListController';

function SingleAudio() {

   
    const [sortby, setSortBy] = useState('story_id');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const PAGE_LIMIT = 10; // number of items to display per page
    const [loading, setLoading] = useState(false);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);
    const [selectedAge, setSelectedAge] = useState(0);
    const location = useLocation();
    useEffect(() => {
        console.log(location.state.story);
    }, [location]);
    const authToken = localStorage.getItem('token') || null;
    const initialUserId = localStorage.getItem('UserID') || 0;
    const [userId, setUserId] = useState(initialUserId || 0);
    // expand audio content
    const [seekingInputMob, setSeekingInputob] = useState('seekinginputmob');
    const [expandedClassName, setExpandedClassName] = useState('as-audio-playstories');
    const [audioList, setAudioList] = useState('ws-stories')
    const [audioPagination, setAudioPagination] = useState('as-audio-pagination')
    const [audioSearch, setAudioSearch] = useState('as-player-search')
    const [audioPlayerBox, setAudioPlayerBox] = useState('as-player-box')
    const [audioPlayerBoxExpand, setAudioPlayerBoxExpand] = useState('as-player-box-expand-none')
    const [footerMob, setFooterMob] = useState('rights')
    const handleExpandClick = () => {
        setExpandedClassName('as-expand'); // Change the class name here
        setAudioList('ws-stories-expand');
        setAudioPagination('as-audio-pagination-expand')
        setAudioSearch('as-player-search-expand')
        setAudioPlayerBox('as-player-box-hide');
        setAudioPlayerBoxExpand('as-player-box-expand')
        setSeekingInputob('seekinginputmob-none');
        setFooterMob('rights-hide');
    };
    const handleExpandClose = () => {
        setExpandedClassName('as-audio-playstories'); // Change the class name here
        setAudioList('ws-stories');
        setAudioPagination('as-audio-pagination')
        setAudioSearch('as-player-search')
        setAudioPlayerBox('as-player-box');
        setAudioPlayerBoxExpand('as-player-box-expand-none')
        setFooterMob('rights');
    };
    // const [data, setData] = useState({});
    const { story_id, sequence } = useParams();
    const { state } = useLocation();

    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    const [maxTime, setMaxTime] = useState('00:00');
    // let sequence = (state) ? state.data.sequence : null;
    const setMaxTimeFromAudio = (audio) => {
        const duration = audio.duration;
        const minutes = Math.floor(duration / 60).toString().padStart(2, '0');
        const seconds = Math.floor(duration % 60).toString().padStart(2, '0');
        const maxTime = `${minutes}:${seconds}`;
        setMaxTime(maxTime);
    };
    useEffect(() => {
        async function fetchData() {
            if (audio) {
                audio.pause(); // Pause the current audio file before loading the new one
            }
            const result = await fetchStoryData(userId,story_id);
            setData(result);
            const newAudio = new Audio(result.
                audioLocation
            );
            newAudio.addEventListener('loadedmetadata', () => {
                setMaxTimeFromAudio(newAudio);
            });
            setAudio(newAudio);
            setIsPlaying(true);

        }

        fetchData();
    }, [sequence]);
     // ListView
     const [stories, setStories] = useState([]);
     const [firstAndLastStoriesNumber, setFirstAndLastStoriesNumber] = useState(0);
     const [apiCallCompleted, setApiCallCompleted] = useState(false);
     useEffect(() => {
         const searchParams = new URLSearchParams(location.search);
         const pageNumber = parseInt(searchParams.get('page'), 10);
         setPage(pageNumber || 1); // If pageNumber is NaN or undefined, default to 1
     }, [location.search]);
     useEffect(() => {
         setLoading(true);
         fetchAudioData(page, sortby, userId,selectedAge, allStoriesLoaded, setSortBy, setStories, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading, setAllStoriesLoaded)
             .then(() => {
                 setApiCallCompleted(true);
             })
             .catch((error) => {
                 console.error('Error fetching data:', error);
                 setApiCallCompleted(true);
                 setLoading(false);
             });
 
     }, [page, sortby, allStoriesLoaded,selectedAge]);
  return (
    <div>
    <Header authToken={authToken} />
    <Helmet>
    <title>Wiki Bedtime Stories: Short bedtime stories for kids</title>
        <meta name="description"
            content="Write, read, and listen to free short bedtime stories for kids. Enjoy fairy tales, sleeping beauty, Ramayana, Greek mythology, Panchatantra, and more bedtime stories for ages 5 to 12." />

     
    </Helmet>
    <SingleAudioView
        authToken={authToken} data={data} loading={loading} setPage={setPage} page={page}
        allStoriesLoaded={allStoriesLoaded} totalStories={totalStories}
        totalStoriesObject={totalStoriesObject}
        setSortBy={setSortBy}

        setData={setData}
        //expand
        expandedClassName={expandedClassName}
        audioList={audioList}
        audioPagination={audioPagination}
        audioSearch={audioSearch}
        audioPlayerBox={audioPlayerBox}
        onExpandClick={handleExpandClick}
        onExpandClose={handleExpandClose}
        audioPlayerBoxExpand={audioPlayerBoxExpand}
        seekingInputMob={seekingInputMob}
        audio={audio}
        // listview
        stories={stories}
    />
    <Footer footerMob={footerMob} />
</div>
  )
}

export default SingleAudio
