/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Home Screen Content
Brief :
The HomeContent component takes in various props:

data: Data for stories.
allStoriesLoaded: Indicates if all stories have been loaded.
loading: Indicates whether data is currently loading.
setPage: Function to set the current page of stories.
page: Current page number.
setData: Function to set the data for stories.
setAllStoriesLoaded: Function to set whether all stories have been loaded.
totalStories: Total number of stories.
totalStoriesObject: Object containing information about total stories.
firstAndLastStoriesNumber: Information about the first and last stories.
apiCallInProgress: Indicates if an API call is in progress.
totalPages: Total number of pages.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/Home.css'
import SearchBox from './SearchBox'
import Stories from './Stories'

function HomeContent({ data, allStoriesLoaded,
    loading, setSortBy,
    setPage, page, setData, setAllStoriesLoaded, totalStories,
    totalStoriesObject, firstAndLastStoriesNumber, apiCallInProgress, totalPages, apiCallCompleted, setApiCallCompleted, selectedAge,
    setSelectedAge, filterby,
    setFilterBy,
    //expand
    expandedClassName,
    audioList,
    audioSearch,
    audioPagination,
    audioPlayerBox,
    onExpandClick,
    onExpandClose,
    audioPlayerBoxExpand,
    seekingInputMob
}) {
    return (
        <div>
            <div className={audioSearch}>
                <SearchBox totalStories={totalStories}
                    loading={loading}
                    totalStoriesObject={totalStoriesObject} />
            </div>

            <Stories data={data} allStoriesLoaded={allStoriesLoaded}
                loading={loading} setSortBy={setSortBy}
                setPage={setPage} page={page} setData={setData} setAllStoriesLoaded={setAllStoriesLoaded} totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                firstAndLastStoriesNumber={firstAndLastStoriesNumber}
                apiCallInProgress={apiCallInProgress}
                totalPages={totalPages}
                apiCallCompleted={apiCallCompleted}
                setApiCallCompleted={setApiCallCompleted}
                selectedAge={selectedAge}
                setSelectedAge={setSelectedAge}
                filterby={filterby}
                setFilterBy={setFilterBy}
                //expand
                expandedClassName={expandedClassName}
                audioList={audioList}
                audioPagination={audioPagination}
                audioPlayerBox={audioPlayerBox}
                onExpandClick={onExpandClick}
                onExpandClose={onExpandClose}
                audioPlayerBoxExpand={audioPlayerBoxExpand}
                seekingInputMob={seekingInputMob}
            />
        </div>
    )
}

export default HomeContent
