/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : The purpose of this code is to create a fetchData function responsible for fetching data related to written stories from the "wikibedtimestories.com" application. This function utilizes Axios to make API requests to retrieve story data.
Brief : The code defines an asynchronous function named fetchData.
This function is designed to retrieve data related to written stories from the application. It uses the all_stories endpoint from the WrittenStories model.
It takes in several parameters (page, allStoriesLoaded, setData, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading, setAllStoriesLoaded) to manage data fetching and updating state within the application.
The function constructs a GET request using Axios to fetch stories data with pagination parameters (pageNumber and pageSize).
Upon receiving the response, it extracts necessary information such as totalStories, totalStoriesObject, and firstAndLastStoriesNumber from the JSON response.
It updates the application state accordingly by appending new data (json.data) to the existing data (prevData) and sets flags (allStoriesLoaded) based on the fetched data length.
Any errors encountered during the fetching process are logged to the console, and the loading state is updated accordingly.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { all_stories } from '../../Model/WS-WrittenStories/WrittenStories';

const PAGE_LIMIT = 10;

const fetchRstories = async (page, userId, allStoriesLoaded, setRstories, setTotalStories, setLoading, setAllStoriesLoaded) => {
    if (!allStoriesLoaded) {
        setLoading(true); // Start loading
        try {
            const response = await axios.get(`${all_stories}`, {
                params: {
                    sortBy: 'recommended',
                    pageNumber: page,
                    pageSize: PAGE_LIMIT,
                    userId: userId,
                    ageRangeId: 0,
                },
            });

            const json = response.data.data[0].recommendations;
            // console.log(json)
            setRstories(json)
            // if (json && json.data && Array.isArray(json.data)) {
            //     const { totalStories } = json;
            //     setTotalStories(totalStories);

            //     // Update data safely, ensuring prevData is an array
            //     // setRstories((prevData) => Array.isArray(prevData) ? [...prevData, ...json.data] : [...json.data]);
            //     setRstories(json)
            //     // Check if all stories have been loaded
            //     if (json.data.length < PAGE_LIMIT) {
            //         setAllStoriesLoaded(true);
            //     }
            // } else {
            //     // console.error('Invalid response structure or no data found:', json);
            // }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading after the API call is done, even if an error occurs
        }
    }
};

export default fetchRstories;

